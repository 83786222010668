import { useState, useContext } from "react"
import { AuthContext } from "Context/authContext/AuthContext"
import "./employmentInfo.scss"
import Cookies from "universal-cookie"
import axios from "axios"
import config from "../../../config/config"

function EmploymentInfo({ display }) {
  const { user } = useContext(AuthContext)

  const [updateMode, setUpdateMode] = useState()
  const [showData, setShowData] = useState(true)
  const [student, setStudent] = useState(
    display?.is_student ? "yes" : "no"
  )
  const [school, setSchool] = useState(
    display?.school_name ?? "unknown"
  )
  const [program, setProgram] = useState(
    display?.school_program ?? "unknown"
  )
  const [start, setStart] = useState(display?.start_date ?? "unknown")
  const [employment, setEmployment] = useState(
    display?.current_status ?? "unknown"
  )
  const [salary, setSalary] = useState(
    display?.salary ? `${display.salary}/month` : "unknown"
  )
  const [jobTitle, setJobTitle] = useState(
    display?.job_title ?? "unknown"
  )
  const [employer, setEmployer] = useState(
    display?.employer ?? "unknown"
  )
  const [jobStart, setJobStart] = useState(
    display?.start_date ?? "unknown"
  )

  // TODO: Info not provided
  const [refName, setRefName] = useState(
    display?.employment_references?.[0]?.name ?? "unknown"
  )
  const [refNumber, setRefNumber] = useState(
    display?.employment_references?.[0]?.phone_number ?? "unknown"
  )

  const [refEmail, setRefEmail] = useState(
    display?.employment_references?.[0]?.email ?? "unknown"
  )
  
  const handleGetFile = (fileId, fileName) => {
    const cookies = new Cookies()
    axios({
      url: `${config.BASE_URL}api/file/${fileId}`,
      method: 'POST',
      responseType: 'blob', // important
      headers: {
        Authorization: "Bearer " + cookies.get("auth_token"),
      },
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTLM element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

  const isWasJobTitle = (display.is_past === 1) ? "what was your job title?" : "what is your current job title?"

  return (
    <section className="employment-info application-preview-container">
      <div className="info-title-container">
        <div className="info-title">
          <div className="info__label info__label--completed">
            completed
          </div>
          <h2>employment & income info</h2>
        </div>
        <div className="info-title-controls">
          {showData ? (
            <>
              {user.roles[0].name == "tenant" && (
                <button
                  className="btn btn--secondary-alt btn--small btn--shrink"
                  onClick={
                    updateMode
                      ? // CHANGE THIS TO HANDLESUBMIT?
                        () => setUpdateMode(false)
                      : //
                        () => setUpdateMode(true)
                  }
                >
                  {updateMode ? "submit" : "edit"}
                </button>
              )}
            </>
          ) : null}
          <button
            className={
              showData
                ? "btn btn--toggle btn--toggle-inverse"
                : "btn btn--toggle btn--toggle-inverse active"
            }
            onClick={() => setShowData(!showData)}
          ></button>
        </div>
      </div>

      {/* THIS SECTION HANDLES STUDENT CASES */}
      {showData ? (
        <>
          {(display?.is_student === 1) ? (
           <>
            <div className="row">
              <div className="col-md-6">
                <h5>are you a student</h5>
                {updateMode ? (
                  <input
                    type="text"
                    value={student}
                    onChange={(e) => setStudent(e.target.value)}
                    />
                ) : (
                  <p>{student}</p>
                )}
              </div>
            </div>
           </>
          ) : '' }
            <div className="row">
              {(display?.is_student === 1) ? (
                <>
                <div className="col-md-6">
                  <h5>what school do you attend?</h5>
                  {updateMode ? (
                    <input
                      type="text"
                      value={school}
                      onChange={(e) => setSchool(e.target.value)}
                      />
                  ) : (
                    <p>{school}</p>
                  )}
                </div>
                <div className="col-md-6">
                  <h5>what program are you taking?</h5>
                  {updateMode ? (
                    <input
                      type="text"
                      value={program}
                      onChange={(e) => setProgram(e.target.value)}
                      />
                  ) : (
                    <p>{program}</p>
                  )}
                </div>
              </>
              ) : ''}

              { display.is_student === 1  && <div className="col-md-6">
              <h5>start date</h5>
              {updateMode ? (
                <input
                  type="text"
                  value={start}
                  onChange={(e) => setStart(e.target.value)}
                />
              ) : (
                <p>{start}</p>
              )}
            </div>}
          </div>

          {(display?.is_student !== 1) ? (
            <>
           <h3>
             {(display?.is_past !== 1) ? (
               <>
                 <span className="employment_type">current</span>
               </>
             ) : (
               <>
                 <span className="employment_type">previous</span>
               </>
             )}
             &nbsp;employment info
           </h3>
           <div className="row">
             { display.is_past !== 1  && (
             <div className="col-md-6">
               <h5>what is your current employment status?</h5>
               {updateMode ? (
                 <input
                   type="text"
                   value={employment}
                   onChange={(e) => setEmployment(e.target.value)}
                   />
               ) : (
                 <p>{employment}</p>
               )}
             </div>
               )}
           </div>
           <div className="row">
             { display.is_past !== 1  && (
             <div className="col-md-6">
               <h5>what's your monthly income for this job?</h5>
               {updateMode ? (
                 <input
                   type="text"
                   value={salary}
                   onChange={(e) => setSalary(e.target.value)}
                   />
               ) : (
                 <p>{salary}</p>
               )}
             </div>)}
             <div className="col-md-6">
               <h5>{ isWasJobTitle }</h5>
               {updateMode ? (
                 <input
                   type="text"
                   value={jobTitle}
                   onChange={(e) => setJobTitle(e.target.value)}
                   />
               ) : (
                 <p>{jobTitle}</p>
               )}
             </div>
             <div className="col-md-6">
               <h5>{
                 (display?.is_past !== 1) ? (
                     <>who is your employer?</>
                 ) : (
                   <>who was your previous employer?</>
                 )
               }</h5>
               {updateMode ? (
                 <input
                   type="text"
                   value={employer}
                   onChange={(e) => setEmployer(e.target.value)}
                   />
               ) : (
                 <p>{employer}</p>
               )}
             </div>
             <div className="col-md-6">
               <h5>when did you start at this job?</h5>
               {updateMode ? (
                 <input
                   type="text"
                   value={jobStart}
                   onChange={(e) => setJobStart(e.target.value)}
                   />
               ) : (
                 <p>{jobStart}</p>
               )}
             </div>
             { display.is_past === 1 && (
               <div className="col-md-6">
                 <h5>when did leave this job?</h5>
                   <p>{display.end_date}</p>
               </div>
             )}

             {(display?.files?.length) ? (
               <>
                 <div className="col-md-6">
                   <h5>proof of income</h5>
                   {display?.files?.map((file) => (
                     <p>
                       <a onClick={() => handleGetFile(file.id, file.original_file_name)}>
                         click to download
                       </a>
                     </p>
                   ))}
                 </div>
                </>
             ) : ( <></> )}
           </div>
           {(display?.employment_references.length || updateMode) && (
            <>
              <h4>reference 1</h4>
              <div className="row">
                <div className="col-md-auto">
                  <h5>name</h5>
                  {updateMode ? (
                    <input
                      type="text"
                      value={refName}
                      onChange={(e) => setRefName(e.target.value)}
                      />
                  ) : (
                    <p>{refName}</p>
                  )}
                </div>
                <div className="col-md-auto">
                  <h5>phone number</h5>
                  <p>
                    {updateMode ? (
                      <input
                        type="text"
                        value={refNumber}
                        onChange={(e) => setRefNumber(e.target.value)}
                        />
                    ) : (
                      <a href="tel:11234567890">{refNumber}</a>
                    )}
                  </p>
                </div>
                <div className="col-md-auto">
                  <h5>email address</h5>
                  <p>
                    {updateMode ? (
                      <input
                        type="text"
                        value={refEmail}
                        onChange={(e) => setRefEmail(e.target.value)}
                        />
                    ) : (
                      <a href="mailto:johnsmith@acme.com">
                        {refEmail}
                      </a>
                    )}
                  </p>
                </div>
              </div>
            </>
          )}
          </>
          ) : ''}
        </>
      ) : null}
    </section>
  )
}

export default EmploymentInfo
