import "./employmentInfo.scss"
import ProgressBar from "../../../Components/ProgressBar/ProgressBar"
import TextInput from "../../../Components/Input/TextInput"
import DatePickerInput from "../../../Components/Input/DatePickerInput"
import {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import Employment from "./Employment/Employment"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import config from "../../../config/config"
import Cookies from "universal-cookie"
import { removeEmptyStringsFromObject } from "util/removeEmptyStringsFromObject"

function EmploymentInfo() {
  const navigate = useNavigate()
  const _fetched = useRef(false)
  const hadStudentInfo = useRef(false)
  const { current: knownIds } = useRef(new Set())
  const [isStudent, setIsStudent] = useState(null)
  // {component:<Employment />, ref:ReactRef,id:string}
  const [employmentList, setEmploymentList] = useState([])

  const [pastEmploymentList, setPastEmploymentList] = useState([])
  const [hasBeenSubmited, setHasBeenSubmited] = useState(false)
  //text area controls
  const [schoolName, setSchoolName] = useState("")
  const [schoolProgram, setSchoolProgram] = useState("")
  const [startDate, setStartDate] = useState("")
  const [, rerender] = useState(false)
  const cookies = new Cookies()
  const submitStudent = () => {
    if (isStudent === true) {
      return axios.post(
        hadStudentInfo.current !== false
          ? `${config.BASE_URL}api/employment/${hadStudentInfo.current}`
          : `${config.BASE_URL}api/employment`,
        removeEmptyStringsFromObject({
          is_student: 1,
          is_past: 0,
          school_name: schoolName,
          school_program: schoolProgram,
          start_date: startDate,
          application_id: parseInt(
            localStorage.getItem("currentApplicationId")
          ),
        }),
        {
          headers: {
            Authorization: "Bearer " + cookies.get("auth_token"),
          },
        }
      )
    } else {
      return Promise.resolve()
    }
  }
  const submitJobs = () => {
    const allJobs = [...employmentList, ...pastEmploymentList]
    const removedIds = new Set(knownIds.values())
    const promises = allJobs.map((job) => {
      const data = job.ref.current.getData()
      
      const _id = job.ref.current.getId()
      const useUpdate = knownIds.has(_id)
      if (useUpdate) {
        removedIds.delete(_id)
      }
      let root
      root = axios.post(
        useUpdate
        ? `${config.BASE_URL}api/employment/${_id}`
        : `${config.BASE_URL}api/employment`,
        removeEmptyStringsFromObject({
          is_student: 0,
          ...data,
          school_name: null,
          school_program: null,
          proof_of_income: undefined,
          application_id: parseInt(
            localStorage.getItem("currentApplicationId")
          ),
        }),
        {
          headers: {
            Authorization: "Bearer " + cookies.get("auth_token"),
          },
        }
      )
      .then(function(employmentData) {
        if (typeof employmentData.data.has_errors != 'undefined') {
          throw new Error(employmentData)
        } else {
          if(employmentData.data.employmentId) {
            if (data.proof_of_income) {
              const fd = new FormData()
              fd.append("files", data.proof_of_income)
              fd.append("model_id", employmentData.data.employmentId)
              fd.append("model_type", "Employment")
              fd.append("input", "proof_of_income")
              
              return axios.post(
                `${config.BASE_URL}api/file`,
                fd,
                {
                  headers: {
                    Authorization: "Bearer " + cookies.get("auth_token"),
                  },
                }
              )
            } else {
              root = Promise.resolve(true)
            }
          } else {
            root = Promise.resolve(true)
          }
        }
      })
    })
    
    return Promise.all(promises).then(() => Promise.all(
      Array.from(removedIds.values()).map((id) => {
        return axios.delete(
          `${config.BASE_URL}api/employment/${id}`,
          {
            headers: {
              Authorization: "Bearer " + cookies.get("auth_token"),
            },
          }
        )
      })
    ))
  }
  
  const handleNextButton = () => {
    setHasBeenSubmited(true)
    let hasError = employmentList.length === 0 && !isStudent
    employmentList.forEach((employment) => {
      employment.ref.current.setHasBeenSubmited(true)
      if (employment.ref.current.hasErrors()) hasError = true
    })
    pastEmploymentList.forEach((employment) => {
      employment.ref.current.setHasBeenSubmited(true)
      if (employment.ref.current.hasErrors()) hasError = true
    })

    if (!hasError) {
      submitStudent()
        .then(() => {
          submitJobs()
            .then((res) => {
              navigate("/tenant/household-info")
            })
            .catch((e) => {
              console.error(e)
            })
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }

  const removeJob = useCallback((id) => {
    setEmploymentList((employmentList) =>
      employmentList.filter((listItem) => listItem.id !== id)
    )
  }, [])
  const addJob = useCallback(
    (data = {}) => {
      setEmploymentList((prevEmploymentList) => {
        let id = Math.random().toString(36)
        const ref = createRef()
        return [
          ...prevEmploymentList,
          {
            id,
            component: (
              <Employment
                data={data}
                key={id}
                ref={ref}
                id={id}
                type="current"
                removeJob={removeJob}
                rerender={() => rerender(Math.random())}
                hasBeenSubmited={hasBeenSubmited}
              />
            ),
            ref,
          },
        ]
      })
    },
    [removeJob, hasBeenSubmited]
  )

  const removePastJob = useCallback((id) => {
    setPastEmploymentList((pastEmploymentList) =>
      pastEmploymentList.filter((listItem) => listItem.id !== id)
    )
  }, [])
  const addPastJob = useCallback(
    (data = {}) => {
      setPastEmploymentList((prevPastEmploymentList) => {
        const id = Math.random().toString(36)
        const ref = createRef()
        return [
          ...prevPastEmploymentList,
          {
            id,
            component: (
              <Employment
                data={data}
                key={id}
                ref={ref}
                type="past"
                id={id}
                removeJob={removePastJob}
                removePastJob={removePastJob}
                rerender={() => rerender(Math.random())}
                hasBeenSubmited={hasBeenSubmited}
              />
            ),
            ref,
          },
        ]
      })
    },
    [removePastJob, hasBeenSubmited]
  )
  useEffect(() => {
    if (!_fetched.current) {
      _fetched.current = true
      if (localStorage.getItem("currentApplicantId") !== undefined)
        axios
          .get(
            `${config.BASE_URL}api/employment/${localStorage.getItem(
              "currentApplicantId"
            )}`,
            {
              headers: {
                Authorization: "Bearer " + cookies.get("auth_token"),
              },
            }
          )
          .then(({ data }) => {
            const studentData = data.employments.filter(
              (item) => item.is_student === 1
            )
            const jobsData = data.employments.filter(
              (item) => item.is_student === 0
            )
            if (studentData.length > 0) {
              setIsStudent(true)
              setSchoolName(studentData[0].school_name)
              setSchoolProgram(studentData[0].school_program)
              setStartDate(studentData[0].start_date)
              hadStudentInfo.current = studentData[0].id
            }
            jobsData.forEach((employment) => {
              knownIds.add(employment.id)
              if (employment.is_past) {
                addPastJob(employment)
              } else {
                addJob(employment)
              }
            })
          })
          .catch((e) => {})
    }
  }, [cookies, addJob, addPastJob, knownIds, hadStudentInfo])
  return (
    <section className="EmploymentInfo">
      <div className="container">
        <button
          className="btn btn--back"
          onClick={() => {
            navigate(-1)
          }}
        >
          back
        </button>
        <ProgressBar type="tenant" step="4" />

        <h1>employment & income info</h1>

        <div className="form-group">
          <label>are you a student?</label>
          <div className="form-choose-group">
            <button
              label="yes"
              name="is_student"
              value="1"
              className={
                "choose-input-group label" +
                (isStudent === true ? " active" : "")
              }
              onClick={() => {
                setIsStudent(true)
              }}
            >
              yes
            </button>
            <button
              label="yes"
              name="is_student"
              value="1"
              className={
                "choose-input-group label" +
                (isStudent === false ? " active" : "")
              }
              onClick={() => {
                setIsStudent(false)
              }}
            >
              no
            </button>
          </div>
        </div>

        {isStudent && (
          <>
            <div className="row">
              <div className="col-xl-4 col-md-6">
                <TextInput
                  type="text"
                  label="what school do you attend?"
                  name="school_name"
                  value={schoolName}
                  onChange={(e) => setSchoolName(e.target.value)}
                  hasError={
                    schoolName.length === 0 &&
                    isStudent &&
                    hasBeenSubmited
                  }
                />
              </div>
              <div className="col-xl-4 col-md-6">
                <TextInput
                  type="text"
                  label="what program are you taking?"
                  name="school_program"
                  value={schoolProgram}
                  onChange={(e) => setSchoolProgram(e.target.value)}
                  hasError={
                    schoolProgram.length === 0 &&
                    isStudent &&
                    hasBeenSubmited
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-md-6">
                <DatePickerInput
                  label="start date"
                  name="start_date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  hasError={
                    startDate.length === 0 &&
                    isStudent &&
                    hasBeenSubmited
                  }
                />
              </div>
            </div>
          </>
        )}

        <h3>current employment</h3>

        {employmentList.map(
          (employmentInstance) => employmentInstance.component
        )}

        <button
          className="btn btn--secondary-alt btn--small mb-4"
          onClick={addJob}
        >
          {" "}
          add a job
        </button>

        <h3>past employment</h3>
        {pastEmploymentList.map(
          (employmentInstance) => employmentInstance.component
        )}

        <button
          className="btn btn--secondary-alt btn--small mb-4"
          onClick={addPastJob}
        >
          {" "}
          add previous job
        </button>

        {hasBeenSubmited &&
          (employmentList.length === 0 && !isStudent ? (
            <p className="EmploymentInfo_error-message">
              Please add a current job to continue
            </p>
          ) : employmentList.some((employment) =>
              employment.ref.current?.hasErrors?.()
            ) ? (
            <p className="EmploymentInfo_error-message">
              Please fix the errors in the current employment section
              to continue
            </p>
          ) : (
            pastEmploymentList.some((employment) =>
              employment.ref.current?.hasErrors?.()
            ) && (
              <p className="EmploymentInfo_error-message">
                Please fix the errors in the past employment section
                to continue
              </p>
            )
          ))}
        <div className="btn-group">
          <button
            className="btn btn--primary"
            onClick={handleNextButton}
          >
            next
          </button>
        </div>
      </div>
    </section>
  )
}

export default EmploymentInfo