const SelectInput = ({ value, label, name, placeholder, inline, onChange, children, defaultValue = "" }) => (
    <div className={inline ? 'form-group form-group--inline' : 'form-group'}>
        {label && <label htmlFor={name}>{label}</label>}
        <select
            value={value}
            name={name}
            id={name}
            className="form-group__form-control"
            placeholder={placeholder}
            onChange={onChange}
            defaultValue={defaultValue}
        >
            {children}
        </select>
        {/*  error message here  */}
    </div>
);

export default SelectInput;