import "./betaNotifyModal.scss"

function BetaNotifyModal({ props }) {
  return (
    <div className="VerificationModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className="modal__container">
          <h2 className={"betaModalTitle"}>Welcome to the Smart Screening Beta!</h2>

          <div className="textContainer">
            <p className={"betaModalText"}>
              Thanks for coming to try out the newest and best way to screen tenants. If any issues pop up or you have thoughts on improving the screening experience, please email us at hello@rentpanda.ca and we will connect with you right away.
            </p>
          </div>

          <button className="btn btn--primary"
                  onClick={() => props(false)}>
            Got it
          </button>
        </div>
      </div>
    </div>
  );
}

export default BetaNotifyModal;