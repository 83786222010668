import "./tenantApplication.scss"
import HousingInfo from "../../../../Components/ApplicationPreview/HousingInfo/HousingInfo"
import EmploymentInfo from "../../../../Components/ApplicationPreview/EmploymentInfo/EmploymentInfo"
import HouseholdInfo from "../../../../Components/ApplicationPreview/HouseholdInfo/HouseholdInfo"
import CreditCheck from "../../../../Components/ApplicationPreview/CreditCheck/CreditCheck"
import IDVerification from "../../../../Components/ApplicationPreview/IDVerification/IDVerification"
import EmploymentIncomeVerification from "../../PremiumFeatures/EmploymentIncomeVerification/EmploymentIncomeVerification"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "axios"
import Cookies from "universal-cookie"
import config from "../../../../config/config"
import clsx from "clsx"
import { usePremiumFlow } from "../../../../Context/flowPremiumContext/FlowPremiumContext"
import BankCheck from "../../../../Components/ApplicationPreview/BankCheck/BankCheck"
import BackgroundCheck from "../../../../Components/ApplicationPreview/BackgroundCheck/BackgroundCheck"


function TenantApplication() {
  const navigate = useNavigate()
  const handleNavBack = () => {
    navigate("/tenant/dashboard")
  }
  const [{ applicant }, setData] = useState({})

  useEffect(() => {
    const cookie = new Cookies()
    axios
      .get(
        `${config.BASE_URL}api/applicant/${localStorage.getItem(
          "currentApplicantId"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${cookie.get("auth_token")}`,
          },
        }
      )
      .then((res) => {
        // console.log("res", res)
        setData(res.data)
      })
  }, [])
  const { hasPremiumFeature, premiumFeatureComplete, premiumFeatureProcessed} = usePremiumFlow()
  const premiumFeatures = [
    {
      id: "credit_check",
      name: "credit check",
      location: "/tenant/credit-report",
    },
    {
      id: "background_check",
      name: "background check",
      location: "/tenant/background-check",
    },
  ]
  const financialVerification = {
    id: "financial_verification",
    name: "financial verification",
    location: "/tenant/employment-income-verification",
  }
  const idVerification = {
    id: "id_verification",
    name: "id verification",
    location: "/tenant/employment-income-verification",
  }

  const yieldSpecificFeature = (feature) => {
    switch (feature.id) {
      case "background_check":
        return (
          applicant?.background_checks?.map((background_check) => {
            return (
              <BackgroundCheck
                type="tenant"
                data={JSON.parse(background_check.certn_data)}
                key={background_check.id}
              />
            )
          }))
      case "credit_check":
        return (
          applicant?.credit_checks?.map((credit_check) => {
            return (
              <CreditCheck
                type="tenant"
                data={JSON.parse(credit_check.certn_data)}
                key={credit_check.id}
              />
            )
          })
        )
      case "reference_check":
        return (
          <>

          </>
        )
      default:
        return (
          <> </>
        )
    }
  }


  const RenderFinancialData = ({financialVerification}) => {
    const hasFinancialVerification = hasPremiumFeature(financialVerification.id)
    const financialVerificationComplete = premiumFeatureComplete(financialVerification.id)
    const financialVerificationProcessed = premiumFeatureProcessed(financialVerification.id)

    if (!hasFinancialVerification) {
      return (
        <>
        </>
      )
    } else if (financialVerificationComplete) {
      return (
        <>
          {applicant?.employment_income_verifications?.map(
            (verification) => {
              return (
                <BankCheck
                  display={verification}
                  key={verification.id}
                />
              )
            })}
        </>
      )
    } else if (financialVerificationProcessed) {
      return (
        <div className="premium-feature">
          <div className="info-title-container">
            <div className="info-title">
              <div className={"info__label processingHeading"}>
                processing
              </div>
              <h2>
                {hasFinancialVerification && (
                  <span>automated employment / income verification</span>
                )}
              </h2>
            </div>
          </div>
          <p className={"processingText"}>
            The information is currently processing. It may take a few minutes to come through. Please submit your application and check back shortly to see your results.
          </p>
        </div>
      )
    } else {
      return (
        <div className="premium-feature">
          <div className="info-title-container">
            <div className="info-title">
              <div className={"info__label info__label--incomplete"}>
                incomplete
              </div>
              <h2>
                {hasFinancialVerification === true && (
                  <span>automated employment / income verification</span>
                )}
              </h2>
            </div>
            <div className="info-title-controls">
              {(!financialVerificationProcessed) && (
                <button
                  className="btn btn--primary btn--small"
                  onClick={() => {
                    navigate(
                      "/tenant/employment-income-verification"
                    )}}
                >
                  start verification
                </button>
              )}
            </div>
          </div>
        </div>
      )
    }
  }

  const RenderIdVerification = ({idVerification}) => {
    const hasIdVerification = hasPremiumFeature(idVerification.id)
    const idVerificationProcessed = premiumFeatureProcessed(idVerification.id)
    const idVerificationComplete = premiumFeatureComplete(idVerification.id)

    if (!hasIdVerification) {
      return (
        <>
        </>
      )
    } else if (idVerificationComplete) {
      return (
        <>
          {applicant?.id_verifications?.map((id_verification) => {
            return (
              <IDVerification
                display={id_verification}
                key={id_verification.id}
              />
            )
          })}
        </>
      )
    } else if (idVerificationProcessed) {
      return (
        <div className="premium-feature">
          <div className="info-title-container">
            <div className="info-title">
              <div className={"info__label processingHeading"}>
                processing
              </div>
              <h2>
                { hasIdVerification && (
                  <span>id verification</span>
                )}
              </h2>
            </div>
          </div>
          <p className={"processingText"}>
            The information is currently processing. It may take a few minutes to come through. Please submit your application and check back shortly to see your results.
          </p>
        </div>
      )
    } else {
      return (
        <div className="premium-feature">
          <div className="info-title-container">
            <div className="info-title">
              <div className={"info__label info__label--incomplete"}>
                incomplete
              </div>
              <h2>
                {hasIdVerification && (
                  <span>id verification</span>
                )}
              </h2>
            </div>
            <div className="info-title-controls">
              {((hasIdVerification && !idVerificationProcessed)) && (
                <button
                  className="btn btn--primary btn--small"
                  onClick={() => {
                    navigate(
                      "/tenant/employment-income-verification"
                    )}}
                >
                  start verification
                </button>
              )}
            </div>
          </div>
        </div>
      )
    }
  }

  const RenderFeatures = ({feature}) => {
    const completed = premiumFeatureComplete(feature.id)
    const processed = premiumFeatureProcessed(feature.id)

    if (completed) {
      return (
        <div>
          {yieldSpecificFeature(feature)}
        </div>
      )
    } else if (processed) {
      return (
        <div className="premium-feature" key={feature.id}>
          <div className="info-title-container">
            <div className="info-title">
              <div
                className={"info__label processingHeading"}
              >
                processing
              </div>
              <h2>{feature.name}</h2>
            </div>
          </div>
          <p className={"processingText"}>
            The information is currently processing. It may take a few minutes to come through. Please submit your application and check back shortly to see your results.
          </p>
        </div>
      )
    } else {
      // Case where feature is not complete, and not processed
      return (
        <div className="premium-feature" key={feature.id}>
          <div className="info-title-container">
            <div className="info-title">
              <div
                className={"info__label info__label--incomplete"}
              >
                incomplete
              </div>
              <h2>{feature.name}</h2>
            </div>
            <div className="info-title-controls">
              <button
                className="btn btn--primary btn--small"
                onClick={() => {
                  navigate(feature.location)
                }}
              >
                start {feature.name}
              </button>
            </div>
          </div>
        </div>
      )
    }
  }


  return (
    <section className="TenantApplication">
      <div className="container">
        <button className="btn btn--back" onClick={handleNavBack}>
          back
        </button>
        <div className="row justify-content-between">
          <div className="col-lg-auto">
            <h1>{applicant?.application.address}</h1>
          </div>
        </div>
        <h2>your application</h2>

        <div className="row justify-content-between">
          <div className="col-lg-6">
            <h5 className="status">Status: {applicant?.status}</h5>
          </div>
        </div>

        <div className="TenantApplication__container">
          {applicant?.housings?.map((house) => {
            return (
              <HousingInfo
                display={house}
                key={house.id}
                type={house.type}
                editable="true"
              />
            )
          })}
          {applicant?.employments?.map((employment) => {
            return (
              <EmploymentInfo
                display={employment}
                key={employment.id}
              />
            )
          })}
          {applicant?.households?.map((household) => {
            return (
              <HouseholdInfo display={household} key={household.id} />
            )
          })}



          {premiumFeatures
            .filter((feature) => hasPremiumFeature(feature.id))
            .map((feature) => <RenderFeatures feature={feature} key={feature.id} />)
          }

          {/* Separate render for these two as they are conditional on each other */}
          <RenderFinancialData financialVerification={financialVerification} />

          <RenderIdVerification idVerification={idVerification} />


          {hasPremiumFeature("reference_check") && (
            <div className="premium-feature">
              <div className="info-title-container">
                <div className="info-title">
                  <div
                    className={clsx([
                      "info__label",
                      premiumFeatureComplete("reference_check")
                        ? "info__label--completed"
                        : "info__label--incomplete",
                    ])}
                  >
                    {premiumFeatureComplete("reference_check")
                      ? "complete"
                      : "incomplete"}
                  </div>
                  <h2>verified landlord references</h2>
                </div>
                <div className="info-title-controls">
                  <button
                    className="btn btn--primary btn--small"
                    onClick={() => {
                      navigate("/tenant/landlord-references")
                    }}
                  >
                    add references
                  </button>
                </div>
              </div>
            </div>
          )}
          {/*            {(hasPremiumFeature("id_verification") ||
              hasPremiumFeature("financial_verification")) && (
              <div className="premium-feature">
                <div className="info-title-container">
                  <div className="info-title">
                    <div className={clsx([
                      "info__label",
                      // THIS IS FOR FINANCIAL VERIFICATION
                      (premiumFeatureComplete("id_verification") || premiumFeatureComplete("financial_verification"))
                        ? "info__label--completed"
                        : "info__label--incomplete"
                    ])}
                    >
                      {(premiumFeatureComplete("id_verification") || premiumFeatureComplete("financial_verification"))
                        ? "complete"
                        : "incomplete"}
                    </div>
                    <h2>
                      {(hasPremiumFeature("financial_verification")) && (
                        <span>automated employment / income verification</span>
                      )}
                      {(hasPremiumFeature("financial_verification") && hasPremiumFeature("id_verification")) && (
                        <span>&nbsp;&amp;&nbsp;</span>
                      )}
                      {(hasPremiumFeature("id_verification")) && (
                        <span>id verification</span>
                      )}
                    </h2>
                  </div>
                  {((hasPremiumFeature("id_verification") && !premiumFeatureComplete("id_verification")) || (hasPremiumFeature("financial_verification") && !premiumFeatureComplete("financial_verification"))) && (
                    <div className="info-title-controls">
                      <button
                        className="btn btn--primary btn--small"
                        onClick={() => {
                          navigate(
                            "/tenant/employment-income-verification"
                          )
                        }}
                      >
                        start verification
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}*/}
        </div>

        <div className="col-lg-auto">
          <button className="btn btn--danger">delete</button>
        </div>
      </div>
    </section>
  )
}

export default TenantApplication
