import "./myAccount.scss"
import { useState, useContext, forwardRef } from "react"
import { AuthContext } from "Context/authContext/AuthContext"
import TextInput from "../../Components/Input/TextInput"
import DeleteAccountModal from "./DeleteAccountModal/DeleteAccountModal"
import DatePickerInput from "../../Components/Input/DatePickerInput"
import config from "../../config/config"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import Cookies from "universal-cookie"
import Helmet from "react-helmet"
import {useUpdateUser} from "../../Hooks/useUpdateUser"

function MyAccount(props) {
  const [infoMessage, setInfoMessage] = useState(false)
  const [passMessage, setPassMessage] = useState(false)
  const [focused, setFocused] = useState(false)
  const [openDeleteAccountModal, setOpenDeleteAccountModal] =
    useState(false)
  const navigate = useNavigate()
  const { user, dispatch } = useContext(AuthContext)
  const cookies = new Cookies()
  const [firstName, setFirstName] = useState(user.first_name || "")
  const [lastName, setLastName] = useState(user.last_name || "")
  const [phoneNumber, setPhoneNumber] = useState(user.phone_number || "")
  const [email, setEmail] = useState(user.email || "")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newUserData, setNewUserData] = useUpdateUser({}, user)
  const [infoHasServerError, setInfoHasServerError] = useState({
    hasErrors: false,
    errors: [],
  })
  const [passHasServerErrors, setPassHasServerErrors] = useState({
    hasErrors: false,
    errors: [],
  })

  const handleFocus = (e) => {
    setFocused(true)
  }

  const checkParity = (e) => {
    const newPass = e.target.value
    if (newPass !== newPassword) {
      setPassHasServerErrors({
        hasErrors: true,
        errors: ["Passwords do not match"],
      })
    } else {
      setPassHasServerErrors({
        hasErrors: false,
        errors: [],
      })
    }
  }

  const apiConfig = {
    headers: {
      Authorization: "Bearer " + cookies.get("auth_token"),
    },
  }

  const handleInfoErrors = (errors, data) => {
      const errorNames = Object.keys(data.errors)

      errorNames.forEach((errorName) => {
        setInfoHasServerError((prevState) => {
          return {
            hasErrors: true,
            errors: [...prevState.errors, data.errors[errorName]],
          }
        })
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const updatedUser = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      email: email,
    }
    setNewUserData(updatedUser)
    try {
      const res = await axios.post(
        `${config.BASE_URL}api/account`,
        updatedUser,
        apiConfig
      )
      if (res.data.has_errors) {
        setInfoHasServerError({
          hasErrors: false,
          errors: [],
        })
        handleInfoErrors(res.data.errors, res.data)
      } else {
        setInfoHasServerError({
          hasErrors: false,
          errors: [],
        })
        setInfoMessage(true)
      }
    } catch (error) {
      console.log("Error: ", error)
    }
  }

  const handlePassErrors = (errors, data) => {
    const errorNames = Object.keys(data.errors)

    errorNames.forEach((errorName) => {
      setPassHasServerErrors((prevState) => {
        return {
          hasErrors: true,
          errors: [...prevState.errors, data.errors[errorName]],
        }
      })
    })
  }

  const handleSubmitPass = async (e) => {
    e.preventDefault()

    try {
      const response = await axios.post(
        `${config.BASE_URL}api/account/update-password`,
        {
          old_password: password,
          new_password: newPassword,
          new_password_confirmation: confirmPassword,
        },
        apiConfig
      )

      if (response.data.has_errors === true) {
        // Clear out previous errors
        setPassHasServerErrors({
          hasErrors: false,
          errors: [],
        })
        handlePassErrors(response.data.errors, response.data)
      } else {
        setPassHasServerErrors({
          hasErrors: false,
          errors: [],
        })
        setPassMessage(true)
      }
    } catch (error) {
      console.log("Error: ", error)
    }
  }

  return (
    <div className="myAccount">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Smart Screening - Delete My Account | Rent Panda
        </title>
        <meta
          name="description"
          content="Make edits or changes to your account information here."
        />
      </Helmet>
      <section className="MyAccountHeader">
        <div className="container">
          <button
            className="btn btn--back"
            onClick={() => navigate(-1)}
          >
            back
          </button>
          <h1>Hello {user.first_name}!</h1>
        </div>
      </section>
      <section className="MyAccountInfo">
        <div className="container">
          <div className="MyAccount__container">
            <h2>account information</h2>
            <div className="row">
              <div className="col-lg-4">
                <TextInput
                  type="text"
                  label="first name"
                  name="first_name"
                  placeholder={user.first_name}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="col-lg-4">
                <TextInput
                  type="text"
                  label="last name"
                  name="last_name"
                  placeholder={user.last_name}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="col-lg-4">
                <TextInput
                  type="text"
                  label="phone number"
                  name="phone_number"
                  placeholder={user.phone_number}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>

              <div className="col-lg-4">
                <label htmlFor="email">email address</label>
                <input
                  className="form_group"
                  type="email"
                  name="email"
                  placeholder={user.email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  onBlur={handleFocus}
                />
                <span className="errorMessage">
                  Must be a valid email address!
                </span>
              </div>
            </div>
            <div className="btn-group">
              <button
                className="btn btn--primary btn--small"
                onClick={(e) => {
                  e.preventDefault()
                  handleSubmit(e)
                }}
              >
                save changes
              </button>

              {infoHasServerError.hasErrors && (
                <div className="ml-2 mb-0-5 status-label serverErrs">
                  {infoHasServerError.errors.map((error, index) => (
                    <span key={index} className={"serverErrText"}>
                      {error}
                    </span>
                  ))}
                </div>
              )}
              {(infoMessage && !infoHasServerError.hasErrors) && (
                <span className="ml-2 mb-0-5 status-label">
                  changes saved!
                </span>
              )}

            </div>
          </div>
        </div>
      </section>
      {/*
      <section className="MyAccountPaymentMethod">
        <div className="container">
          <div className="MyAccount__container">
            <h2>payment method</h2>
            <h3>saved payment method</h3>
            <div className="payment-method-card">
              <button className="btn btn--edit"></button>
              <div className="payment-method-card__label">Visa</div>
              <div className="payment-method-card__number">
                **** **** **** 1234
              </div>
              <div className="payment-method-card__details">
                <div className="payment-method-card__exp">
                  <small>exp:</small>
                  <span>12/24</span>
                </div>
                <div className="payment-method-card__cvv">
                  <small>cvv:</small>
                  <span>123</span>
                </div>
              </div>
            </div>

            {/* Show when user clicks edit button. hide the card. */}
      {/* hiding payment for now *
            <div className="payment-method-edit-form">
              <TextInput
                type="text"
                label="Card number"
                name="card_number"
              />
              <div className="row">
                <div className="col-lg-6">
                  <DatePickerInput
                    label="Exp. Date"
                    name="exp_date"
                  />
                </div>
                <div className="col-lg-6">
                  <TextInput
                    label="CVV"
                    name="cvv"
                    placeholder="123"
                  />
                </div>
              </div>
              <div className="btn-group">
                <button className="btn btn--small btn--shrink btn--primary">
                  save card
                </button>
                <button className="btn btn--small btn--shrink btn--secondary-alt">
                  cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      */}
      <section className="MyAccountPaymentMethod">
        <div className="container">
          <div className="MyAccount__container">
            <h2>reset password</h2>
            <form action="submit">


            <div className="row">
              <div className="col-lg-4">
                <label htmlFor="old password">old password</label>
                <input
                  className="form_group"
                  type="password"
                  placeholder="old password"
                  autoComplete={"off"}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  onBlur={handleFocus}
                  // focused={focused.toString()}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <label htmlFor="new password">new password</label>
                <input
                  className="form_group"
                  type="password"
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  onBlur={handleFocus}
                  pattern={confirmPassword}
                  // focused={focused.toString()}
                />
              </div>
              <div className="col-lg-4">
                <label htmlFor="confirm password">
                  confirm password
                </label>
                <input
                  className="form_group"
                  type="password"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value)
                    checkParity(e)
                  }}
                  required
                  onFocus={() => setFocused(true)}
                  // focused={focused.toString()}
                  pattern={newPassword}
                />
                <span className="errorMessage">
                  Passwords don't match!
                </span>
              </div>
            </div>
            <div className="btn-group">
              <button
                className="btn btn--primary btn--small"
                type={"submit"}
                onClick={handleSubmitPass}
              >
                save changes
              </button>

              {passHasServerErrors.hasErrors && (
                  <div className="ml-2 mb-0-5 status-label serverErrs">
                    {passHasServerErrors.errors.map((error, index) => (
                      <span key={index} className={"serverErrText"}>
                        {error}
                      </span>
                    ))}
                  </div>
                )}

              {(passMessage && !passHasServerErrors.hasErrors) && (
                <span className="ml-2 mb-0-5 status-label">
                  changes saved!
                </span>
              )}
            </div>
            </form>
          </div>
        </div>
      </section>

      <div className="container mb-4">
        <div className="row justify-content-end">
          <div className="col-auto">
            <button
              className="btn MyAccount__delete-account-btn"
              onClick={() => {
                setOpenDeleteAccountModal(true)
              }}
            >
              delete account
            </button>
          </div>
        </div>
      </div>
      {openDeleteAccountModal && (
        <DeleteAccountModal closeModal={setOpenDeleteAccountModal} />
      )}
    </div>
  )
}

export default MyAccount
