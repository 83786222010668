import { useContext, useState, useEffect } from "react"
import { AuthContext } from "../Context/authContext/AuthContext"
import { userInfoChange } from "../Context/authContext/AuthActions"


export const useUpdateUser = (newKeys, initState) => {
  const { user, dispatch } = useContext(AuthContext)
  const [value, setValue] = useState(initState)

  const updateUser = () => {
    const updatedUser = {...user}
    const updatedFields = Object.keys(value)
    updatedFields.forEach((key, index) => {
      switch (key) {
        default:
          break
        case "first_name":
          updatedUser.first_name = value.first_name
          break
        case "last_name":
          updatedUser.last_name = value.last_name
          break
        case "email":
          updatedUser.email = value.email
          break
        case "phone_number":
          updatedUser.phone_number = value.phone_number
      }
    })
    dispatch(userInfoChange(updatedUser))
    localStorage.setItem("user", JSON.stringify(updatedUser))
  }


  useEffect(() => {
    updateUser()

  }, [value])

  return [value, setValue]
}