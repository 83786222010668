import axios from "axios"
import { useState } from "react"
import Cookies from "universal-cookie"
import SelectInput from "Components/Input/SelectInput"
import TextInput from "Components/Input/TextInput"
import BackgroundCheckConfirmModal from "../BackgroundCheckConfirmModal/BackgroundCheckConfirmModal"
import config from "config/config"

function BackgroundCheckModal({ setOpenBackgroundCheckModal }) {
  const [openModal, setOpenModal] = useState(false)
  const [address, setAddress] = useState(false)
  const [city, setCity] = useState(false)
  const [province, setProvince] = useState("AB")
  const cookie = new Cookies()
  return (
    <div className="BackgroundCheckConfirmModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className="modal__container">
          <h2>please provide your SIN and birthdate</h2>
          <p>
            <strong>
              this information will not be saved or displayed to the
              landlord.
              <br />
              It is used to accurately pull your background check
              directly from Certn.
            </strong>
          </p>
          <div className="row w-full">
            <div className="col-md-6">
              <TextInput
                type="text"
                label="your address"
                name="address"
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <TextInput
                type="text"
                label="your city"
                name="city"
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
          </div>{" "}
          <div className="row w-full">
            <div className="col-md-12">
              <SelectInput
                label="your province"
                name="province"
                inline="true"
                onChange={(e) => setProvince(e.target.value)}
              >
                <option value="AB">Alberta</option>
                <option value="BC">British Columbia</option>
                <option value="MB">Manitoba</option>
                <option value="NB">New Brunswick</option>
                <option value="NL">Newfoundland and Labrador</option>
                <option value="NS">Nova Scotia</option>
                <option value="NT">Northwest Territories</option>
                <option value="NU">Nunavut</option>
                <option value="ON">Ontario</option>
                <option value="PE">Prince Edward Island</option>
                <option value="QC">Quebec</option>
                <option value="SK">Saskatchewan</option>
                <option value="YT">Yukon</option>
              </SelectInput>
            </div>
          </div>
          <br />
          <div className="btn-group">
            <button
              className="btn btn--primary"
              onClick={() => {
                axios
                  .post(
                    `${config.BASE_URL}api/background-check`,
                    {
                      //sin,
                      address,
                      city,
                      province,
                      country: "CA",
                      application_id: localStorage.getItem(
                        "currentApplicationId"
                      ),
                    },
                    {
                      headers: {
                        Authorization:
                          "Bearer " + cookie.get("auth_token"),
                      },
                    }
                  )
                  .then(() => {
                    setOpenModal(true)
                  })
              }}
            >
              next
            </button>
            <button
              className="btn btn--back"
              onClick={() => {
                setOpenBackgroundCheckModal(false)
              }}
            >
              cancel
            </button>
          </div>
        </div>
      </div>
      {openModal ? <BackgroundCheckConfirmModal /> : null}
    </div>
  )
}

export default BackgroundCheckModal
