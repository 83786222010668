import "./housing.scss"
import ProgressBar from "../../../Components/ProgressBar/ProgressBar"
import TextInput from "../../../Components/Input/TextInput"
import TextareaInput from "../../../Components/Input/TextareaInput"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import config from "../../../config/config"
import Cookies from "universal-cookie"
import PlacesAutocomplete from "react-places-autocomplete"
import SelectInput from "../../../Components/Input/SelectInput"

function Housing(props) {
  const EXCEEDS_LIMIT = new Error(
    "Rent amount exceeds limit: \n $1,000,000 please enter a lower amount"
  )
  const NOT_DIGITS = new Error("Please enter a valid number")
  const cookies = new Cookies()

  const currApplicantId = localStorage.getItem("currentApplicantId")
  const applicationId = localStorage.getItem("currentApplicationId")
  const initHousingState = {
    type: props.type,
    ownership: "own",
    address: "",
    unit_number: "",
    duration: 0,
    monthly_rent: 0,
    reason_for_leaving: "",
    application_id: applicationId,
    landlord_name: "",
    landlord_phone: "",
    landlord_email: "",
  }

  const [rentCostErr, setRentCostErr] = useState(false)
  const [rentCostErrMsg, setRentCostErrMsg] = useState("")

  const [isAllClear, setIsAllClear] = useState(true)
  const [address, setAddress] = useState("")
  const [addressErr, setAddressErr] = useState(false)
  const [addressErrMsg, setAddressErrMsg] = useState("")
  const [housingData, setHousingData] = useState(initHousingState)

  const [unitNumberErr, setUnitNumberErr] = useState(false)
  const [unitNumberErrMsg, setUnitNumberErrMsg] = useState("")

  const [durationErr, setDurationErr] = useState(false)
  const [durationErrMsg, setDurationErrMsg] = useState("")

  const [userIsUpdating, setUserIsUpdating] = useState(false)

  const [reasonLeaveErr, setReasonLeaveErr] = useState(false)
  const [reasonLeaveErrMsg, setReasonLeaveErrMsg] = useState("")

  useEffect(() => {
    axios
      .get(`${config.BASE_URL}api/housing/${currApplicantId}`, {
        headers: {
          Authorization: `Bearer ${cookies.get("auth_token")}`,
        },
      })
      .then((res) => {
        const housings = res.data.housings

        if (Array.isArray(housings) && housings.length > 0) {
          const currentHouse = housings.find(
            (house) => house.type === "current"
          )
          const previousHousings = housings.find(
            (house) => house.type === "previous"
          )
          if (currentHouse && props.type === "current") {
            setUserIsUpdating(true)
            trackHouseFromBackEnd(currentHouse)
          }
          if (previousHousings && props.type === "previous") {
            setUserIsUpdating(true)
            trackHouseFromBackEnd(previousHousings)
          }

        } else {
          // Handle no existing housings start w/ init state
          trackHouseFromBackEnd(housingData)
          setUserIsUpdating(false)
        }
      })
  }, [])

  const trackHouseFromBackEnd = (house) => {
    setAddress(house.address)
    setHousingData((prevState) => ({
      ...prevState,
      ownership: house.ownership,
      type: house.type,
      unit_number: house.unit_number || "",
      duration: house.duration,
      monthly_rent: house.monthly_rent || 0,
      reason_for_leaving: house.reason_for_leaving,
      applicant_id: house.applicant_id,
      created_at: house.created_at,
      address: house.address,
      updated_at: house.updated_at,
      user_profile_id: house.user_profile_id,
      id: house.id,
    }))
  }

  const searchOptions = {
    componentRestrictions: { country: ["ca"] },
  }
  const navigate = useNavigate()

  const handleChange = (e) => {
    switch (e.target.name) {
      default:
        break
      case "unit_number":
      case "duration":
      case "monthly_rent":
        if (e.target.value < 0) {
          // Silently ignore if value is negative
          return
        }
        break
    }

    setHousingData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleSelect = (value) => {
    setAddress(value)
    setHousingData((prevState) => ({
      ...prevState,
      address: value,
    }))
  }

  const validateHowMuchRent = () => {
    const digitPattern = /^\d+$/
    const limit = 1000000
    let inputNoSpaces
    if (housingData.monthly_rent) {
      inputNoSpaces = housingData.monthly_rent.replace(/\s+/g, "")
    }
    const isOnlyDigits = digitPattern.test(inputNoSpaces)

    if (!isOnlyDigits) {
      throw NOT_DIGITS
    }
    if (inputNoSpaces > limit) {
      throw EXCEEDS_LIMIT
    }

    setRentCostErr(false)
    setRentCostErrMsg("")
    setIsAllClear(true)
  }

  const ONLY_NUMS_ALLOWED = new Error("Please enter only numbers")
  const NO_ZERO_ALLOWED = new Error("Please enter a number greater than zero")
  const NO_EMPTY_DURATION = new Error("Please enter how long you lived here")
  const INVALID_CHARS = new Error("Please enter a valid unit number")
  const UNIT_NUM_TOO_LONG = new Error("Unit number is too long")

  const validateUnitNumber = () => {
    if (housingData.unit_number === "") return
    if (housingData.unit_number.length > 100) throw UNIT_NUM_TOO_LONG
    const unitNum = /^[\s\da-zàèìòùÀÈÌÒÙáéíóúýâêîôûãñõäëïöüÿ:./-]*$/gmi
    const result = unitNum.test(housingData.unit_number)

    if (!result) throw INVALID_CHARS
    setUnitNumberErr(false)
  }

  const validateDuration = () => {
    const digitPattern = /^\d+$/
    const result = digitPattern.test(housingData.duration)

    if (housingData.duration === "") throw NO_EMPTY_DURATION
    // This previously held a check to be above 0, with new
    // implementation, we allow 0 to be a valid duration (aka less than a year)
    if (!result) throw ONLY_NUMS_ALLOWED
    setDurationErr(false)
  }

  const handleBlurRent = () => {
    try {
      validateHowMuchRent()
    } catch (err) {
      setRentCostErr(true)
      setRentCostErrMsg(err.message)
      setIsAllClear(false)
    }
    if (housingData.ownership === "own") {
      setIsAllClear(true)
      setRentCostErr(false)
    }
  }

  const submitData = () => {
    if (applicationId !== undefined) {
      if (userIsUpdating) {
        // API w/ which housing entry to update
        axios
          .post(
            `${config.BASE_URL}api/housing/${housingData.id}`,
            { ...housingData },
            {
              headers: {
                Authorization: "Bearer " + cookies.get("auth_token"),
              },
            }
          )
          .then(({ data }) => {
            if (!data.has_errors) {
              props.type === "current"
                ? navigate("/tenant/previous-housing")
                : navigate("/tenant/employment-info")
            }
          })
          .catch((e) => {
            console.error("Error: ", e)
          })
      } else if (isAllClear) {
        // API to handle new housing entry
        axios
          .post(
            `${config.BASE_URL}api/housing`,
            { ...housingData },
            {
              headers: {
                Authorization: "Bearer " + cookies.get("auth_token"),
              },
            }
          )
          .then((res) => {
            if (!res.data.has_errors) {
              props.type === "current"
                ? navigate("/tenant/previous-housing")
                : navigate("/tenant/employment-info")
            }
          })
          .catch((e) => {
            console.error("Error: ", e)
          })
      }
    }
  }

  const handleBlurUnitNumber = () => {
    try {
      validateUnitNumber()
    } catch (err) {
      setUnitNumberErr(true)
      setUnitNumberErrMsg(err.message)
    }
  }

  const handleBlurDuration = () => {
    try {
      validateDuration()
    } catch (err) {
      setDurationErr(true)
      setDurationErrMsg(err.message)
    }
  }

  const NO_ADDRESS_SELECTED = new Error("Please select an address")

  const validateAddress = () => {
    if (address === "") {
      throw NO_ADDRESS_SELECTED
    } else {
      setAddressErr(false)
      setAddressErrMsg("")
    }
  }

  const NO_REASON_LEAVE = new Error("Please enter a reason for leaving")

  const validateReasonLeave = () => {
    if (housingData.reason_for_leaving === ""
    ) {

      throw NO_REASON_LEAVE
    } else {
      setReasonLeaveErr(false)
      setReasonLeaveErrMsg("")
    }
  }

  const handleBlurReasonLeave = () => {
    try {
      validateReasonLeave()
    } catch (err) {
      setReasonLeaveErr(true)
      setReasonLeaveErrMsg(err.message)
    }
  }

  const checkAddressBeforeSubmit = () => {
    try {
      validateAddress()
    } catch (err) {
      setAddressErr(true)
      setAddressErrMsg(err.message)
    }
  }

  const handleNextButton = () => {
    // check for any errors and if so, don't submit
    checkAddressBeforeSubmit()
    handleBlurDuration()
    if (
      !rentCostErr &&
      !unitNumberErr &&
      !durationErr &&
      !addressErr &&
      !reasonLeaveErr
    ) {
      submitData()
    }
  }

  const rentQuestionLabel = (props.type === "current") ? "how much do you pay in rent?" : "how much did you pay in rent"

  return (
    <section className="Housing">
      <div className="container">
        <button
          className="btn btn--back"
          onClick={() => {
            navigate(-1)
          }}
        >
          back
        </button>
        <ProgressBar type="tenant" step="3" />

        {props.type === "current" && <h1>current housing</h1>}
        {props.type === "previous" && <h1>previous housing</h1>}
        <div className="form-group">
          <label>do you own or rent?</label>
          <div className="form-choose-group">
            <button
              id="own"
              value={"own"}
              name={"ownership"}
              className={
                housingData.ownership === "own"
                  ? "choose-input-group label active"
                  : "choose-input-group label"
              }
              onClick={handleChange}
            >
              own
            </button>
            <button
              id="rent"
              value={"rent"}
              name={"ownership"}
              className={
                housingData.ownership === "rent"
                  ? "choose-input-group label active"
                  : "choose-input-group label"
              }
              onClick={handleChange}
            >
              rent
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4 col-md-6">
            <div className="form-group">
              {addressErr && (
                <p className="text-danger">{addressErrMsg}</p>
              )}
              <label htmlFor="address">address</label>

              <PlacesAutocomplete
                value={address}
                name={"address"}
                onChange={setAddress}
                onSelect={handleSelect}
                className=""
                id="address_autocomplete"
                searchOptions={searchOptions}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div key={suggestions.placeId}>
                    <input
                      {...getInputProps({
                        placeholder: "Search Places ...",
                        className: "form-group__form-control",
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item"
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                            }
                          : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                            }
                        return (
                          <div
                            key={suggestion.placeId}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>

            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            {unitNumberErr && (
              <p className="text-danger">{unitNumberErrMsg}</p>
            )}
            <TextInput
              type="text"
              value={housingData.unit_number}
              onChange={handleChange}
              onBlur={handleBlurUnitNumber}
              hasError={unitNumberErr}
              label="unit number"
              name="unit_number"
              min
              placeholder="Unit 000"
            />
          </div>

          {housingData.ownership === "rent" && (
            <div className="col-xl-4"></div>
          )}

          <div className="col-xl-4 col-md-6">
            {durationErr && (
              <p className={"text-danger"}>{durationErrMsg}</p>
            )}

            <SelectInput
              label="how long have you lived here"
              name="duration"
              onChange={handleChange}
              defaultValue={housingData.duration}
            >
              <option value={0}>less than a year</option>
              <option value={1}>1 year</option>
              <option value={2}>2 years</option>
              <option value={3}>3 years</option>
              <option value={4}>4 years</option>
              <option value={5}>5 years</option>
              <option value={6}>6 years</option>
              <option value={7}>7 years</option>
              <option value={8}>8 years</option>
              <option value={9}>9 years</option>
              <option value={10}>10 years</option>
              <option value={11}>more than 10 years</option>
            </SelectInput>
          </div>
          {housingData.ownership === "rent" && (
          <div>
            <div className="col-xl-4 col-md-6">
              {rentCostErr && (
                <p className="text-danger">{rentCostErrMsg}</p>
              )}
              <TextInput
                type="number"
                value={housingData.monthly_rent}
                onChange={handleChange}
                onBlur={handleBlurRent}
                hasError={rentCostErr}
                label={rentQuestionLabel}
                name="monthly_rent"
              />
            </div>
            
            <div className="LandlordInfo__reference">
              <label>provide a landlord reference for this housing</label>

              <div className="row">
                <div className="col-md-4">
                  <TextInput
                    type="text"
                    name="landlord_name"
                    placeholder="name"
                    onChange={handleChange}
                    />
                </div>
                <div className="col-md-4 col-sm-6">
                  <TextInput
                    type="text"
                    name="landlord_phone"
                    placeholder="phone number"
                    onChange={handleChange}
                    />
                </div>
                <div className="col-md-4 col-sm-6">
                  <TextInput
                    type="email"
                    name="landlord_email"
                    placeholder="email address"
                    onChange={handleChange}
                    />
                </div>
              </div>
            </div>
          </div>
          )}
        </div>

        <div className="row">
          <div className="col-lg-10">
            {reasonLeaveErr && (
              <p className={"text-danger"}>{reasonLeaveErrMsg}</p>
            )}
            <TextareaInput
              label="Reason for leaving"
              name="reason_for_leaving"
              value={housingData.reason_for_leaving}
              onChange={handleChange}
              onBlur={handleBlurReasonLeave}
              hasError={reasonLeaveErr}
            />
          </div>
        </div>
        <div className="btn-group">
          <button
            className="btn btn--primary"
            onClick={handleNextButton}
          >
            next
          </button>
          {props.type === "previous" && (
            <button
              className="btn"
              onClick={() => {
                navigate("/tenant/employment-info")
              }}
            >
              skip
            </button>
          )}
        </div>
      </div>
    </section>
  )
}

export default Housing
