import "./PriceLabel.scss"
import clsx from "clsx"

const PriceLabel = ({value, isFree = false, isComingSoon = false}) => {

  if (isComingSoon) {
    return (
      <div className={"isComingSoon labelBackground"}>
        Coming Soon
      </div>
      )
  } else {

    return (
      <div className={clsx("labelBackground", isFree && "priceLabelBlack")}>
        {isFree ? "Free" : `$${value}`}
      </div>
    )
  }
}

export default PriceLabel