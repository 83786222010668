import "./application.scss"
import "../AddressInformation/addressInformation.scss"

import ApplicantInfo from "./ApplicantInfo/ApplicantInfo"
import HousingInfo from "../../../Components/ApplicationPreview/HousingInfo/HousingInfo"
import EmploymentInfo from "../../../Components/ApplicationPreview/EmploymentInfo/EmploymentInfo"
import HouseholdInfo from "../../../Components/ApplicationPreview/HouseholdInfo/HouseholdInfo"
import Guarantor from "../../../Components/ApplicationPreview/Guarantor/Guarantor"
import PremiumLocked from "../../../Components/ApplicationPreview/PremiumLocked/PremiumLocked"
import CreditCheck from "../../../Components/ApplicationPreview/CreditCheck/CreditCheck"
import BackgroundCheck from "../../../Components/ApplicationPreview/BackgroundCheck/BackgroundCheck"
import IDVerification from "../../../Components/ApplicationPreview/IDVerification/IDVerification"
import PersonalizedVideo from "../../../Components/ApplicationPreview/PersonalizedVideo/PersonalizedVideo"
import VerifiedLandlordReference from "../../../Components/ApplicationPreview/VerifiedLandlordReference/VerifiedLandlordReference"
import BankCheck from "../../../Components/ApplicationPreview/BankCheck/BankCheck"
import HowWeRankModal from "./HowWeRankModal/HowWeRankModal"
import Cookies from "universal-cookie"
import config from "../../../config/config"
import HandleLoginRedirect from "../../../API/HandleLoginRedirect"
import axios from "axios"
import { useEffect, useReducer, useRef, useState } from "react"
import FilterModal from "./FilterModal/FilterModal"
import CoApplicants from "../../../Components/ApplicationPreview/CoApplicants/CoApplicants"
import { useNavigate } from "react-router-dom"
import PlacesAutocomplete from "react-places-autocomplete"
import Helmet from "react-helmet"
import TextInput from "../../../Components/Input/TextInput"
import clsx from "clsx"
import Trophy from "../../../assets/svg/trophy.svg"
import { useCookie } from "../../../Hooks/useCookie"
import { PREMIUM_FEATURES_LIST } from "../../../util/PremiumFeaturesListAndIDs"


function numberToCurrency(number) {
  return new Intl.NumberFormat("en-US", {
  }).format(number)
}

function UserInfo({
  coapplicants,
  currentUserApplication,
  data,
  numberToCurrency,
  previousUser,
  setPreviousUser,
  user,
  user_id,
  toggleFavStatus
}) {
  const [auth_token] = useCookie("auth_token", null)
  const [applicationId] = useCookie( "current_property_application_id", null)
  const [premiumPending, setPremiumPending] = useState([...PREMIUM_FEATURES_LIST])

  const coreData = {
    currentUserApplication,
    data,
    numberToCurrency,
    previousUser,
    setPreviousUser,
    user,
    user_id,
  }


  const setTrueStatus = (feature) => {

    if (feature.is_completed === 1 && feature.is_paid === 1 && feature.is_processed === 1) {
      return "completed"
    } else if (feature.is_processed === 1 && feature.is_completed === 0) {
      return "processed"
    } else if (feature.is_paid === 1) {
      return "paid"
    } else if (feature.is_processed === 0 && feature.is_paid === 0) {
      return "not_purchased"
    } else {
      return "awaiting"
    }
  }

  useEffect(() => {
    const getFeature = (features) => {
     let premiumFeatures = []
      features.forEach((feature) => {
        if (feature.tenant_id === user_id) {
          premiumFeatures.push(...feature.items)
        }
      })
      return premiumFeatures
    }

    if (applicationId !== null && user_id !== null) {
      const response = axios.get(
        `${config.BASE_URL}api/application/${applicationId}/screening-requests`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      ).then((res) => {
        if (res.data.length > 0) {
          let targetFeatures = getFeature(res.data)

          if (targetFeatures.length === 0) {
            setPremiumPending([...PREMIUM_FEATURES_LIST])
          } else {
            const newPremiumPending = premiumPending.map(pending => {
              const item = targetFeatures.find(item => item.item === pending.id)
              if (item) {
                return {
                  ...pending,
                  status: setTrueStatus(item)
                }
              } else {
                return pending
              }
            })
            setPremiumPending(newPremiumPending)
          }
        }
      })
    }

    // Get updated status on applicants premium features every time the applicant_id changes
  },[data.applicant_id])


  return (
    <>
      {previousUser && (
       <button
            className="btn btn--back"
            onClick={() => {
              currentUserApplication(previousUser)
            }}
          >
        back to primary applicant
      </button>
      )}

      {coapplicants.length > 0 ? (
        <CoApplicants coapplicants={coapplicants}
                      coreData={coreData}
                      previousUser={previousUser}
                      setPreviousUser={setPreviousUser}
                      userId={user_id} />
      ): null}


      <ApplicantInfo user={user} data={data} toggleFavStatus={toggleFavStatus} />
      {user.housings
        .filter((house) => house.type === "current")
        .map((house) => (
          <HousingInfo
            type="current"
            display={house}
            key={house.id}
          />
        ))}

      {user.housings
        .filter((house) => house.type === "previous")
        .map((house) => (
          <HousingInfo
            type="previous"
            display={house}
            key={house.id}
          />
        ))}

      {user.employments.map((employment) => (
        <EmploymentInfo display={employment} key={employment.id} />
      ))}

      {user.households.map((house) => (
        <HouseholdInfo display={house} key={house.id} />
      ))}

      {data?.credit_checks?.map((credit_check) => (
        <CreditCheck
          key={credit_check.id}
          type="landlord"
          data={credit_check}
        />
      ))}

      {data?.employment_income_verifications?.map((bank_check) => (
        <BankCheck display={bank_check} key={bank_check.id} />
      ))}

      {data?.background_checks?.map((background_check) => (
        <BackgroundCheck
          key={background_check.id}
          type="landlord"
          data={JSON.parse(background_check.certn_data || "null")}
        />
      ))}

      {data?.id_verifications?.map((id) => {
        return <IDVerification display={id} key={id.id} />
      })}

      {user?.landlord_references?.length > 0 ? (
        <VerifiedLandlordReference
          display={user.landlord_references}
        />
      ): null}

      {!data?.credit_checks?.length && (
        <PremiumLocked
          title="credit check"
          description="detailed credit report including credit score, who they owe money to and how much, all cases sent to collections and past bankruptcies."
          pending={premiumPending.find(item => item.id === "credit_check").status}
          meta={{
            name: user.first_name + " " + user.last_name,
            contact_type: user.email ? "email" : "phone",
            contact_info: user.email ?? "",
            phone: user.phone_number ?? "",
            selected_features: ["credit_check"],
          }}
        />
      )}

      {!data?.background_checks?.length ? (
        <PremiumLocked
          title="background check"
          description="exposes any criminal record, shows Landlord and Tenant Board appearances, analyzes their social media profiles and checks all public records."
          pending={premiumPending.find(item => item.id === "background_check").status}
          meta={{
            name: user.first_name + " " + user.last_name,
            contact_type: user.email ? "email" : "phone",
            contact_info: user.email ?? "",
            phone: user.phone_number ?? "",
            selected_features: ["background_check"],
          }}
        />
      ): null }
      {!data?.employment_income_verifications?.length ? (
        <PremiumLocked
          title="income & expenses verification"
          description="we scan their bank statements, access their pay stubs and measure their expenses with details directly from the bank."
          pending={premiumPending.find(item => item.id === "financial_verification").status}
          meta={{
            name: user.first_name + " " + user.last_name,
            contact_type: user.email ? "email" : "phone",
            contact_info: user.email ?? "",
            phone: user.phone_number ?? "",
            selected_features: ["financial_verification"],
          }}
        />
      ): null}

      {!data?.id_verifications?.length ? (
        <PremiumLocked
          title="ID verification"
          description="verified government ID and face matching technology to ensure safety and security."
          pending={premiumPending.find(item => item.id === "id_verification").status}
          meta={{
            name: user.first_name + " " + user.last_name,
            contact_type: user.email ? "email" : "phone",
            contact_info: user.email ?? "",
            phone: user.phone_number ?? "",
            selected_features: ["id_verification"],
          }}
        />
      ): null }

      {/* disable landlord ref purchase for now.
      {user?.landlord_references?.length ? (
        <PremiumLocked
          title="verified landlord references"
          description="real reviews from past landlords who have been verified through lease and landlord ID matching technology."
          pending={null}
          meta={{
            name: user.first_name + " " + user.last_name,
            contact_type: user.email ? "email" : "phone",
            contact_info :user.email ?? "",
            phone: user.phone_number ?? "",
            selected_features: ["reference_check"],
          }}
        />
      ): null }
      */}
    </>
  )
}
function ApplicantCard({
  top,
  name,
  houseSize,
  income,
  pets,
  smoke,
  profilePercent,
  is_favourite,
  onClick,
  disabled,
  user_id,
  firstHighlighted
}) {

  // Add a useRef to add the class of "init-focus" to the first element in the list
  const ref = useRef(null);
  useEffect(() => {
    if (firstHighlighted) {
      ref.current.classList.add("init-focus");
    } else if (!firstHighlighted) {
      ref.current.classList.remove("init-focus");
    }
  },[firstHighlighted])

  // temp fix, backend logic is not working
  const noZeroHouseSize = houseSize + 1

  const contents = (
    <div  key={user_id}>
      <div ref={ref} className="applicant-card__container">
        {top && <div className="top-badge">
          <img src={Trophy} alt="" />
          top tenant
        </div>}
        <div className="favouriteContainer">
          <span
            className={`attribute-icon--favorite ${
              is_favourite === 1 ? "fav" : "notFav"
            }`}
          />
        </div>
        <h2>{name}</h2>
        <div className="applicant-card__attributes-container">
          <div className="applicant__attribute">
            <span className="applicant__attribute__icon attribute-icon--household">
              {noZeroHouseSize}
            </span>
            <span className="applicant__attribute__label">
              {noZeroHouseSize} person household
            </span>
          </div>
          <div className="applicant__attribute">
            <span className="applicant__attribute__icon attribute-icon--income"></span>
            <span className="applicant__attribute__label">
              $ {numberToCurrency(income)} income
            </span>
          </div>
          <div className="applicant__attribute">
            <span className="applicant__attribute__icon attribute-icon--has-pets"></span>
            <span className="applicant__attribute__label">
              {pets.length > 0 ? "has" : "no"} pets
            </span>
          </div>
          <div className="applicant__attribute">
            <span className="applicant__attribute__icon attribute-icon--non-smoker"></span>
            <span className="applicant__attribute__label">
              {smoke === 0 && "non-"}smoker
            </span>
          </div>
        </div>
      </div>
    </div>
  )
  return (
    <div className={
        "applicant-card " +
        (disabled ? "applicant-card--retracted" : "")
      }>
      {(disabled && contents) || (
        <a href="#?" onClick={onClick}>
          {contents}
        </a>
      )}
    </div>
  )
}
function Application(props) {
  const [openHowWeRankModal, setOpenHowWeRankModal] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [showFirstUser, setShowFirstUser] = useState(false)
  const [highlightFirstUser, setHighlightFirstUser] = useState(true)
  const [data, setData] = useState({})
  const navigate = useNavigate()
  const [updateMode, setUpdateMode] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [allApplicants, setAllApplicants] = useState([])
  const [applicants, setApplicants] = useState([])
  const [baseList, setBaseList] = useState([])

  const [ignored, forceUpdate] = useReducer(x => x + 1, 0)
  const [previousUser, setPreviousUser] = useState(null)
  const [shouldAddressUpdate, setShouldAddressUpdate] = useState(false)

  const [error, setError] = useState(false)
  const [address, setAddress] = useState("")
  const searchOptions = {
    componentRestrictions: { country: ["ca"] },
  }

  const [applicantsErr, setApplicantsErr] = useState(false)

  const initFilterConfig = {
    filterApplied: false,
    householdRange: {
      abs: {
        min: 0,
        max: 0,
      },
      curr: {
        min: 0,
        max: 0,
      }
    },
    salaryRange: {
      abs: {
        min: 0,
        max: 0,
      },
      curr: {
        min: 0,
        max: 0,
      }
    },
    creditRange: {
      abs: {
        min: 0,
        max: 0,
      },
      curr: {
        min: 0,
        max: 0,
      }
    },
    allowSmoke: true,
    pets: false,
    idVerification: false,
    bankCheck: false,
    backgroundCheck: false,
    filterByCreditCheck: false,
    filterByFavs: false
  }


  const [filterConfig, setFilterConfig] = useState(initFilterConfig)
  const [searchText, setSearchText] = useState("")

  // Helper utilities
  const getHighestOccupancy = (baseList) => {
    let highestOccupancy = 0

    baseList.forEach((applicant) => {
      const occupantTotal = getTotalTenantCount(applicant)
      if (occupantTotal > highestOccupancy) {
        highestOccupancy = occupantTotal
      }
    })

    return highestOccupancy
  }

  const getHighLowSalary = (baseList) => {
    let high = 0
    let low = 0
    baseList.forEach((applicant) => {
      // NOTE: The curr salary should be found via employment.is_past value === 1
      // However, at this time no employments have that set to 1.
      // let salary = applicant.user.employments[0]?.salary
      let salary = applicant.user.employments.reduce(
        (a, v) => a + v.salary * (v.is_past === 0),
        0 )
      if (salary < 0) salary = 0
      if (salary > high) {
        high = salary
      }
      if (salary < low) {
        low = salary
      }
    })

    return { min: low, max: high }
  }

  const getHighLowCredit = (baseList) => {
    let high = 0
    let low = 0
    baseList.forEach((applicant) => {
      if  (applicant.credit_checks.length > 0) {
        let credit = parseInt(applicant.credit_checks[0].credit_score)
        if (credit < 0) credit = 0
        if (credit > high) {
          high = credit
        }
        if (credit < low) {
          low = credit
        }
      }
    })

    return { min: low, max: high }
  }

  const [topApplicants, setTopApplicants] = useState([])
  const getTopThree = (baseList) => {
    const topThree = []
    const tenantRatings = []
    baseList.forEach((applicant) => {
      const tenantRating = applicant.ranking_score
      const tenantId = applicant.user_id

      if (tenantRating >= 75) {
        tenantRatings.push({rating: tenantRating, id: tenantId})
      }
    })
    tenantRatings.sort((a, b) => b.rating - a.rating)
    for (let i = 0; i < 3; i++) {
      if (tenantRatings[i]) {
        topThree.push(tenantRatings[i].id)
      }
    }
    return topThree
  }

  // End utils, begin filter
  const resetFilter = (dataState) => {
    setApplicantsErr(false)

    const occupancy = getHighestOccupancy(dataState.applicants)
    const highLowSalary = getHighLowSalary(dataState.applicants)
    const highLowCredit = getHighLowCredit(dataState.applicants)

    setFilterConfig({
      ...initFilterConfig,
      householdRange: {
        abs: {
          min: 0,
          max: occupancy
        },
        curr: {
          min: 0,
          max: occupancy
        }
      },
      salaryRange: {
        abs: {
          ...highLowSalary
        },
        curr: {
          ...highLowSalary
        }
      },
      creditRange: {
        abs: {
          ...highLowCredit
        },
        curr: {
          ...highLowCredit
        }
      },
      allowSmoke: true,
      pets: false,
      idVerification: false,
      bankCheck: false,
      backgroundCheck: false,
      filterByCreditCheck: false,
      filterByFavs: false
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })

    const cookies = new Cookies()
    const currentPropertyId = cookies.get(
      "current_property_application_id" )
    const apiConfig = {
      headers: {
        Authorization: "Bearer " + cookies.get("auth_token"),
      }}

    axios.get(
      `${config.BASE_URL}api/property/` + currentPropertyId,
      apiConfig )
      .then(function(response) {
        if (HandleLoginRedirect(navigate, response) === false) {
          if (typeof response.data.property !== "undefined") {
            setData(response.data)

            const nonCoApplicants = response.data.applicants.filter((applicant) => {
              return applicant.isCoapplicant === false
            })

            setAddress(response.data.property.address)
            // All applicants include both co applicants and main applicants
            setAllApplicants(response.data.applicants)
            // BaseList is the immutable list
            setBaseList(nonCoApplicants)
            // Applicants is the mutable list
            setApplicants(nonCoApplicants)
            resetFilter(response.data)
            setShowFirstUser(true)

            setTopApplicants(getTopThree(nonCoApplicants))
          }
        }
      })
      .catch(function(error) {
        let response = error.response
        if (HandleLoginRedirect(navigate, response) === false) {
        }
      })

  }, [])

  const handleSelect = (value) => {
    setAddress(value)
    setShouldAddressUpdate(true)
  }

  const handleUpdateNewAddress = () => {
    const cookies = new Cookies()
    const currentPropertyId = cookies.get(
      "current_property_application_id"
    )
    const apiConfig = {
      headers: {
        Authorization: "Bearer " + cookies.get("auth_token"),
      },
    }
    axios.post(
        `${config.BASE_URL}api/application/` + currentPropertyId,
        {
          address: address,
        }, apiConfig )
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error.message)
      })
  }

  const focusOnSelectedUser = (removeHighlight = false) => {
    let selected = document.getElementsByClassName(
      "applicant-card__container"
    )
    if (selected.length > 0) {
      selected[0].classList.add("init-focus")
    } else {
      selected[0].classList.remove("init-focus")
    }
  }

  // This selects an application based off the users user_id property
  const selectApplicationForView = (usersAppId) => {
    // from the baseList find the user with the matching userAppId
    let selectedApplication = allApplicants.find((user) => {
      return user.user_id === usersAppId
    })

    if (typeof selectedApplication.isCoapplicant != 'undefined') {
      if(!selectedApplication.isCoapplicant) setPreviousUser(null)
    }

    const {
      user,
      credit_checks,
      background_checks,
      employment_income_verifications,
      id_verifications,
      coapplicants,
      user_id,
    } = selectedApplication
    const data = {
      user_id,
      coapplicants,
      credit_checks,
      background_checks,
      employment_income_verifications,
      id_verifications,
      houseSize: user.households[0]?.tenant_count || 0,
      income: user.employments.reduce(
        (a, v) => a + v.salary * (v.is_past === 0),
        0
      ),
      name: user.first_name + " " + user.last_name,
      pets: user.households.reduce(
        (a, v) => [...a, ...(JSON.parse(v.pets) || [])],
        []
      ),
      smoke: user.households[0]?.smoke,
      profilePercent: selectedApplication.status === "in-review" ? 100 : 50,
      top: false,
      disabled: false,
      is_favourite: selectedApplication.is_favourite,
      applicant_id: selectedApplication.id,
      isTopTenant: Boolean(topApplicants.find((id) => id === user_id)),
    }
    setSelectedUser({ user, data, numberToCurrency, coapplicants, user_id })
  }

  useEffect(() => {
    if (applicants.length > 0) {
      selectApplicationForView(applicants[0].user_id)
    }
  }, [showFirstUser])

  useEffect(() => {
    if (shouldAddressUpdate) { // handle updating property address
      if (!updateMode) {
        handleUpdateNewAddress()
      }
    }
  }, [updateMode])


  const getTotalTenantCount = (applicant) => {
    return parseInt(applicant.user.households[0]?.tenant_count) || 0
  }

  const handleOccupancyRange = (baseList) => {
    const { min, max } = filterConfig.householdRange.curr
    return baseList.filter((applicant) => getTotalTenantCount(applicant) >= min && getTotalTenantCount(applicant) <= max)
  }

  const getSmoke = (applicant) => {
    let smoke = applicant.user.households[0]?.smoke || 0
    if (smoke === 0) { // Not a smoker
      return smoke
    } else {
      return 1
    }
  }

  const applySmokeFilter = (baseList) => {
    return baseList.filter(applicant => getSmoke(applicant) === 0)
  }

  const getPets = (applicant) => {
    const pets = applicant.user.households[0]?.pets || null
    // Handle any cases where the pets' field is not specified
    // this array string is okay because pets comes back as an unparsed JSON string
    if (pets === "[]" || pets === null || pets === undefined) {
      return 0
    } else { // The user has pets
      return 1
    }
  }

  const handlePets = (baseList) => {
    return baseList.filter(applicant => getPets(applicant) === 0)
  }

  const getBackgroundCheck = (applicant) => {
    const { background_checks } = applicant
    if (background_checks.length === 0) {
      return 0
    } else {
      return 1
    }
  }

  const handleBackgroundChecks = (baseList) => {
    return baseList.filter(applicant => getBackgroundCheck(applicant) === 1)
  }

  const getIsFavourite = (applicant) => {
    return applicant.is_favourite === 1
  }

  const handleFavourites = (baseList) => {
    return baseList.filter(applicant => getIsFavourite(applicant) === true)
  }


  const applySalaryRange = (baseList) => {
    const { min, max } = filterConfig.salaryRange.curr
    return baseList.filter((applicant) => {
      let salary = applicant.user.employments[0]?.salary || 0
      if (salary < 0) salary = 0

      return salary >= min && salary <= max
    })
  }

  const handleIdVerifications = (baseList) => {
      return baseList.filter((applicant) => applicant.id_verifications.length > 0)
  }

  const applyCreditRange = (baseList) => {
    const { min, max } = filterConfig.creditRange.curr
    return baseList.filter((applicant) => {
      let credit = applicant.credit_checks[0]?.credit_score || 0
      if (credit < 0) credit = 0

      return credit >= min && credit <= max
    })
  }

  const handleFilterByCreditChecks = (baseList) => {
    return baseList.filter(applicant => applicant.credit_checks[0]?.credit_score)
  }

  const handleBankChecks = (baseList) => {
    return baseList.filter((applicant) => applicant.employment_income_verifications.length > 0)
  }

  const applyAllFilters = () => {
    if (filterConfig.filterApplied) {
      let filteredList = handleOccupancyRange(baseList)
      filteredList = applySalaryRange(filteredList)
      filteredList = applyCreditRange(filteredList)

      if (!filterConfig.allowSmoke ) {
        filteredList = applySmokeFilter(filteredList)
      }

      if (filterConfig.pets) {
        filteredList = handlePets(filteredList)
      }

      if (filterConfig.idVerification) {
        filteredList = handleIdVerifications(filteredList)
      }

      if (filterConfig.bankCheck) {
        filteredList = handleBankChecks(filteredList)
      }

      if (filterConfig.backgroundCheck) {
        filteredList = handleBackgroundChecks(filteredList)
      }

      if (filterConfig.filterByCreditCheck) {
        filteredList = handleFilterByCreditChecks(filteredList)
      }

      if (filterConfig.filterByFavs) {
        filteredList = handleFavourites(filteredList)
      }

      if (filteredList.length === 0) {
        setApplicantsErr(true)
        throw new Error("No applicants match your filter criteria")

      } else {
        setApplicants(() => filteredList)
        forceUpdate()
        setApplicantsErr(false)
      }
    }
  }

  useEffect(() => {
    try {
      applyAllFilters()
    } catch (error) {
      // console.log("Error: ", error.message)
      setShowFirstUser(false)
    }
  } , [filterConfig])


  useEffect(() => {

    const findApplicantInList = (text) => {
      // Find all applicants with either the first name or last name that matches part of or all of the string in order
      return applicants.filter((applicant) => {
          const { user } = applicant
          const { first_name, last_name } = user
          const firstNameMatch = first_name.toLowerCase().includes(text.toLowerCase())
          const lastNameMatch = last_name.toLowerCase().includes(text.toLowerCase())
          return firstNameMatch || lastNameMatch
        }
      )
    }

    const foundApplicants = findApplicantInList(searchText)
    if (searchText !== "") {
      if (!foundApplicants.length) {
        setApplicantsErr(true)
        setApplicants([])
        setShowFirstUser(false)
      } else if (foundApplicants.length !== 0) {
        setApplicantsErr(false)
        setApplicants([...foundApplicants])
      }
    } else if (searchText === "") {
      setApplicants(baseList)
      setApplicantsErr(false)
    }

  } , [searchText])

  const updateFavStatus = (applicantId) => {

    const newApplicants = baseList.map((applicant) => {
      if (applicant.id === applicantId) {
        if (applicant.is_favourite === 1) {
          applicant.is_favourite = 0
        } else {
          applicant.is_favourite = 1
        }
      }
      return applicant
    })

    // Set the base list of applicants, then force the browser to do a refresh
    // This works for updating the applicant list when a favourite is changed in ApplicantInfo
    setBaseList(newApplicants)
    forceUpdate()
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Smart Screening - View Applications | Rent Panda
        </title>
        <meta
          name="description"
          content="View tenant applications for your property."
        />
      </Helmet>
      <a id="top"></a>
      <section className="ApplicationHeader">
        <div className="container">
          <button
            className="btn btn--back"
            onClick={() => navigate(-1)}
          >
            back
          </button>
          <div className="title-container">
            {updateMode ? (
              <div className="form-group">
                <label htmlFor="address_autocomplete">
                  update address
                </label>

                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={handleSelect}
                  className="form-group__form-control"
                  id="address_autocomplete"
                  searchOptions={searchOptions}
                >
                  {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                    <div key={suggestions.placeId}>
                      <textarea
                        {...getInputProps({
                          placeholder:
                            `${data.property.address}` ||
                            "Search Places ...",
                          className: "address-field-google",
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item"
                          const style = suggestion.active
                            ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                            }
                            : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                            }
                          return (
                            <div
                              key={suggestion.placeId}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>

                {error && (
                  <span className="errorMessage">
                    {" "}
                    You must enter an address
                  </span>
                )}
              </div>
            ) : (
              <h1>{address}</h1>
            )}

            {updateMode ? (
              <>
                <button
                  className="btn btn--secondary-alt btn--small btn--shrink"
                >
                  submit
                </button>
              </>
            ) : (
              <>
                <button
                  className="btn btn--edit"
                  onClick={() => setUpdateMode(true)}
                ></button>
              </>
            )}
          </div>
          <button
            className="btn btn--primary applicationsBtn"
            onClick={() => {
              navigate("/landlord/application-invite")
            }}
          >
            invite more applicants
          </button>
        </div>
      </section>
      <section className="Application">
        <div className="container">
          <div className="Application__search-filters mb-3 row">
            <div className="col-lg-4 searchApplicantInput">
              <TextInput
                type="text"
                placeholder="search applicants"
                name="search_applicants"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
            </div>
            <div>
              <div className="Application__filters">
                <button className={clsx("btn btn--filter", filterConfig.filterByFavs && "isActive")}
                        onClick={() => {
                          setFilterConfig((prevState) => ({
                            ...prevState,
                            filterApplied: true,
                            filterByFavs: !prevState.filterByFavs
                          }))
                        }}
                        // onClick={() => {
                        //   setFilterConfig((prevState) => ({
                        //       ...prevState,
                        //       filterApplied: true,
                        //     bankCheck: !prevState.bankCheck,
                        //   }))
                        // }}
                >
                  {/*income & expense verified*/}
                  favourites
                </button>
                <button className={clsx("btn btn--filter", filterConfig.pets && "isActive")}
                        onClick={() => {
                          setFilterConfig((prevState) => ({
                            ...prevState,
                            filterApplied: true,
                            pets: !prevState.pets,
                          }))
                        }}
                >no pets
                </button>
                <button className={clsx("btn btn--filter", filterConfig.filterByCreditCheck && "isActive")}
                        onClick={() => {
                          setFilterConfig((prevState) => ({
                            ...prevState,
                            filterApplied: true,
                            filterByCreditCheck: !prevState.filterByCreditCheck,
                          }))
                        }}
                >
                  credit check
                </button>
                <button className={clsx("btn btn--filter", !filterConfig.allowSmoke && "isActive")}
                        onClick={() => {
                          setFilterConfig((prevState) => ({
                            ...prevState,
                            filterApplied: true,
                            allowSmoke: !prevState.allowSmoke,
                          }))
                        }}
                >
                  non-smoker
                </button>
                <button
                  className="btn btn--moreFilters"
                  onClick={() => setOpenModal(true)}
                >
                  more filters
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Applicants */}
            <aside className="col-lg-5">
              {( applicantsErr === true ? (
                    <>
                      <h2 className="text-danger">
                        No applicants match your filter criteria. &nbsp;
                        {""}
                        <span className={"resetFilterBtn"}
                              onClick={() => {
                                setApplicants([...baseList])
                                resetFilter(data)
                              }}
                        >
                          reset filter
                        </span>
                      </h2>
                      <h2>showing 0 applicants</h2>
                    </>
                  ) :
                  <>
                    <h2>showing {applicants.length} applicants</h2>
                    <div className="title-container">
                      <button
                        className=" btn btn--question"
                        onClick={() => {
                          setOpenHowWeRankModal(true)
                        }}
                      >
                        how we rank applicants?
                      </button>
                    </div>
                  </>
              )}


              {/*  Applicant  */}
              { (applicantsErr === false) && applicants.map((applicant, index) => {
                const {
                  credit_checks,
                  background_checks,
                  user,
                  id,
                  employment_income_verifications,
                  id_verifications,
                  is_favourite,
                  isCoapplicant,
                  coapplicants
                } = applicant
                const data = {
                  credit_checks,
                  background_checks,
                  employment_income_verifications,
                  id_verifications,
                  houseSize: user.households[0]?.tenant_count,
                  income: user.employments?.reduce(
                    (a, v) => a + v.salary * (v.is_past === 0),
                    0
                  ),
                  name: user.first_name + " " + user.last_name,
                  pets: user.households?.reduce(
                    (a, v) => [...a, ...(JSON.parse(v.pets) || [])],
                    []
                  ),
                  smoke: user.households[0]?.smoke || 0,
                  profilePercent:
                    applicant.status === "in-review" ? 100 : 50,
                  disabled: false,
                  top: Boolean(topApplicants.find((id) => id === applicant.user_id)),
                }

                return (
                  <ApplicantCard
                    key={applicant.id}
                    is_favourite={applicant.is_favourite}
                    firstHighlighted={(highlightFirstUser === true && index === 0)}
                    top={data.top}
                    onClick={() => {
                      selectApplicationForView(applicant.user_id)
                      if (highlightFirstUser) {
                        setHighlightFirstUser(false)
                      }
                      if (window.innerWidth > 1000) {
                        window.scroll({
                          top: 500,
                          left: 0,
                          behavior: "smooth",
                        })
                      }
                    }}
                    {...data}
                  ></ApplicantCard>
                )
              })}
            </aside>

            {/* Applicant Info */}
            {selectedUser && (
              <main className="col-lg-7">
                <div className="applicant-info-container">
                  <UserInfo {...selectedUser}
                            currentUserApplication={selectApplicationForView}
                            previousUser={previousUser}
                            setPreviousUser={setPreviousUser}
                            toggleFavStatus={updateFavStatus}
                  />

                </div>
              </main>
            )}
          </div>
        </div>
      </section>
      {openHowWeRankModal && (
        <HowWeRankModal closeModal={setOpenHowWeRankModal} />
      )}

      {openModal && (
        <FilterModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          filterConfig={filterConfig}
          setFilterConfig={setFilterConfig}
          applyFilters={applyAllFilters}
        />
      )}
    </div>
  )
}

export default Application