import "./applicationPreview.scss"
import ProgressBar from "../../../Components/ProgressBar/ProgressBar"
import HousingInfo from "../../../Components/ApplicationPreview/HousingInfo/HousingInfo"
import EmploymentInfo from "../../../Components/ApplicationPreview/EmploymentInfo/EmploymentInfo"
import HouseholdInfo from "../../../Components/ApplicationPreview/HouseholdInfo/HouseholdInfo"
import Guarantor from "../../../Components/ApplicationPreview/Guarantor/Guarantor"
import PremiumLocked from "../../../Components/ApplicationPreview/PremiumLocked/PremiumLocked"
import CreditCheck from "../../../Components/ApplicationPreview/CreditCheck/CreditCheck"
import BackgroundCheck from "../../../Components/ApplicationPreview/BackgroundCheck/BackgroundCheck"
import IDVerification from "../../../Components/ApplicationPreview/IDVerification/IDVerification"
import PersonalizedVideo from "../../../Components/ApplicationPreview/PersonalizedVideo/PersonalizedVideo"
import VerifiedLandlordReference from "../../../Components/ApplicationPreview/VerifiedLandlordReference/VerifiedLandlordReference"
import BankCheck from "../../../Components/ApplicationPreview/BankCheck/BankCheck"
import { useEffect, useState } from "react"
import ConfirmModal from "./ConfirmModal/ConfirmModal"
import CoApplicants from "../../../Components/ApplicationPreview/CoApplicants/CoApplicants"
import axios from "axios"
import Cookies from "universal-cookie"
import { useNavigate } from "react-router-dom"
import config from "../../../config/config"
import { usePremiumFlow } from "Context/flowPremiumContext/FlowPremiumContext"


function ApplicationPreview() {
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const navigate = useNavigate()
  const [showData, setShowData] = useState(true)
  const [{ applicant }, setData] = useState({})
  const { hasPremiumFeature, premiumFeatureComplete, premiumFeatureProcessed } =
    usePremiumFlow()
    
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want to leave this page? Your application may have not been submitted yet!";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);  
    
  useEffect(() => {
    const cookie = new Cookies()
    axios
      .get(
        `${config.BASE_URL}api/applicant/${localStorage.getItem(
          "currentApplicantId"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${cookie.get("auth_token")}`,
          },
        }
      )
      .then((res) => {
        setData(res.data)
      })
  }, [])

  const premiumFeatures = [
    {
      id: "credit_check",
      name: "credit check",
      location: "/tenant/credit-report",
    },
    {
      id: "background_check",
      name: "background check",
      location: "/tenant/background-check",
    },
    {
      id: "reference_check",
      name: "reference check",
      location: "/tenant/landlord-references",
    },
  ]

  const financialVerification = {
    id: "financial_verification",
    name: "financial verification",
    location: "/tenant/employment-income-verification",
  }
  const idVerification = {
    id: "id_verification",
    name: "id verification",
    location: "/tenant/employment-income-verification",
  }

  const yieldSpecificFeature = (feature) => {
    switch (feature.id) {
      case "background_check":
        return (
          applicant?.background_checks?.map((background_check) => {
            return (
              <BackgroundCheck
                type="tenant"
                data={JSON.parse(background_check.certn_data)}
                key={background_check.id}
              />
            )}))
      case "credit_check":
        return (
          applicant?.credit_checks?.map((credit_check) => {
            return (
              <CreditCheck
                type="tenant"
                data={credit_check}
                key={credit_check.id}
              />
            )
          })
        )
      case "reference_check":
        return (
          <>
          </>
        )
      default:
        return (
          <> </>
        )
    }
  }


// This needs to be split into two
  const RenderFinancialVerification = ({financialVerification}) => {
    const hasFinancialVerification = hasPremiumFeature(financialVerification.id)
    const financialVerificationComplete = premiumFeatureComplete(financialVerification.id)
    const financialVerificationProcessed = premiumFeatureProcessed(financialVerification.id)

    if (!hasFinancialVerification) {
      return (
        <>
        </>
      )
    }
    if (financialVerificationComplete) {
      return (
          < >
            {applicant?.employment_income_verifications?.map(
              (verification) => {
                return (
                  <BankCheck
                    display={verification}
                    key={verification.id}
                  />
                )
              })}
          </>
      )
    } else if (financialVerificationProcessed) {
      return (
        <div className="premium-feature">
          <div className="info-title-container">
            <div className="info-title">
              <div className={"info__label processingHeading"}>
               processing
              </div>
              <h2>
                {hasFinancialVerification && (
                  <span>automated employment / income verification</span>
                )}
              </h2>
            </div>
          </div>
          <p className={"processingText"}>
            The information is currently processing. It may take a few minutes to come through. Please submit your application and check back shortly to see your results.
          </p>
        </div>
      )
    } else {
      return (
        <div className="premium-feature">
          <div className="info-title-container">
            <div className="info-title">
              <div className={"info__label info__label--incomplete"}>
                incomplete
              </div>
              <h2>
                {hasFinancialVerification === true && (
                  <span>automated employment / income verification</span>
                )}
              </h2>
            </div>
            <div className="info-title-controls">
              <button
                className="btn btn--primary btn--small"
                onClick={() => {
                  navigate(
                    "/tenant/employment-income-verification"
                  )}}
              >
                start verification
              </button>
            </div>
          </div>
        </div>
      )
    }
  }

  const RenderIdVerification = ({idVerification}) => {
    const hasIdVerification = hasPremiumFeature(idVerification.id)
    const idVerificationProcessed = premiumFeatureProcessed(idVerification.id)
    const idVerificationComplete = premiumFeatureComplete(idVerification.id)

    if (!hasIdVerification) {
      return (
        <>
        </>
      )
    }
    if (idVerificationComplete) {
      return (
        <>
          {applicant?.id_verifications?.map((id_verification) => {
            return (
              <IDVerification
                display={id_verification}
                key={id_verification.id}
              />
            )
          })}
        </>
      )
    } else if (idVerificationProcessed) {
      return (
        <div className="premium-feature">
          <div className="info-title-container">
            <div className="info-title">
              <div className={"info__label processingHeading"}>
                processing
              </div>
              <h2>
                { hasIdVerification && (
                  <span>id verification</span>
                )}
              </h2>
            </div>
          </div>
          <p className={"processingText"}>
            The information is currently processing. It may take a few minutes to come through. Please submit your application and check back shortly to see your results.
          </p>
        </div>
      )
    } else {
        return (
          <div className="premium-feature">
            <div className="info-title-container">
              <div className="info-title">
                <div className={"info__label info__label--incomplete"}>
                  incomplete
                </div>
                <h2>
                  {hasIdVerification && (
                    <span>id verification</span>
                  )}
                </h2>
              </div>
              <div className="info-title-controls">
                <button
                  className="btn btn--primary btn--small"
                  onClick={() => {
                    navigate(
                      "/tenant/employment-income-verification"
                    )}}
                >
                  start verification
                </button>
              </div>
            </div>
          </div>
        )
    }
  }

  const RenderFeatures = ({feature}) => {
    const completed = premiumFeatureComplete(feature.id)
    const processed = premiumFeatureProcessed(feature.id)

    if (completed) {
      return (
        <div>
          {yieldSpecificFeature(feature)}
        </div>
      )
    } else if (processed) {
      return (
        <div className="premium-feature" key={feature.id}>
          <div className="info-title-container">
            <div className="info-title">
              <div
                className={"info__label processingHeading"}
              >
                processing
              </div>
              <h2>{feature.name}</h2>
            </div>
          </div>
          <p className={"processingText"}>
            The information is currently processing. It may take a few minutes to come through. Please submit your application and check back shortly to see your results.
          </p>
        </div>
      )
    } else {
      // Case where feature is not complete, and not processed
      return (
        <div className="premium-feature" key={feature.id}>
          <div className="info-title-container">
            <div className="info-title">
              <div
                className={"info__label info__label--incomplete"}
              >
                incomplete
              </div>
              <h2>{feature.name}</h2>
            </div>
            <div className="info-title-controls">
              <button
                className="btn btn--primary btn--small"
                onClick={() => {
                  navigate(feature.location)
                }}
              >
                start {feature.name}
              </button>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <>

    <section className="ApplicationPreview">
      <div className="container">
        <button
          className="btn btn--back"
          onClick={() => {
            navigate(-1)
          }}
        >
          back
        </button>
        <ProgressBar type="tenant" step="8" />
        <div className="row">
          <div className="col-lg-8">
            <h1 className="h2">
              Please review your application for unit-
              {applicant?.application?.unit}{" "}
              {applicant?.application?.address} and submit to the
              landlord.
            </h1>
          </div>
        </div>

        <div className="ApplicationPreview__container">
          {applicant?.housings?.map((house) => {
            return (
              <HousingInfo
                display={house}
                key={house.id}
                type={house.type}
                editable="true"
                showData={showData}
                setShowData={setShowData}
              />
            )
          })}
          {applicant?.employments?.map((employment) => {
            return (
              <EmploymentInfo
                display={employment}
                key={employment.id}
              />
            )
          })}
          {applicant?.households?.map((household) => {
            return (
              <HouseholdInfo display={household} key={household.id} />
            )
          })}

          {/*
          <Guarantor
            editable="true"
            showData={showData}
            setShowData={setShowData}
          />

          <PremiumLocked
            title="some title"
            description="some description"
            showData={showData}
            setShowData={setShowData}
          />
		      */}

          {premiumFeatures
            .filter((feature) => hasPremiumFeature(feature.id))
            .map((feature) => <RenderFeatures feature={feature} key={feature.id} />)
          }

          {/* Separate render for these two as they are conditional on each other */}
          <RenderFinancialVerification financialVerification={financialVerification} />

          <RenderIdVerification idVerification={idVerification} />

          {/* <PersonalizedVideo
            editable="true"
            showData={showData}
            setShowData={setShowData}
          /> */}

          {/*
          <VerifiedLandlordReference
            editable="true"
            showData={showData}
            setShowData={setShowData}
          />
          */}
        </div>

        <div className="fixedButtonCont">
          <div className="btn-group">
            <button
              className="btn btn--primary floatingBtn"
              onClick={() => {
                setOpenConfirmModal(true)
              }}
            >
              submit
            </button>
          </div>
        </div>

      </div>

    </section>

      {openConfirmModal && (
        <ConfirmModal
          closeModal={setOpenConfirmModal}
          address={applicant?.application?.address}
        />
      )}

    </>
  )
}

export default ApplicationPreview
