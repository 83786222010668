import "./tenantPremiumFeatures.scss"
import ProgressBar from "../../../Components/ProgressBar/ProgressBar"
import { useNavigate } from "react-router-dom"
import { usePremiumFlow } from "Context/flowPremiumContext/FlowPremiumContext"


const premiumFeatures = [
  {
    id: "credit_check",
    name: "credit check",
    location: "/tenant/credit-report",
  },
  {
    id: "background_check",
    name: "background check",
    location: "/tenant/background-check",
  },
  {
    id: "reference_check",
    name: "reference check",
    location: "/tenant/landlord-references",
  }
]

const financialVerification = {
  id: "financial_verification",
  name: "financial verification",
  location: "/tenant/employment-income-verification",
}
const idVerification = {
  id: "id_verification",
  name: "id verification",
  location: "/tenant/employment-income-verification",
}

function TenantPremiumFeatures() {
  const navigate = useNavigate()
  const { hasPremiumFeature, premiumFeatureComplete, premiumFeatureProcessed } =
    usePremiumFlow()

  const FinancialVerification = ({financialVerification}) => {
    const hasFinancialVerification = hasPremiumFeature(financialVerification.id)
    const financialVerificationComplete = premiumFeatureComplete(financialVerification.id)
    const financialVerificationProcessed = premiumFeatureProcessed(financialVerification.id)

    if (!hasFinancialVerification) {
      return (
        <>
        </>
      )
    } else if (financialVerificationComplete) {
      return (
        <div className="premium-feature">
          <div className="info-title-container">
            <div className="info-title">
              <div className={"info__label info__label--completed"}>
                complete
              </div>
              <h2>
                {hasFinancialVerification && (
                  <span>automated employment / income verification</span>
                )}
              </h2>
            </div>
          </div>
        </div>
      )
    } else if (financialVerificationProcessed) {
      return (
        <div className="premium-feature">
          <div className="info-title-container">
            <div className="info-title">
              <div className={"info__label processingHeading"}>
               processing
              </div>
              <h2>
                {hasFinancialVerification && (
                  <span>automated employment / income verification</span>
                )}
              </h2>
            </div>
          </div>
          <p className={"processingText"}>
            The information is currently processing. It may take a few minutes to come through. Please submit your application and check back shortly to see your results.
          </p>
        </div>
      )
    } else {
      return (
        <div className="premium-feature">
          <div className="info-title-container">
            <div className="info-title">
              <div className={"info__label info__label--incomplete"}>
               incomplete
              </div>
              <h2>
                {hasFinancialVerification === true && (
                  <span>automated employment / income verification</span>
                )}
              </h2>
            </div>
            <div className="info-title-controls">
              <button
                className="btn btn--primary btn--small"
                onClick={() => {
                  navigate(
                    "/tenant/employment-income-verification"
                  )}}
              >
                start verification
              </button>
            </div>
          </div>
        </div>
      )
    }
  }

  const RenderIdVerification = ({idVerification}) => {
    const hasIdVerification = hasPremiumFeature(idVerification.id)
    const idVerificationComplete = premiumFeatureComplete(idVerification.id)
    const idVerificationProcessed = premiumFeatureProcessed(idVerification.id)

    if (!hasIdVerification) {
      return (
        <>
        </>
      )
    } else if (idVerificationComplete) {
      return (
        <div className="premium-feature">
          <div className="info-title-container">
            <div className="info-title">
              <div className={"info__label info__label--completed"}>
                complete
              </div>
              <h2>
                {hasIdVerification && (
                  <span>id verification</span>
                )}
              </h2>
            </div>
          </div>
        </div>
      )
    } else if (idVerificationProcessed) {
      return (
        <div className="premium-feature">
          <div className="info-title-container">
            <div className="info-title">
              <div className={"info__label processingHeading"}>
                processing
              </div>
              <h2>
                {hasIdVerification && (
                  <span>id verification</span>
                )}
              </h2>
            </div>
          </div>
          <p className={"processingText"}>
            The information is currently processing. It may take a few minutes to come through. Please submit your application and check back shortly to see your results.
          </p>
        </div>
      )
    } else {
      return (
        <div className="premium-feature">
          <div className="info-title-container">
            <div className="info-title">
              <div className={"info__label info__label--incomplete"}>
                incomplete
              </div>
              <h2>
                {hasIdVerification && (<span>id verification</span>)}
              </h2>
            </div>
            <div className="info-title-controls">
              <button
                className="btn btn--primary btn--small"
                onClick={() => {
                  navigate(
                    "/tenant/employment-income-verification"
                  )}}
              >
                start verification
              </button>
            </div>
          </div>
        </div>
      )
    }
  }

  const RenderFeatures = ({feature}) => {
    const completed = premiumFeatureComplete(feature.id)
    const tenantHasSubmitted = premiumFeatureProcessed(feature.id)

    if (completed) {
      return (
        <div className="premium-feature" key={feature.id}>
          <div className="info-title-container">
            <div className="info-title">
              <div
                className={"info__label info__label--completed"}
              >
                complete
              </div>
              <h2>{feature.name}</h2>
            </div>
          </div>
        </div>
      )
    } else if (tenantHasSubmitted) {
      return (
        <div className="premium-feature" key={feature.id}>
          <div className="info-title-container">
            <div className="info-title">
              <div
                className={"info__label processingHeading"}
              >
                processing
              </div>
              <h2>{feature.name}</h2>
            </div>
          </div>
          <p className={"processingText"}>
            The information is currently processing. It may take a few minutes to come through. Please submit your application and check back shortly to see your results.
          </p>
        </div>
      )
    } else {
      // Case where feature is not complete, and not processed
      return (
        <div className="premium-feature" key={feature.id}>
          <div className="info-title-container">
            <div className="info-title">
              <div
                className={"info__label info__label--incomplete"}
              >
                incomplete
              </div>
              <h2>{feature.name}</h2>
            </div>
              <div className="info-title-controls">
                <button
                  className="btn btn--primary btn--small"
                  onClick={() => {
                    navigate(feature.location)
                  }}
                >
                  start {feature.name}
                </button>
              </div>
          </div>
        </div>
      )
    }
  }


  return (
    <section className="TenantPremiumFeatures">
      <div className="container">
        <button
          className="btn btn--back"
          onClick={() => navigate(-1)}
        >
          back
        </button>
        <ProgressBar type="tenant" step="7" />
        <h1>premium features</h1>
        <div className="TenantPremiumFeatures__container">

          {premiumFeatures
            .filter((feature) => hasPremiumFeature(feature.id))
            .map((feature) => <RenderFeatures feature={feature} key={feature.id} />)
          }

          <RenderIdVerification idVerification={idVerification} />

          <FinancialVerification financialVerification={financialVerification} />

        </div>
        <div className="btn-group mt-3">
          <button
            className="btn btn--primary"
            onClick={() => {
              navigate("/tenant/application-preview")
            }}
          >
            next
          </button>
        </div>
      </div>
    </section>
  )
}

export default TenantPremiumFeatures
