import "./LLPaid.scss"
import ProgressBar from "../../../../Components/ProgressBar/ProgressBar"
import { useState } from "react"
import BackgroundCheckModal from "../../PremiumFeatures/BackgroundCheck/BackgroundCheckModal/BackgroundCheckModal"
import LLPaidRejectModal from "./LLPaidRejectModal/LLPaidRejectModal"
import { useNavigate } from "react-router-dom"
import { usePremiumFlow } from "Context/flowPremiumContext/FlowPremiumContext"
import { Link } from "react-router-dom";

function LLPaid({ hideProgress }) {
  const [openModal, setOpenModal] = useState(false)
  const navigate = useNavigate()
  const premiumFeatures = usePremiumFlow()
  
  

  return (
    <section className="LLPaid">
      <div className="container">
        {!hideProgress && (
          <ProgressBar ProgressBar type="tenant" step="7" />
        )}

        <h1>
          the landlord would like some more info from you to complete
          the application.
        </h1>
        <p className="mb-3">
          The landlord has purchased the following items to add to
          your application. This information is kept secure and only
          shared with the landlord who has requested it. Would you
          like to consent and move forward?{" "}
        </p>
        <div className="row">
          {premiumFeatures.hasPremiumFeature("credit_check") && (
            <div className="col-md-6">
              <div className="LLPaid__feature-wrapper">
                <div className="LLPaid__feature">
                  <span className="circle-check--small"></span>
                  <div>
                    <h4>credit check</h4>
                    <p>
                      industry standard credit checks are a secure and
                      trusted way to back up your application with
                      financial info.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {premiumFeatures.hasPremiumFeature("id_verification") && (
            <div className="col-md-6">
              <div className="LLPaid__feature-wrapper">
                <div className="LLPaid__feature">
                  <span className="circle-check--small"></span>
                  <div>
                    <h4>id verification</h4>
                    <p>
                      let the landlord know you are a real person, so
                      they aren't worried about any scams.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {premiumFeatures.hasPremiumFeature("background_check") && (
            <div className="col-md-6">
              <div className="LLPaid__feature-wrapper">
                <div className="LLPaid__feature">
                  <span className="circle-check--small"></span>
                  <div>
                    <h4>background check</h4>
                    <p>
                      get an instant background check, so you and the
                      landlord dont have to go searching.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {premiumFeatures.hasPremiumFeature("reference_check") && (
            <div className="col-md-6">
              <div className="LLPaid__feature-wrapper">
                <div className="LLPaid__feature">
                  <span className="circle-check--small"></span>
                  <div>
                    <h4>verified landlord references</h4>
                    <p>
                      get your old landlords to leave you a reference,
                      so no one has to bother with old school calls.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* 
          <div className="col-md-6">
            <div className="LLPaid__feature-wrapper">
              <div className="LLPaid__feature">
                <span className="circle-check--small"></span>
                <div>
                  <h4>personalized video</h4>
                  <p>
                    introduce yourself to your potential landlord, so
                    they can get to know you quickly and easily.
                  </p>
                </div>
              </div>
            </div>
          </div>*/}
          {premiumFeatures.hasPremiumFeature(
            "financial_verification"
          ) && (
            <div className="col-md-6">
              <div className="LLPaid__feature-wrapper">
                <div className="LLPaid__feature">
                  <span className="circle-check--small"></span>
                  <div>
                    <h4>income & expenses verification</h4>
                    <p>
                      securely connect with your bank, to verify your
                      income and rent payments so you dont have to
                      pull paystubs or letters of employment anymore.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="btn-group mt-3">
          <button
            className="btn btn--back"
            onClick={() => {
              navigate(-1)
            }}
          >
            back
          </button>
          {!hideProgress && (
            <button
              className="btn btn--secondary-alt"
              onClick={() => {
                setOpenModal(true)
              }}
            >
              reject
            </button>
          )}
          <Link
            to="/tenant/premium-features"
            className="btn btn--primary llpaid-continue-button">
          >
            continue
          </Link>
        </div>
      </div>
      {openModal && (
        <LLPaidRejectModal
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      )}
    </section>
  )
}

export default LLPaid
