import "./applicationInvite.scss"

import ProgressBar from "../../../Components/ProgressBar/ProgressBar"

import CongratulationsModal from "Pages/Payment/CongratulationsModal/CongratulationsModal"
import { useNavigate } from "react-router-dom"
import EmailList from "../../../Components/EmailList/EmailList"
import NumberList from "../../../Components/MobileNumberList/NumberList"
import axios from "axios"
import { v4 as uuidv4 } from "uuid"
import { useState, useRef, useEffect } from "react"
import Cookies from "universal-cookie"
import config from "../../../config/config"
import HandleLoginRedirect from "../../../API/HandleLoginRedirect"
import Helmet from "react-helmet"

function ApplicationInvite(props) {
  const [openModal, setOpenModal] = useState(false)
  const [property, setProperty] = useState(false)
  const [payload, setPayload] = useState({ emails: [], numbers: [] })

  const payloadHandler = (dataProperty, data) => {
    if (dataProperty === "emails") {
      setPayload((prevState) => ({
        emails: data,
        numbers: prevState.numbers,
      }))
    } else if (dataProperty === "nums") {
      setPayload((prevState) => ({
        numbers: data,
        emails: prevState.emails,
      }))
    }
  }

  useEffect(() => {
    const cookies = new Cookies()
    const currentPropertyId = cookies.get(
      "current_property_application_id"
    )
    const apiConfig = {
      headers: {
        Authorization: "Bearer " + cookies.get("auth_token"),
      },
    }

    const res = axios
      .get(
        `${config.BASE_URL}api/property/` + currentPropertyId,
        apiConfig
      )
      .then(function (response) {
        // @todo
        // alert(response.data.message)
        if (HandleLoginRedirect(navigate, response) === false) {
          // Results are in: response.data.data
          if (typeof response.data.property !== "undefined") {
            setProperty(response.data.property)
          }
        }
      })
      .catch(function (error) {
        let response = error.response

        if (HandleLoginRedirect(navigate, response) === false) {
          // Results are in: response.data.data
        }
      })
  }, [props])

  useEffect(() => {
    const cookies = new Cookies()
    const currentPropertyId = cookies.get(
      "current_property_application_id"
    )

    const apiConfig = {
      headers: {
        Authorization: "Bearer " + cookies.get("auth_token"),
      },
    }
    const myUrl =
      `${config.BASE_URL}api/application/` +
      currentPropertyId +
      `/send-link`

    if (openModal) {
      const response = axios
        .post(
          myUrl,
          {
            emails: payload.emails,
            phone_numbers: payload.numbers,
          },
          apiConfig
        )
        .catch(function (error) {
          let response = error.response
          console.log("Error: ", response)
        })

      response.then(() => {
        // console.log("Data submitted.")
      })
    }
  }, [openModal])

  const navigate = useNavigate()
  return (
    <section className="ApplicationInvite">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Smart Screening - Invite Applicants | Rent Panda
        </title>
        <meta
          name="description"
          content="Invite tenants to fill out an application to your property. Share via email, text or a direct link."
        />
      </Helmet>
      <div className="container">
        <button
          className="btn btn--back"
          onClick={() => {
            navigate("/landlord/customize-application")
          }}
        >
          back
        </button>
        <ProgressBar type="landlord" step="5" />
        <h1>send link to applicants</h1>
        <p>
          Invite applicants to apply to your rental by giving them the
          link, typing in their email or sending it by text. Multiple
          applicants can apply using a single link and you'll be able
          to review all the applications as they are completed.
        </p>

        <div className="row row--boxed mb-2 mt-2">
          <div className="col-lg-6">
            <h2>share a link</h2>
            <p className="mb-0">
              <span>
                <u>
                  https://screening.rentpanda.ca/apply/
                  {property.token}
                </u>
                <button className="btn btn--secondary-alt btn--small copyPastaBtn"
                        onClick={() => {
                          navigator.clipboard.writeText(`https://screening.rentpanda.ca/apply/${property.token}`)
                        }}
                >
                  copy link
                </button>
              </span>
            </p>
          </div>
        </div>

        <div className="row row--boxed mb-2">
          <div className="col-lg-6">
            <h2>share by email</h2>
            <div className="inline-input-group">
              <EmailList props={payloadHandler} />
            </div>
          </div>
        </div>

        <div className="row row--boxed mb-2">
          <div className="col-lg-6">
            <h2>share by text</h2>
            <div className="inline-input-group">
              <NumberList props={payloadHandler} />
            </div>
          </div>
        </div>

        <div className="btn-group">
          <button
            className="btn btn--primary"
            onClick={() => {
              setOpenModal(true)
            }}
          >
            next
          </button>
        </div>

        {openModal && (
          <CongratulationsModal
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        )}
      </div>
    </section>
  )
}

export default ApplicationInvite
