import "./screenApplicants.scss"
import ProgressBar from "../../../Components/ProgressBar/ProgressBar"
import { useEffect, useState } from "react"
import PremiumModal from "./PremiumModal/PremiumModal"
import BasicModal from "./BasicModal/BasicModal"
import ApplicantForm from "./ApplicantForm/ApplicantForm"
import { useNavigate } from "react-router-dom"
import { CartState } from "Context/cartContext/CartContext"
import { useEffectOnce } from "Hooks/useEffectOnce"
function getQueryParam(name, defaultValue) {
  const search = new URLSearchParams(window.location.search)

  return search.has(name)
    ? JSON.parse(atob(search.get(name)))
    : defaultValue
}
function ScreenApplicants() {
  // ROUTER

  const navigate = useNavigate()

  // CONTEXT

  const {
    state: { cart, premiumFeatures },
    dispatch,
  } = CartState()

  // STATE

  const [addMoreHidden, setAddMoreHidden] = useState(true)
  const [applicationList, setApplicationList] = useState({
    0: { id: 0 },
  })
  const [openPremiumModal, setOpenPremiumModal] = useState(false)
  const [openBasicModal, setOpenBasicModal] = useState(false)
  const [tenantInformation, setTenantInformation] = useState([])
  const [total, setTotal] = useState()
  const [tenantsName, setTenantsName] = useState()

  useEffectOnce(() => {
    dispatch({
      type: "RESET",
      payload: {},
    })
    const name = getQueryParam("name", null)
    const contact_type = getQueryParam("contact_type", null)
    const contact_info = getQueryParam("contact_info", null)
    const phone = getQueryParam("phone", null)
    const selected_features = getQueryParam(
      "selected_features",
      ""
    ).split(",")
    const hide_add_more = getQueryParam("hide_add_more", false)
    if (hide_add_more) setAddMoreHidden(true)
    const disabled_fields = new Set()
    if (contact_type !== null) disabled_fields.add("contact_type")
    if (contact_info !== null) disabled_fields.add("contact_info")
    setTenantInformation([
      {
        id: 0,
        name: name,
        phone: phone,
        email: contact_type === "email" ? contact_info : null,
      },
    ])
    for (const feature of premiumFeatures) {
      if (selected_features.includes(feature.id)) {
        dispatch({
          type: "ADD_TO_CART",
          payload: {
            ...feature,
            applicantId: 0,
          },
          id: 0,
        })
      }
    }
  })
  useEffect(() => {
    dispatch({
      type: "UPDATE_TENANT_INFO",
      payload: tenantInformation,
    })
  }, [tenantInformation, dispatch])

  useEffect(() => {
    dispatch({
      type: "RESET",
      payload: {},
    })
  }, [])

  const addTenant = () => {
    setApplicationList({
      ...applicationList,
      [parseInt(
        applicationList[
          Object.keys(applicationList)[
            Object.keys(applicationList).length - 1
          ]
        ].id
      ) + 1]: {
        id:
          parseInt(
            applicationList[
              Object.keys(applicationList)[
                Object.keys(applicationList).length - 1
              ]
            ].id
          ) + 1,
      },
    })
  }

  const removeTenant = (item) => {
    setApplicationList(
      applicationList.filter((listItem) => listItem.id !== item.id)
    )
  }

  useEffect(() => {
    let total = 0
    Object.keys(cart).map((item) =>
      Object.keys(cart[item]).map(
        (cartItem) =>
          (total =
            total +
            Number(cart[item][cartItem].price) *
              cart[item][cartItem].qty)
      )
    )

    setTotal(total)
  }, [cart])

  return (
    <section className="ScreenApplicants">
      <div className="container">
        <button
          className="btn btn--back"
          onClick={() => {
            navigate(-1)
          }}
        >
          back
        </button>
        <ProgressBar type="landlord" step="4" />
        <h1>screen your applicants</h1>

        <div className="row">
          {/* Tenant info*/}
          <div className="col-xl-8 col-lg-7">
            {Object.keys(applicationList).map((id) => (
              <ApplicantForm
                key={id}
                id={id}
                removeTenant={() => removeTenant(id)}
                tenantInformation={tenantInformation}
                setTenantInformation={setTenantInformation}
              />
            ))}
            {!addMoreHidden && (
              <button
                className="btn btn--secondary btn--small mb-3"
                onClick={addTenant}
              >
                add more
              </button>
            )}
          </div>

          {/* Order Info*/}
          <div className="col-xl-4 col-lg-5">
            <div className="order-summary">
              <div className="order-summary__details">
                <h2>order info</h2>
                <div className="order-summary__order-item order-summary__order-item--large">
                  <span>user's application</span>
                  <span>${total}</span>
                </div>

                {cart.length > 0 ? (
                  <>
                    {cart.map((feature) => (
                      <div
                        className="order-summary__order-item"
                        key={feature[0].id}
                      >
                        <span>{feature[0].name}</span>
                        <span>${feature[0].price}</span>
                      </div>
                    ))}
                  </>
                ) : (
                  "add premium features!"
                )}
              </div>
              <div className="order-summary__total">
                <h2>total amount</h2>
                <h2>${total}</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="btn-group">
          <button
            className="btn btn--primary"
            onClick={() => {
              navigate("/landlord/screen-applicants/payment")
            }}
          >
            proceed to payment
          </button>

          <button
            className="btn btn--secondary-alt"
            disabled={true}
            /* onClick={() => {
              setOpenRequestApplicantModal(true)
            }} */
          >
            <div className="comingSoon">
              <h4 className="comingText">coming soon!</h4>
            </div>
            request tenants pay
          </button>
        </div>
      </div>
      {openPremiumModal && (
        <PremiumModal closeModal={setOpenPremiumModal} />
      )}
      {openBasicModal && (
        <BasicModal closeModal={setOpenBasicModal} />
      )}
      {/*{openRequestApplicantModal && (*/}
      {/*  <RequestApplicantPayModal*/}
      {/*    closeModal={setOpenRequestApplicantModal}*/}
      {/*  />*/}
      {/*)}*/}
    </section>
  )
}

export default ScreenApplicants
