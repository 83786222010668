import "./tenantDashboard.scss"
import SelectInput from "../../../Components/Input/SelectInput"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import config from "../../../config/config"
import axios from "axios"
import Cookies from "universal-cookie"
import { AuthContext } from "Context/authContext/AuthContext"
import { useContext } from "react"
function TenantDashboard() {
  const { user } = useContext(AuthContext)

  const [applications, setApplications] = useState([])
  const cookies = new Cookies()

  useEffect(() => {
    const cookie = new Cookies()
    axios
      .get(`${config.BASE_URL}api/applicant`, {
        params: {
          sort_field: "created_at",
          sort_asc: 1,
        },
        headers: {
          Authorization: "Bearer " + cookie.get("auth_token"),
        },
      })
      .then((res) => {
        const { data } = res.data
        function parseStatus(status) {
          switch (status) {
            case "pending":
              return "in progress"
            case "in-review":
              return "submitted"
            default:
              return ""
          }
        }
        setApplications(
          data.map(
            ({
              application: app,
              application_id,
              created_at,
              id,
              status,
              unit,
              hasScreeningRequest
            }) => ({
              id, // unique key for react
              appId: id, // application id for reference.
              applicationId: application_id,
              address: app.address.split("\n")[0].trim(),
              status: (hasScreeningRequest) ? 'additional request' : parseStatus(status),
              hasScreeningRequest: hasScreeningRequest,
              unit: app.unit,
              date: Intl.DateTimeFormat().format(
                new Date(created_at)
              ),
              city: app.address?.split("\n")[1]?.trim(),
            })
          )
        )
      })
      .catch((e) => console.error({ e }))
  }, [])
  const navigate = useNavigate()
  return (
    <section className="TenantDashboard">
      <div className="container">
        <h1>hello {user.first_name}!</h1>
        <div className="title-container">
          <h2>your applications</h2>
        </div>

        <div className="TenantDashboard__applications">
          <table>
            <thead>
              <tr>
                <th className="text-left">address</th>
                <th>date applied</th>
                <th>status</th>
                <th>view application</th>
              </tr>
            </thead>
            <tbody>
              {applications.map((a) => (
                <tr
                  className="TenantDashboard__application"
                  key={a.id}
                >
                  <td className="text-left">
                    {a.unit ? `Unit: ${a.unit}, ` : null}
                    {a.address}
                  </td>
                  <td>{a.date}</td>
                  <td>{a.status}</td>
                  <td>
                    <button
                      className="btn btn--secondary-alt btn--small btn--shrink"
                      onClick={() => {
                        if(a.hasScreeningRequest) {
                          axios
                            .post(
                            `${config.BASE_URL}api/screening-request/screening-request-get-connect/${a.applicationId}`,
                            {
                              tenant_phone_number: user.phone_number,
                              tenant_id: user.user_id,
                              tenant_email: user.email,
                            },
                            {
                              headers: {
                                Authorization: "Bearer " + cookies.get("auth_token"),
                              },
                            }
                          )
                            .then((res) => {
                            localStorage.setItem(
                              "currentApplicantId",
                              a.id
                            )

                            localStorage.setItem(
                              "currentApplicationId",
                              a.applicationId
                            )

                            localStorage.setItem("isNotFirstSubmission", true)
                            setTimeout(() => {
                              navigate('/tenant/premium-features')
                            })
                          })
                        } else {
                          localStorage.setItem(
                            "currentApplicantId",
                            a.id
                          )

                          localStorage.setItem(
                            "currentApplicationId",
                            a.applicationId
                          )

                          //localStorage.setItem("isNotFirstSubmission", true)
                          setTimeout(() => {
                            if (a.status === "submitted")
                              navigate("/tenant/dashboard/application")
                            else navigate("/tenant/current-housing")
                          })
                        }
                      }}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  )
}

export default TenantDashboard
