import "./householdInfo.scss"
import { useEffect, useState } from "react"
import TextInput from "../../../Components/Input/TextInput"
import ProgressBar from "../../../Components/ProgressBar/ProgressBar"
import NumberPickerInput from "../../../Components/Input/NumberPickerInput"
import DatePickerInput from "../../../Components/Input/DatePickerInput"
import TextareaInput from "../../../Components/Input/TextareaInput"
import { useNavigate } from "react-router-dom"
import PetInfo from "./PetInfo"
import axios from "axios"
import config from "../../../config/config"
import Cookies from "universal-cookie"
import { usePremiumFlow } from "Context/flowPremiumContext/FlowPremiumContext"

function HouseholdInfo() {
  const premiumFlow = usePremiumFlow()
  const [petList, setPetList] = useState([])
  const navigate = useNavigate()
  const [hasPets, setHasPets] = useState(null)
  const [isSmoker, setIsSmoker] = useState(null)
  const [needAccessibleHome, setNeedAccessibleHome] = useState(null)
  const [tenantCount, setTenantCount] = useState(0)
  const [minorCount, setMinorCount] = useState(0)
  const [parkingCount, setParkingCount] = useState(0)
  const [rentDuration, setRentDuration] = useState(0)
  const [monthlyIncome, setMonthlyIncome] = useState(0)
  const [description, setDescription] = useState("")
  const [isUpdating, setIsUpdating] = useState(false)
  const [requestedMoveInDate, setRequestedMoveInDate] = useState(false)

  const cookies = new Cookies()
  const currApplicantId = localStorage.getItem("currentApplicantId")

  const [existingHouseholdId, setExistingHouseholdId] = useState(null)

  const [minorErr, setMinorErr] = useState(false)
  const [minorErrMsg, setMinorErrMsg] = useState("")
  const MINOR_EXCEEDS_TENANT = new Error(
    "Minor count exceeds tenant count"
  )

  const [requestedMoveInDateErr, setRequestedMoveInDateErr] = useState({hasError: false, msg: ""})
  const [rentingDurationErr, setRentingDurationErr] = useState({hasError: false, msg: ""})
  const [smokeErr, setSmokeErr] = useState({hasError: false, msg: ""})
  const [accessibleErr, setAccessibleErr] = useState({hasError: false, msg: ""})
  const [petsErr, setPetsErr] = useState({hasError: false, msg: ""})
  const [descErr, setDescErr] = useState({hasError: false, msg: ""})

  const trackStateFromDb = (data) => {
    if (data.pets === "[]") {
      setHasPets(false)
      setPetList( [])
    } else {
      let pets = JSON.parse(data.pets)
      const newPets = pets.map((pet) => ({
        type: pet.type,
        size: pet.size,
        id: Math.random().toString(36)
      }))
      setPetList([...newPets])
      setHasPets(true)
    }
    if (data.smoke === 0) {
      setIsSmoker(false)
    } else {
      setIsSmoker(true)
    }
    if (data.accessible === 0) {
      setNeedAccessibleHome(false)
    } else {
      setNeedAccessibleHome(true)
    }

    setTenantCount(data.tenant_count)
    setMinorCount(data.minor_count)
    setParkingCount(data.parking_count)
    setRentDuration(data.renting_duration)
    setMonthlyIncome(data.monthly_income)
    setDescription(data.description)
    setRequestedMoveInDate(data.requested_move_in_date)
  }

  const handleBlurRentDuration = (e) => {
    if (e.target.value.match(/\d/)) {
      if (e.target.value === "0") {
        setRentingDurationErr({hasError: true, msg: "please enter valid a number"})
        setRentDuration("")
      } else {
        setRentDuration(e.target.value)
        setRentingDurationErr({hasError: false, msg: ""})
      }
    } else {
      setRentingDurationErr({hasError: true, msg: "please enter valid a number"})
      setRentDuration("")
    }
  }

  const handleNextBtnValidation = () => {
    let hasErrors = false
    if (isSmoker === null) {
      setSmokeErr({hasError: true, msg: "please select an option"})
      hasErrors = true
    }
    if (needAccessibleHome === null) {
      setAccessibleErr({hasError: true, msg: "please select an option"})
      hasErrors = true
    }
    if (description === "") {
      setDescErr({hasError: true, msg: "please enter a description"})
      hasErrors = true
    }
    if (hasPets === null) {
      setPetsErr({hasError: true, msg: "please select an option"})
      hasErrors = true
    }
    if (rentDuration === 0) {
      setRentingDurationErr({hasError: true, msg: "please enter valid a duration"})
      hasErrors = true
    }

    return hasErrors
  }

  const validateMinors = () => {
    if (minorCount > tenantCount) {
      setMinorErr(true)
      throw MINOR_EXCEEDS_TENANT
    }
    setMinorErr(false)
  }

  const handleSubmit = () => {
    try {
      validateMinors()
    } catch (e) {
      setMinorErrMsg(e.message)
      setMinorErr(true)
    }
    handleNextButton()
  }

  useEffect(() => {
    // Check for existing application data
    if (currApplicantId !== undefined)
      axios
        .get(`${config.BASE_URL}api/household/${currApplicantId}`, {
          headers: {
            Authorization: "Bearer " + cookies.get("auth_token"),
          },
        })
        .then((res) => {
          if (res.data !== "") {
            setIsUpdating(true)
            setExistingHouseholdId(res.data.household.id)
            trackStateFromDb(res.data.household)
          }
        })
        .catch((err) => {
          console.log("Error: ", err)
        })
  }, [])


  const handleNextButton = () => {
    const notAllClear = handleNextBtnValidation()
    if (notAllClear) {
      return
    }
    // Change endpoint if updating, needs to be existing household id, not applicant id
    const urlString = isUpdating
      ? `${config.BASE_URL}api/household/${existingHouseholdId}`
      : `${config.BASE_URL}api/household`

    axios
      .post(
        urlString,
        {
          tenant_count: tenantCount,
          minor_count: minorCount,
          monthly_income: monthlyIncome,
          renting_duration: rentDuration,
          parking_count: parkingCount,
          smoke: isSmoker,
          accessible: needAccessibleHome,
          has_pets: hasPets,
          requested_move_in_date: requestedMoveInDate,
          pets: hasPets
            ? petList.map(({ type, size }) => ({
                type,
                size,
              }))
            : [],
          description,
          application_id: localStorage.getItem(
            "currentApplicationId"
          ),
        },
        {
          headers: {
            Authorization: "Bearer " + cookies.get("auth_token"),
          },
        }
      )
      .then((res) => {
        const data = res.data
        if (!data.has_errors) {
          if (
            premiumFlow.hasData &&
            premiumFlow.premiumFeatures.length
          ) {
            navigate("/tenant/premium/paid")
          } else {
            navigate("/tenant/application-preview")
          }
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }
  const addPet = () => {
    setPetList((prevPetList) => {
      return [
        ...prevPetList,
        {
          // due to being able to remove pets it's not safe to use the length of the array as it can lead to duplicated ids
          id: Math.random().toString(36),
          type: null,
          size: null,
        },
      ]
    })
  }

  const removePet = (petId) => {
    // This is being passed an id, not an item
    const newPetList = petList.filter(
      (listItem) => listItem.id !== petId
    )
    if (newPetList.length === 0) {
      setHasPets(false)
      setPetList([])
      return
    }
    setPetList(newPetList)
  }
  const updatePet = (pet) => {
    setPetList(
      petList.map((item) => {
        return item.id === pet.id ? pet : item
      })
    )
  }
  return (
    <section className="HouseholdInfo">
      <div className="container">
        <button
          className="btn btn--back"
          onClick={() => {
            navigate(-1)
          }}
        >
          back
        </button>
        <ProgressBar type="tenant" step="5" />

        <h1>household & personal info</h1>

        <div className="row">
          <div className="col-xl-4 col-md-6">
            <NumberPickerInput
              min={0}
              max={100}
              label="how many people are you renting with?"
              name="tenant_count"
              value={tenantCount}
              onChange={setTenantCount}
              hasError={tenantCount > 100}
            />
          </div>
          <div className="col-xl-4 col-md-6">
            <NumberPickerInput
              min={0}
              label="how many of those are children/minors?"
              name="minor_count"
              value={minorCount}
              onChange={setMinorCount}
              hasError={minorCount > tenantCount}
            />
            {minorErr ? (
              <p className="text-danger">{minorErrMsg}</p>
            ) : null}
          </div>
          <div className="col-xl-4 col-md-6">
            <NumberPickerInput
              min={0}
              label="how many parking spots do you require?"
              name="parking_count"
              value={parkingCount}
              onChange={setParkingCount}
              hasError={parkingCount > 100}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4 col-md-6">
            <TextInput
              type="number"
              label="how many months do you want to rent for ?"
              name="rent_duration"
              value={rentDuration}
              hasError={rentingDurationErr.hasError}
              onBlur={handleBlurRentDuration}
              onChange={(e) => {
                if (e.target.value >= 0)
                  setRentDuration(e.target.value)
              }}
            />
            {rentingDurationErr.hasError && (
              <p className="text-danger">{rentingDurationErr.msg}</p>
            )}
          </div>
          <div className="col-xl-4 col-md-6">
            <TextInput
              type="number"
              label="what is the total household monthly income (everyone living in the home)?"
              name="monthly_income"
              value={monthlyIncome}
              onChange={(e) => {
                if (e.target.value >= 0)
                  setMonthlyIncome(e.target.value)
              }}
              hasError={monthlyIncome > 1000000}
            />
          </div>
        </div>
        
        <div className="row">
        <div className="col-xl-4 col-md-6">
            <DatePickerInput
                label="When would you want to move in?"
                name="requested_move_in_date"
                onChange={(e) => {
                    if (e.target.value != null)
                      setRequestedMoveInDate(e.target.value)
                }}
              />
            {requestedMoveInDateErr.hasError && (
              <p className="text-danger">{requestedMoveInDateErr.msg}</p>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4 col-md-6">
            <div className="form-group">
              <label>
                do you or any member of your household smoke?
              </label>
              <div className="form-choose-group">
                <button
                  id="smoker"
                  className={
                    isSmoker === true
                      ? "choose-input-group label active"
                      : "choose-input-group label"
                  }
                  onClick={() => {
                    setIsSmoker(true)
                  }}
                >
                  yes
                </button>
                <button
                  id="nonSmoker"
                  className={
                    isSmoker === false
                      ? "choose-input-group label active"
                      : "choose-input-group label"
                  }
                  onClick={() => {
                    setIsSmoker(false)
                  }}
                >
                  no
                </button>
              </div>
            </div>
            {smokeErr.hasError && (
              <p className="text-danger">{smokeErr.msg}</p>
            )}
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="form-group">
              <label>do you require an accessible home?</label>
              <div className="form-choose-group">
                <button
                  className={
                    needAccessibleHome === true
                      ? "choose-input-group label active"
                      : "choose-input-group label"
                  }
                  onClick={() => {
                    setNeedAccessibleHome(true)
                  }}
                >
                  yes
                </button>
                <button
                  className={
                    needAccessibleHome === false
                      ? "choose-input-group label active"
                      : "choose-input-group label"
                  }
                  onClick={() => {
                    setNeedAccessibleHome(false)
                  }}
                >
                  no
                </button>
              </div>
            </div>
            {accessibleErr.hasError && (
              <p className="text-danger">{accessibleErr.msg}</p>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4 col-md-6">
            <div className="form-group">
              <label>
                do you have any pets as part of your household?
              </label>
              <div className="form-choose-group">
                <button
                  className={
                    hasPets === true
                      ? "choose-input-group label active"
                      : "choose-input-group label"
                  }
                  onClick={() => {
                    setHasPets(true)
                  }}
                >
                  yes
                </button>
                <button
                  className={
                    hasPets === false
                      ? "choose-input-group label active"
                      : "choose-input-group label"
                  }
                  onClick={() => {
                    setHasPets(false)
                  }}
                >
                  no
                </button>
              </div>
            </div>
            {petsErr.hasError && (
              <p className="text-danger">{petsErr.msg}</p>
            )}
          </div>
        </div>

        {(hasPets && petList.length > 0) &&
          petList.map((petInfo) => (
            <PetInfo
              key={petInfo.id}
              onChange={updatePet}
              removePet={() => {
                removePet(petInfo.id)
              }}
              {...petInfo}
            />
          ))}
        {hasPets && (
          <button
            className="btn btn--secondary-alt btn--small mb-4"
            onClick={() => {
              addPet()
            }}
          >
            add a pet
          </button>
        )}
        <TextareaInput
          label="this is the space to tell the landlord a little bit about your household"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          hasError={(description.length > 1000 || descErr.hasError )}
        />
        {descErr.hasError && (
          <p className="text-danger">{descErr.msg}</p>
        )}

        <div className="btn-group">
          <button className="btn btn--primary" onClick={handleSubmit}>
            next
          </button>
        </div>
      </div>
    </section>
  )
}

export default HouseholdInfo
