import { useEffect, useState } from "react"
import Email from "./Email"
import EmailForm from "./EmailForm"

function EmailList({props}) {
  const [emails, setEmails] = useState([])

  useEffect(() => {
    if (emails !== []) {
      let emailData = emails.map((email) => email.text.toLowerCase())
      props("emails", emailData)
    }
  }, [emails])

  const addEmail = (email) => {
    let newEmails = [...emails]
    // Prevent duplicates in newEmails when adding email
    if (newEmails.find( (item) => item.text === email.text)) {
      // Silently handle if the email is already in the array
    } else {
      newEmails.push(email)
      setEmails(newEmails)
    }
  }

  const removeEmail = (id) => {
    const removeArr = [...emails].filter(
      (email) => email.id !== id
    )
    setEmails(removeArr)
  }

  return (
    <div>
      <Email
        emails={emails}
        removeEmail={removeEmail}
      />
      <EmailForm onSubmit={addEmail} />
    </div>
  )
}

export default EmailList
