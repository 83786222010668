import { useEffect, useState } from "react"
import EquifaxSvg from "../../../assets/svg/equifax.svg"
import "./creditCheck.scss"
import Cookies from "universal-cookie"
import axios from "axios"
import config from "../../../config/config"


function CreditCheck({ type, data }) {
  const [showData, setShowData] = useState(true)
  const [certnId, setCertnId] = useState(null)
  const [showDownloadBtn, setShowDownloadBtn] = useState(false)
  const [certn_data, setCertnData] = useState(null)
  const equifax = certn_data?.equifax_result
  const [creditLevel, setCreditLevel] = useState("poor");
  const [credit_score, setCreditScore] = useState(0);
  const error = certn_data?.information?.non_field_errors?.[0]
  const complete = data?.status === "RETURNED" || data?.status === 'Returned'


  const getCertnId = (array, property) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i][property] !== undefined) {
        return array[i][property]
      }
    }
  }

  const evaluateCertn = () => {
    if (certn_data.background_checks.length > 0 || certn_data.credit_checks.length > 0) {
      setShowDownloadBtn(true)
      if (certn_data.background_checks.length > 0) {
        setCertnId(getCertnId(certn_data.background_checks, "certn_id"))
      } else {
        setCertnId(getCertnId(certn_data.credit_checks, "certn_id"))
      }
    } else {
      setShowDownloadBtn(false)
    }
  }

  useEffect(() => {
    console.log(data);
    let certn_data_local = null;
    if(typeof data.certn_data != 'undefined') {
      certn_data_local = JSON.parse(data?.certn_data || "null");
    }
    else {
      certn_data_local = data;
    }
    
    setCertnData(certn_data_local);
    if(certn_data_local) {
        let current_credit_score = certn_data_local?.equifax_result.credit_score;
        setCreditScore(current_credit_score)

        if (current_credit_score >= 580) setCreditLevel("fair")
        if (current_credit_score >= 670) setCreditLevel("good")
        if (current_credit_score >= 740) setCreditLevel("very-good")
        if (current_credit_score >= 800) setCreditLevel("excellent")
        
        
      let userCertnId = certn_data_local?.id || null
      if (userCertnId) {
        setCertnId(userCertnId)
        setShowDownloadBtn(true)
      }
    }
  }, [data]);

  const handleGetFullReport = () => {
    // 3 Possible download endpoints, all POST Requests.
    const endpoints = {
      pdf: "download-report",
      web: "web-report",
      link: "link-report", // Only one that works atm
    }

    const openInNewTab = (url) => {
      const win = window.open(url, '_blank')
      win.focus()
    }

    const cookies = new Cookies()
    const apiConfig = {
      headers: {
        Authorization: "Bearer " + cookies.get("auth_token"),
      },
    }
    axios.post(
      `${config.BASE_URL}api/certn/${endpoints.link}`,
      {application_id: certnId},
      apiConfig
    )
      .then((response) => {
        if (response.data.status === 1) {
          openInNewTab(response.data.data.report_url)
        }
      })
      .catch((error) => {
        console.log(error)
      })

  }


  return (
    <section className="credit-check-info mainContent">
      <div className="application-preview-badge">
        <div className="application-preview-badge__inner">
          <span>powered by</span>
          <img
            src={EquifaxSvg}
            width={101}
            height={21}
            alt="Equifax"
          />
        </div>
      </div>
      {error ? (
        <div className="info-title-container-p">
          <div className="info-title">
            <h2>Unexpected Error in Credit Check:</h2>
            <p>{error}</p>
          </div>
        </div>
      ) : (
        <div className="info-title-container-p">
          <div className="info-title">
            <div
              className={`info__label info__label--${
                complete ? "completed" : "incomplete"
              }`}
            >
              {complete ? "completed" : "incomplete"}
            </div>
            <h2>
              <span className="mr-1">credit check</span>
            </h2>
            {type === "landlord" && showDownloadBtn && (
               <div>
                   <button 
                        className="btn btn--primary"
                        onClick={handleGetFullReport}
                   >
                     download report
                   </button>
               </div>
            )}
          </div>
          <div className="info-title-controls">
            <button
              className="btn btn--toggle btn--toggle-inverse"
              onClick={() => setShowData(!showData)}
            ></button>
          </div>
        </div>
      )}

      {/*
                choose a css class to set the credit score

                Excellent = .credit-score--excellent
                Very Good = .credit-score--very-good
                Good = .credit-score--good
                Fair = .credit-score--fair
                Poor = .credit-score--poor
            */}
      {showData && !error && (
        <div className="credit-score-information">
          <div className={"credit-score--" + creditLevel}>
            <h6>credit score</h6>
            <h2 className="credit-score__title">{credit_score}</h2>
            <div className="credit-score__scale-container">
              <div className="credit-score__scale-section credit-score__scale-section--poor">
                <div className="credit--score__scale-section__pill">
                  <span>300-579</span>
                </div>
                <div className="credit--score__scale-section__label">
                  Poor
                </div>
              </div>
              <div className="credit-score__scale-section credit-score__scale-section--fair">
                <div className="credit--score__scale-section__pill">
                  <span>580-669</span>
                </div>
                <div className="credit--score__scale-section__label">
                  Fair
                </div>
              </div>
              <div className="credit-score__scale-section credit-score__scale-section--good">
                <div className="credit--score__scale-section__pill">
                  <span>670-739</span>
                </div>
                <div className="credit--score__scale-section__label">
                  Good
                </div>
              </div>
              <div className="credit-score__scale-section credit-score__scale-section--very-good">
                <div className="credit--score__scale-section__pill">
                  <span>740-799</span>
                </div>
                <div className="credit--score__scale-section__label">
                  Very Good
                </div>
              </div>
              <div className="credit-score__scale-section credit-score__scale-section--excellent">
                <div className="credit--score__scale-section__pill">
                  <span>800-850</span>
                </div>
                <div className="credit--score__scale-section__label">
                  Excellent
                </div>
              </div>
            </div>
          </div>
          {type === "landlord" && (
            <>
              <h4>unpaid bills</h4>
              <table className="credit-score-table">
                <thead>
                  <tr>
                    <th>Credit Grantor</th>
                    <th>% Credit Used</th>
                    <th>
                      Late Payments
                      <br />
                      (30/60/90)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {certn_data?.equifax_result?.trades?.length > 0 ? (
                    certn_data?.equifax_result?.trades?.map(
                      (trade, index) => (
                        <tr key={trade.id}>
                          <td>{trade.credit_grantor}</td>
                          <td>
                            {Math.floor(
                              Number(trade.percent_credit_used) * 1000
                            ) / 10}
                            %
                          </td>
                          <td>
                            {trade.past_due_count_30_day || 0}/
                            {trade.past_due_count_60_day || 0}/
                            {trade.past_due_count_90_day || 0}
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td>No Entries</td>
                    </tr>
                  )}
                </tbody>
              </table>

              <h4>debts sent collections</h4>
              <table className="credit-score-table">
                <thead>
                  <tr>
                    <th>creditor</th>
                    <th>assigned date</th>
                    <th>date paid</th>
                    <th>amount owed</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <td>TBD</td>
                  </tr> */}
                  {certn_data?.equifax_result?.collections?.map(
                    (collection) => {
                      return (
                        <tr key={collection.id}>
                          <td>
                            {collection.creditor ??
                              collection.agency_entity ??
                              "unknown"}
                          </td>
                          <td>{collection.assigned_data}</td>
                          <td>{collection.date_paid}</td>
                          <td>${collection.amount_owed}</td>
                        </tr>
                      )
                    }
                  )}
                  {certn_data?.equifax_result?.collections?.length > 0 ? (
                    certn_data?.equifax_result?.collections?.map(
                      (collection) => {
                        return (
                          <tr key={collection.id}>
                            <td>
                              {collection.creditor ??
                                collection.agency_entity ??
                                "unknown"}
                            </td>
                            <td>{collection.assigned_data}</td>
                            <td>{collection.date_paid}</td>
                            <td>${collection.amount_owed}</td>
                          </tr>
                        )
                      }
                    )
                  ) : (
                    <tr>
                      <td>No Entries</td>
                    </tr>
                  )}
                </tbody>
              </table>

              <h4>reported bankruptcies</h4>
              <table className="credit-score-table">
                <thead>
                  <tr>
                    <th>customer</th>
                    <th>liability amount</th>
                    <th>date filled</th>
                  </tr>
                </thead>
                <tbody>
                  {certn_data?.equifax_result?.bankruptcies?.length ? (
                    certn_data?.equifax_result?.bankruptcies?.map(
                      (bankruptcy) => (
                        <tr key={bankruptcy.id}>
                          <td>{bankruptcy.customer}</td>
                          <td>{bankruptcy.liability_ammount}</td>
                          <td>{bankruptcy.date_filed}</td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td>No Entries</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          )}
        </div>
      )}
    </section>
  )
}

export default CreditCheck
