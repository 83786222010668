/**
 * I want there to be a single source of truth for the premium features and the way they are
 * used in the application preview. This is that source of truth.
 * It will help for data congruency and actually knowing what premium features are available.
 */


export const PREMIUM_FEATURES_LIST = [
  {
    id: "credit_check",
    name: "credit check",
    location: "/tenant/credit-report",
    status: null
  },
  {
    id: "background_check",
    name: "background check",
    location: "/tenant/background-check",
    status: null
  },
  {
    id: "financial_verification",
    name: "financial verification",
    location: "/tenant/employment-income-verification",
    status: null
  },
  {
    id: "id_verification",
    name: "id verification",
    location: "/tenant/employment-income-verification",
    status: null
  }
]