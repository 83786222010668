import "./applicantInfo.scss"
import Cookies from "universal-cookie"
import axios from "axios"
import config from "../../../../config/config"
import { useEffect, useState } from "react"

function ApplicantInfo({ user, data, toggleFavStatus}) {
  const [isFavourite, setIsFavourite] = useState(data.is_favourite)

  function numberToCurrency(number) {
    return new Intl.NumberFormat("en-US", {
    }).format(number)
  }

  // newVal must be 1 or 0
  const toggleFavourites = (newVal) => {
    const applicant_id = data.applicant_id
    const cookies = new Cookies()
    const apiConfig = {
      headers: {
        Authorization: "Bearer " + cookies.get("auth_token"),
      },
    }
    axios
      .post(
        `${config.BASE_URL}api/applicant/update-favourite/${applicant_id}`,
        {is_favourite: newVal},
        apiConfig
      )
      .then((response) => {
        if (response.data.status === 1) {
          toggleFavStatus(applicant_id)
          setIsFavourite(response.data.is_favourite)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    setIsFavourite(data.is_favourite)

  }, [data.is_favourite])

  const noZeroHouseSize = data.houseSize + 1


  return (
    <section className="applicant-info">
      {/* <div className="top-badge mb-0-5">top tenant</div> */}
      <div className="applicantContent">
        <h2 className="applicant__name">
          {user.first_name} {user.last_name}
        </h2>
        <h4 className="mt-0">
          date applied:{" "}
          {new Date(user.created_at).toLocaleDateString()}
        </h4>
        <div className="row mt-2">
          <div className="col-md">
            <div className="applicant-info__contact-info-label">
              Email Address:
            </div>
            <a
              href={"mailto:" + user.email}
              className="applicant-info__contact-info-value"
            >
              {user.email}
            </a>
          </div>
          <div className="col-md">
            <div className="applicant-info__contact-info-label">
              Phone Number:
            </div>
            <a
              href={"tel:" + user.phone_number.replace(/[^0-9]/g, "")}
              className="applicant-info__contact-info-value"
            >
              {user.phone_number}
            </a>
          </div>
        </div>

         <div className="btn-group mb-2 mt-1">
           { isFavourite === 0 ?
             (<button className="btn btn--secondary"
                     onClick={() => {
                       toggleFavourites(1)
                     }}
             >
               add to favorites
             </button> ) :
             ( <button className="btn btn--secondary"
                     onClick={() => {
                       toggleFavourites(0)
                     }}
             >
               remove from favorites
             </button> )
           }
        </div>

        <h2>summary of applicant</h2>
        <div className="applicant-info__attributes-container">
          <div className="applicant__attribute">
            <span className="applicant__attribute__icon attribute-icon--household">
              {noZeroHouseSize}
            </span>
            <span className="applicant__attribute__label">
              {noZeroHouseSize} person household
            </span>
          </div>
          <div className="applicant__attribute">
            <span className="applicant__attribute__icon attribute-icon--income"></span>
            <span className="applicant__attribute__label">
              $ {numberToCurrency(data.income)} income
            </span>
          </div>
          <div className="applicant__attribute">
            <span className="applicant__attribute__icon attribute-icon--has-pets"></span>
            <span className="applicant__attribute__label">
              {/* This needs to have a question mark or  be refactored to its own variable, api returns null if no pets */}
              {data.pets?.length || "no"} pets
            </span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ApplicantInfo
