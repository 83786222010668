import {
  createContext,
  useEffect,
  useReducer,
} from "react"
import AuthReducer from "./AuthReducer"

const INITIAL_STATE = {
  user:
    (localStorage.getItem("user_expiresAt") != null && (new Date().getTime() < localStorage.getItem("user_expiresAt"))) ? JSON.parse(localStorage.getItem("user")) : null,
  user_expiresAt:
    (localStorage.getItem("user_expiresAt") != null) ? localStorage.getItem("user_expiresAt") : null,
  isFetching: false,
  error: false,
}

export const AuthContext = createContext(
  INITIAL_STATE
)

export const AuthContextProvider = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    AuthReducer,
    INITIAL_STATE
  )

  useEffect(() => {
    localStorage.setItem(
      "user",
      JSON.stringify(state.user)
    );
  }, [state.user])

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        isFetching: state.isFetching,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
