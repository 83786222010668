import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import "./landLordLanding.scss"
import SplashImg from "../../../assets/images/landlord-landing.png"
import BetaNotifyModal from "./BetaNotifyModal/BetaNotifyModal"
import { useNavigate } from "react-router-dom"
import Helmet from "react-helmet"
import metaLinkImg from "../../../assets/images/metaLinkSummaryBG.png"
import PriceLabel from "../../../Components/Labels/PriceLabel/PriceLabel"

export default function LandlordLanding() {
  const navigate = useNavigate()
  const [openBetaModal, setOpenBetaModal] = useState(false)

  const showVerifyEmailModal = () => {
    setTimeout(() => {
      setOpenBetaModal(true)
    }, 2500)
  }

  useEffect(() => {
    showVerifyEmailModal()
  }, [])

  return (
    <section className="LandlordLanding">
      <Helmet>
        <meta charSet="utf-8" />
        {/* Titles */}
        <title>The smartest tenant screening tool for real estate investors in Canada | Rent Panda</title>
        <meta name="title" key="title" content={"The smartest tenant screening tool for real estate investors in Canada | Rent Panda"} />
        <meta property="og:title" key="og:title" content={"The smartest tenant screening tool for real estate investors in Canada | Rent Panda"} />

        {/* Open Graph (OG) is for social link previews */}
        <meta property="og:locale" key="og:locale" content="en_US" />
        <meta property="og:type" key="og:type" content="website" />
        <meta name="description" key="description" content={"Smart Screening makes choosing a tenant easy for landlords - top tenant recos, instant credit & background checks, income & expense reports and ID verification software."} />
        <meta
          property="og:description"
          key="og:description"
          content={"Smart Screening makes choosing a tenant easy for landlords - top tenant recos, instant credit & background checks, income & expense reports and ID verification software."}
        />
        <meta property="og:url" content="https://screening.rentpanda.ca/" />
        <meta name={"ia:markup_url"} content={"https://screening.rentpanda.ca/"} />
        {/* Facebook image */}
        <meta
          property="og:image"
          key="og:image"
          content={`${metaLinkImg}`}
        />
        {/* Twitter image */}
        <meta name="twitter:card" content="summary" />
        <meta
          name="description"
          content="Smart Screening makes choosing a tenant easy for landlords - top tenant recos, instant credit & background checks, income & expense reports and ID verification software."
        />
      </Helmet>
      <div className="container">
        {openBetaModal && (
          <BetaNotifyModal props={setOpenBetaModal} />
        )}
        <div className="row">
          <div className="col-lg-6">
            <h1>the smartest screening tool around</h1>
            <p className="subText">
              Smart screening is the fastest way to pre-qualify
              applicants, rank them based on the best fit for your
              rental and get access to the most advanced tools to
              reveal all the information you need to make the right
              decision.
            </p>
            <div className="btn-group btn-screening">
              <button
                className="btn btn--primary"
                onClick={() => navigate("/login")}
              >
                start screening
              </button>
            </div>
          </div>
          <div className="col-lg-6">
            <img
              className="LandlordLanding__splash-img"
              src={SplashImg}
              alt="Rent Panda Landlord"
            />
          </div>
        </div>
        <div className="LandlordLanding__details-container">

          <div className="servicesHeader">

            <h5> <hr className={"topGreenSemi"}/>our full suite of screening tools for <span className={"goGreen"}> {" "} only $35.00</span></h5>
          </div>

          <div className="LandlordLanding__details">
            <div className="LandlordLanding__details-item">
              <div className="LandlordLanding__details-item__svg">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.2656 24.687L33.5312 43.112C35.1094 45.1575 38.1562 45.4674 40.3281 43.8093C42.5 42.1512 42.9844 39.1604 41.4062 37.1149L27.1406 18.6899L19.2656 24.687Z"
                    fill="#8CC947"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M31.7715 32.7431C38.295 27.7769 39.5368 18.5227 34.5451 12.0733C29.5533 5.62385 20.2183 4.42149 13.6948 9.38772C7.1712 14.354 5.92943 23.6082 10.9212 30.0576C15.9129 36.507 25.2479 37.7094 31.7715 32.7431Z"
                    fill="white"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M22.9648 20.7513C25.243 20.7513 27.0898 18.9197 27.0898 16.6603C27.0898 14.4009 25.243 12.5693 22.9648 12.5693C20.6867 12.5693 18.8398 14.4009 18.8398 16.6603C18.8398 18.9197 20.6867 20.7513 22.9648 20.7513Z"
                    fill="#8CC947"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M29.0432 28.8406C29.4651 28.8406 29.8088 28.4842 29.7619 28.0812C29.3869 24.6876 26.4807 22.0532 22.9651 22.0532C19.4494 22.0532 16.5432 24.6876 16.1682 28.0812C16.1213 28.4842 16.4807 28.8406 16.8869 28.8406H29.0432Z"
                    fill="#8CC947"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                </svg>
              </div>
              <div className="LandlordLanding__details-item__details">
                <PriceLabel isFree={true} />
                <h4>pre-qualify applicants</h4>
                <p>
                  Get employment, tenancy and household info upfront,
                  so you can save time communicating and doing
                  showings later.{" "}
                </p>
              </div>
            </div>

            <div className="LandlordLanding__details-item">
              <div className="LandlordLanding__details-item__svg">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M28.1094 32.0676H23.3438V43.4895H28.1094V32.0676Z"
                    fill="#8CC947"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.6875 12.177H8.04687C7.53125 12.177 7.125 12.5208 7.125 12.9583V22.3333C7.125 25.1301 10.0937 27.4114 13.1562 27.4114L12.6719 12.177H12.6875Z"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M38.7813 12.177H43.4219C43.9375 12.177 44.3438 12.5208 44.3438 12.9583V22.3333C44.3438 25.1301 41.375 27.4114 38.3125 27.4114L38.7969 12.177H38.7813Z"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M17.7188 45.4426C17.7188 46.5208 18.9844 47.3958 20.5312 47.3958H30.9531C34.6562 47.3489 34.6562 43.5364 30.9531 43.4895H20.5312C18.9688 43.4895 17.7188 44.3645 17.7188 45.4426Z"
                    fill="#8CC947"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.6055 4.45825C13.0898 4.45825 12.6836 4.8645 12.6836 5.34888V24.302C13.3555 40.1614 38.1055 40.1614 38.7773 24.302V5.34888C38.7773 4.8645 38.3711 4.45825 37.8555 4.45825H13.6055Z"
                    fill="white"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M27.2107 15.6088C27.4089 16.0053 27.7859 16.3028 28.2545 16.3695L28.402 15.3804C31.5852 15.8966 33.0928 15.8764 33.2325 16.3037C33.3719 16.7299 32.1495 17.6016 29.8707 19.896L29.1636 19.1889C29.1632 19.1893 29.1628 19.1897 29.1624 19.1901C28.8654 19.4879 28.6783 19.9385 28.7786 20.4412L29.3368 23.7105L26.3983 22.167L26.3894 22.1623L26.3804 22.1578C26.0009 21.968 25.5127 21.9363 25.0824 22.1726L22.1547 23.7105L22.7158 20.4237L22.7196 20.4016L22.7224 20.3794C22.7653 20.0359 22.6955 19.5566 22.3278 19.1889L22.3279 19.1888L22.3192 19.1803L19.9328 16.8513L23.2272 16.3709C23.6761 16.3099 24.0941 16.0134 24.2905 15.5892L25.7457 12.6449L27.2086 15.6047L27.2086 15.6047L27.2107 15.6088Z"
                    fill="#8CC947"
                    stroke="#000105"
                    strokeWidth="2"
                  />
                </svg>
              </div>
              <div className="LandlordLanding__details-item__details">
                <PriceLabel isFree={true} />
                <h4>top tenant recommendations</h4>
                <p>
                  Smart tech sorts applicants and recommends the best,
                  so you can save time sifting through applications
                </p>
              </div>
            </div>


            <div className="LandlordLanding__details-item">
              <div className="LandlordLanding__details-item__svg">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M18.543 35.3199C18.543 30.8043 23.1211 28.0107 27.9023 30.0264C30.2305 31.0107 31.6367 33.4326 31.6211 35.9639C31.6211 36.1826 31.793 36.3545 31.9961 36.3545H46.1523C46.5898 36.3545 46.9648 35.9951 46.9492 35.542C46.5273 23.7295 36.9023 14.292 25.0898 14.292C13.2773 14.292 3.41797 23.9639 3.21484 35.9639C3.21484 36.167 3.38672 36.3389 3.58984 36.3389H18.1836C18.3867 36.3389 18.5586 36.167 18.5586 35.9639L18.543 35.3199Z"
                    fill="white"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M7.65485 23.5637L19.1882 31.8659C19.3409 31.9745 19.3867 32.1917 19.2951 32.3469C19.1118 32.6883 18.9132 33.0918 18.7604 33.5728C18.5618 34.178 18.4855 34.7367 18.4549 35.2022C18.4244 35.6057 18.1036 35.9471 17.6911 35.9471H4.23305C3.79004 35.9471 3.45397 35.5747 3.46925 35.1402C3.51508 33.8056 3.68311 32.1917 4.14139 30.4227C4.90519 27.4277 6.15781 25.1465 7.16602 23.6258C7.27295 23.4551 7.50209 23.424 7.67013 23.5482L7.65485 23.5637Z"
                    fill="white"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M42.6653 23.8544L30.8684 32.2294C30.7122 32.3387 30.6653 32.5419 30.759 32.7137C31.0403 33.2294 31.5872 34.3856 31.6497 35.6356C31.6653 36.0419 32.009 36.3544 32.4153 36.3544H46.5872C46.7903 36.3544 46.9622 36.1981 46.9622 35.9794C46.9465 34.5419 46.7747 32.7606 46.2747 30.7762C45.4934 27.7606 44.2122 25.4637 43.1809 23.9325C43.0715 23.7606 42.8372 23.7294 42.6653 23.8544Z"
                    fill="#8CC947"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M32.0761 15.8549L27.5292 29.5424C27.4667 29.7299 27.5448 29.9174 27.7167 29.9956C28.2792 30.2612 29.5448 30.9643 30.3729 32.1049C30.4979 32.2768 30.7323 32.3237 30.9042 32.1987L42.6698 23.8549C42.8417 23.7299 42.8886 23.5112 42.7636 23.3393C42.0761 22.3862 41.2323 21.3706 40.1542 20.3549C37.4511 17.7768 34.6073 16.3862 32.5448 15.6206C32.3417 15.5424 32.1386 15.6518 32.0761 15.8549Z"
                    fill="white"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M18.6676 15.2405C20.1363 14.7967 25.9801 13.3124 31.8395 15.3476C32.027 15.4088 32.1363 15.623 32.0738 15.8066L27.527 29.1956C27.4645 29.3793 27.2613 29.4864 27.0582 29.4252C26.4332 29.2416 25.027 28.9202 23.4332 29.3334C23.2457 29.3793 23.027 29.2875 22.9645 29.1038L18.4332 15.7148C18.3707 15.5159 18.4645 15.317 18.6676 15.2558V15.2405Z"
                    fill="white"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M32.1602 15.479L27.4258 29.9946L32.1602 15.479Z"
                    fill="white"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M30.5508 32.4326L42.9883 23.5732L30.5508 32.4326Z"
                    fill="white"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M23.0313 29.667L18.3281 15.4014L23.0313 29.667Z"
                    fill="white"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M7.20312 23.5581L19.5937 32.4175L7.20312 23.5581Z"
                    fill="white"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M37.5295 32.9512C37.5273 32.949 37.5243 32.948 37.5212 32.9483L24.5417 34.4009C24.1823 34.4478 23.9323 34.7759 24.0104 35.1353L24.1823 36.0103C24.2448 36.3696 24.6042 36.5884 24.9636 36.4946L37.5251 32.968C37.5325 32.966 37.5349 32.9566 37.5295 32.9512Z"
                    fill="#000105"
                    stroke="#000105"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="LandlordLanding__details-item__details">
                <PriceLabel value={15} />

                <h4>instant credit checks</h4>
                <p>
                  Detailed credit report including credit score, who
                  they owe money to and how much, all cases sent to
                  collections and past bankruptcies.
                </p>
              </div>
            </div>

            <div className="LandlordLanding__details-item">
              <div className="LandlordLanding__details-item__svg">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M28.5586 8.93164H22.2148C20.489 8.93164 19.0898 10.3308 19.0898 12.0566V15.4473C19.0898 17.1732 20.489 18.5723 22.2148 18.5723H28.5586C30.2845 18.5723 31.6836 17.1732 31.6836 15.4473V12.0566C31.6836 10.3308 30.2845 8.93164 28.5586 8.93164Z"
                    fill="white"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M43.707 18.8535H7.06641C6.20346 18.8535 5.50391 19.5531 5.50391 20.416V40.4629C5.50391 41.3258 6.20346 42.0254 7.06641 42.0254H43.707C44.57 42.0254 45.2695 41.3258 45.2695 40.4629V20.416C45.2695 19.5531 44.57 18.8535 43.707 18.8535Z"
                    fill="white"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M6.10156 16.3989C5.03906 16.3989 4.19531 17.0759 4.19531 17.9186V26.7604C4.19531 28.4182 13.6797 29.7444 25.3828 29.7444C37.0859 29.7444 46.5703 28.4044 46.5703 26.7604V17.9186C46.5703 17.0759 45.7109 16.3989 44.6641 16.3989H6.10156Z"
                    fill="white"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M27.8465 25.394H22.9233C22.4004 25.394 21.9766 25.8428 21.9766 26.3963V33.0912C21.9766 33.6447 22.4004 34.0935 22.9233 34.0935H27.8465C28.3694 34.0935 28.7933 33.6447 28.7933 33.0912V26.3963C28.7933 25.8428 28.3694 25.394 27.8465 25.394Z"
                    fill="#8CC947"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                </svg>
              </div>
              <div className="LandlordLanding__details-item__details">
                <PriceLabel value={6} />

                <h4>comprehensive background checks</h4>
                <p>
                  Exposes any criminal record, shows Landlord and
                  Tenant Board appearances, analyzes their social
                  media profiles and checks all public records.
                </p>
              </div>
            </div>


            <div className="LandlordLanding__details-item">
              <div className="LandlordLanding__details-item__svg">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M44.7773 32.1348C44.7773 32.1348 34.6523 38.1973 34.3867 38.2598L18.7773 41.6348C18.6055 41.666 18.4336 41.6973 18.2461 41.6973H10.7773V33.5723L17.793 29.5254C18.168 29.3066 18.6055 29.1973 19.043 29.1973H28.4961C30.6055 29.2129 30.5898 32.916 28.4961 32.9316H19.293C18.8398 32.9316 18.4648 33.3691 18.4648 33.916C18.4648 34.4629 18.8398 34.9004 19.293 34.9004H32.8555C32.9805 34.9004 43.3242 28.7129 43.3242 28.7129C43.543 28.5879 43.793 28.5098 44.0273 28.5098C45.8086 28.541 46.1992 31.3691 44.7773 32.1504V32.1348Z"
                    fill="white"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M9.10547 31.1504H6.55859C5.64387 31.1504 4.90234 31.8919 4.90234 32.8066V41.9316C4.90234 42.8464 5.64387 43.5879 6.55859 43.5879H9.10547C10.0202 43.5879 10.7617 42.8464 10.7617 41.9316V32.8066C10.7617 31.8919 10.0202 31.1504 9.10547 31.1504Z"
                    fill="#8CC947"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M41.5898 15.3379C41.5898 20.9004 37.0898 25.4004 31.5273 25.4004C18.1836 24.8691 18.1836 5.80664 31.5273 5.27539C37.0898 5.27539 41.5898 9.77539 41.5898 15.3379Z"
                    fill="#8CC947"
                    stroke="black"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M34.3418 18.7754C33.8574 19.2463 33.1713 19.5288 32.2564 19.6499V20.9953H31.0589V19.6903C30.0364 19.6364 28.8255 19.3135 28.0586 18.7215L28.7179 17.255C29.3502 17.7259 30.2382 18.0354 31.0455 18.1026V16.0979C30.0364 15.8288 29.2426 15.6539 28.6102 14.9005C27.9644 14.0798 28.1259 12.6267 28.9466 11.8867C29.4309 11.4158 30.1306 11.1198 31.0455 11.0122V9.68018H32.2429V10.9853C33.2251 11.066 34.0458 11.3082 34.6916 11.7252L34.0862 13.2052C33.4807 12.8554 32.8753 12.6536 32.2429 12.5863V14.6314C33.2385 14.887 34.0189 15.0485 34.6512 15.8019C35.2971 16.6092 35.1356 18.0488 34.3283 18.7754H34.3418ZM30.3997 13.999C30.5477 14.1336 30.7764 14.2412 31.0589 14.3219V12.6132C30.3593 12.7209 29.8346 13.4743 30.3997 13.999ZM32.9156 17.7394C33.4538 17.1339 32.956 16.5823 32.2698 16.3939V18.0488C32.5524 17.9815 32.7811 17.8739 32.9156 17.7394Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="LandlordLanding__details-item__details">
                <PriceLabel value={10} />

                <h4>income and expense verification</h4>
                <p>
                  We scan their bank statements, access their pay
                  stubs and measure their expenses with details
                  directly from the bank.
                </p>
              </div>
            </div>


            <div className="LandlordLanding__details-item">
              <div className="LandlordLanding__details-item__svg">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M31.7109 4.08057H10.0703C9.21094 4.08057 8.50781 4.78369 8.50781 5.64307V46.2681C8.50781 47.1274 9.21094 47.8306 10.0703 47.8306H40.6953C41.5547 47.8306 42.2578 47.1274 42.2578 46.2681V14.9868C42.2578 14.5806 42.1016 14.1899 41.8203 13.9087L32.8359 4.56494C32.5391 4.25244 32.1328 4.08057 31.7109 4.08057Z"
                    fill="white"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M25.3828 37.7524C31.898 37.7524 37.1797 32.4708 37.1797 25.9556C37.1797 19.4403 31.898 14.1587 25.3828 14.1587C18.8676 14.1587 13.5859 19.4403 13.5859 25.9556C13.5859 32.4708 18.8676 37.7524 25.3828 37.7524Z"
                    fill="#8CC947"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M32.1969 20.3307C31.6188 19.8463 30.775 19.9244 30.2907 20.5026L23.4313 28.7057L20.3219 26.0963C19.7438 25.6119 18.9 25.6901 18.4157 26.2682C17.9313 26.8463 18.0094 27.6901 18.5875 28.1744L22.6813 31.5963C23.2907 32.0807 24.1657 32.0026 24.6813 31.4244L32.3844 22.2369C32.8688 21.6588 32.7907 20.8151 32.2282 20.3307H32.1969Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="LandlordLanding__details-item__details">
                <PriceLabel value={10} isComingSoon={true} />

                <h4>verified landlord references</h4>
                <p>
                  Real reviews from past landlords who have been
                  verified through lease and landlord ID matching
                  technology.
                </p>
              </div>
            </div>

            <div className="LandlordLanding__details-item">
              <div className="LandlordLanding__details-item__svg">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M43.918 12.9551H6.26172C5.39877 12.9551 4.69922 13.6546 4.69922 14.5176V36.2832C4.69922 37.1461 5.39877 37.8457 6.26172 37.8457H43.918C44.7809 37.8457 45.4805 37.1461 45.4805 36.2832V14.5176C45.4805 13.6546 44.7809 12.9551 43.918 12.9551Z"
                    fill="white"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <mask id="path-2-inside-1_1202_1725" fill="white">
                    <path d="M41.0005 22.4703H24.0474C23.6099 22.4703 23.2662 22.8141 23.2662 23.2516C23.2193 23.7359 23.5474 24.1734 24.0474 24.1734H41.0162C42.0474 24.1891 42.063 22.4547 41.0162 22.4703H41.0005Z" />
                  </mask>
                  <path
                    d="M41.0005 22.4703H24.0474C23.6099 22.4703 23.2662 22.8141 23.2662 23.2516C23.2193 23.7359 23.5474 24.1734 24.0474 24.1734H41.0162C42.0474 24.1891 42.063 22.4547 41.0162 22.4703H41.0005Z"
                    fill="black"
                  />
                  <path
                    d="M23.2662 23.2516L25.2569 23.4442L25.2662 23.3481V23.2516H23.2662ZM41.0162 24.1734L41.0465 22.1737L41.0313 22.1734H41.0162V24.1734ZM41.0162 22.4703V24.4703H41.0311L41.046 24.4701L41.0162 22.4703ZM41.0005 20.4703H24.0474V24.4703H41.0005V20.4703ZM24.0474 20.4703C22.5053 20.4703 21.2662 21.7095 21.2662 23.2516H25.2662C25.2662 23.9186 24.7145 24.4703 24.0474 24.4703V20.4703ZM21.2755 23.0589C21.1355 24.5056 22.1628 26.1734 24.0474 26.1734V22.1734C24.4209 22.1734 24.7746 22.352 25.0012 22.6296C25.2121 22.888 25.2817 23.1874 25.2569 23.4442L21.2755 23.0589ZM24.0474 26.1734H41.0162V22.1734H24.0474V26.1734ZM40.9859 26.1732C41.8712 26.1866 42.6434 25.7972 43.1507 25.1729C43.6158 24.6005 43.7928 23.9209 43.7954 23.331C43.7981 22.7412 43.6272 22.0541 43.1574 21.4737C42.6439 20.8393 41.8658 20.4574 40.9863 20.4705L41.046 24.4701C40.6899 24.4754 40.3025 24.3045 40.0483 23.9904C39.8377 23.7303 39.7948 23.4728 39.7955 23.3128C39.7962 23.1525 39.8414 22.9027 40.0462 22.6505C40.2932 22.3466 40.6767 22.1681 41.0465 22.1737L40.9859 26.1732ZM41.0162 20.4703H41.0005V24.4703H41.0162V20.4703Z"
                    fill="#000105"
                    mask="url(#path-2-inside-1_1202_1725)"
                  />
                  <mask id="path-4-inside-2_1202_1725" fill="white">
                    <path d="M41.0005 26.6271H24.0474C23.6099 26.6271 23.2662 26.9708 23.2662 27.4083C23.2193 27.8927 23.5474 28.3302 24.0474 28.3302H41.0162C42.0474 28.3458 42.063 26.6114 41.0162 26.6271H41.0005Z" />
                  </mask>
                  <path
                    d="M41.0005 26.6271H24.0474C23.6099 26.6271 23.2662 26.9708 23.2662 27.4083C23.2193 27.8927 23.5474 28.3302 24.0474 28.3302H41.0162C42.0474 28.3458 42.063 26.6114 41.0162 26.6271H41.0005Z"
                    fill="black"
                  />
                  <path
                    d="M23.2662 27.4083L25.2569 27.601L25.2662 27.5049V27.4083H23.2662ZM41.0162 28.3302L41.0465 26.3304L41.0313 26.3302H41.0162V28.3302ZM41.0162 26.6271V28.6271H41.0311L41.046 28.6268L41.0162 26.6271ZM41.0005 24.6271H24.0474V28.6271H41.0005V24.6271ZM24.0474 24.6271C22.5053 24.6271 21.2662 25.8662 21.2662 27.4083H25.2662C25.2662 28.0754 24.7145 28.6271 24.0474 28.6271V24.6271ZM21.2755 27.2157C21.1355 28.6623 22.1628 30.3302 24.0474 30.3302V26.3302C24.4209 26.3302 24.7746 26.5087 25.0012 26.7864C25.2121 27.0447 25.2817 27.3441 25.2569 27.601L21.2755 27.2157ZM24.0474 30.3302H41.0162V26.3302H24.0474V30.3302ZM40.9859 30.33C41.8712 30.3434 42.6434 29.9539 43.1507 29.3296C43.6158 28.7572 43.7928 28.0777 43.7954 27.4877C43.7981 26.898 43.6272 26.2109 43.1574 25.6304C42.6439 24.996 41.8658 24.6142 40.9863 24.6273L41.046 28.6268C40.6899 28.6321 40.3025 28.4612 40.0483 28.1471C39.8377 27.887 39.7948 27.6296 39.7955 27.4695C39.7962 27.3093 39.8414 27.0594 40.0462 26.8073C40.2932 26.5033 40.6767 26.3248 41.0465 26.3304L40.9859 30.33ZM41.0162 24.6271H41.0005V28.6271H41.0162V24.6271Z"
                    fill="#000105"
                    mask="url(#path-4-inside-2_1202_1725)"
                  />
                  <path
                    d="M18.1992 20.7681C18.0586 15.2837 10.0273 15.2837 9.88672 20.7681C10.0273 26.2524 18.0586 26.2524 18.1992 20.7681Z"
                    fill="#8CC947"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M20.1643 33.1113C20.5862 33.1113 20.9455 32.752 20.8987 32.3457C19.8518 24.252 8.25803 24.252 7.21116 32.3457C7.16428 32.7676 7.52366 33.1113 7.94553 33.1113H20.1799H20.1643Z"
                    fill="#8CC947"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                </svg>
              </div>
              <div className="LandlordLanding__details-item__details">
                <PriceLabel value={4} />

                <h4>ID verification</h4>
                <p>
                  Verified government ID and face matching technology
                  to ensure safety and security.
                </p>
              </div>
            </div>



            <div className="LandlordLanding__details-item">
              <div className="LandlordLanding__details-item__svg">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.17969 13.1929H45.2109V40.4429C45.2109 41.3022 44.5078 42.0054 43.6484 42.0054H6.74219C5.88281 42.0054 5.17969 41.3022 5.17969 40.4429V13.1929Z"
                    fill="white"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M6.74219 8.896H43.6484C44.5078 8.896 45.2109 9.62799 45.2109 10.5226V14.7519H5.17969V10.5226C5.17969 9.62799 5.88281 8.896 6.74219 8.896Z"
                    fill="#000105"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M9.82031 12.5991C10.3208 12.5991 10.7266 12.1934 10.7266 11.6929C10.7266 11.1924 10.3208 10.7866 9.82031 10.7866C9.3198 10.7866 8.91406 11.1924 8.91406 11.6929C8.91406 12.1934 9.3198 12.5991 9.82031 12.5991Z"
                    fill="white"
                  />
                  <path
                    d="M12.5391 12.5991C13.0396 12.5991 13.4453 12.1934 13.4453 11.6929C13.4453 11.1924 13.0396 10.7866 12.5391 10.7866C12.0386 10.7866 11.6328 11.1924 11.6328 11.6929C11.6328 12.1934 12.0386 12.5991 12.5391 12.5991Z"
                    fill="white"
                  />
                  <path
                    d="M41.4942 10.7866H24.0098C22.8223 10.7866 22.8067 12.6147 24.0098 12.6147H41.4942C42.6817 12.6147 42.6973 10.7866 41.4942 10.7866Z"
                    fill="white"
                  />
                  <path
                    d="M25.1953 37.9058C30.373 37.9058 34.5703 33.7084 34.5703 28.5308C34.5703 23.3531 30.373 19.1558 25.1953 19.1558C20.0176 19.1558 15.8203 23.3531 15.8203 28.5308C15.8203 33.7084 20.0176 37.9058 25.1953 37.9058Z"
                    fill="#8CC947"
                    stroke="#000105"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M28.7109 28.2337C28.9297 28.3587 28.9297 28.6868 28.7109 28.8118L25.7422 30.5306L22.7734 32.2493C22.5547 32.3743 22.2734 32.2181 22.2734 31.9524V25.0774C22.2734 24.8274 22.5547 24.6556 22.7734 24.7806L25.7422 26.4993L28.7109 28.2181V28.2337Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="LandlordLanding__details-item__details">
                <PriceLabel isFree={true} isComingSoon={true}/>

                <h4>personalized videos</h4>
                <p>
                  Get a video of your applicants so you can get to
                  know them and do your ‘gut’ check, before you meet
                  in person.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="row justify-content-center mt-3">
          <div className="col-auto">
            <Link
                to="/login"
                className="btn btn--primary"
            >
              start screeing
            </Link>
  </div> 
        </div>*/}
      </div>
    </section>
  )
}
