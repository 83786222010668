export const loginStart = (userCredentials) => ({
  type: "LOGIN_START",
})

export const loginSuccess = (user) => ({
  type: "LOGIN_SUCCESS",
  payload: user,
})

export const loginFailure = () => ({
  type: "LOGIN_FAILURE",
})

// LOGOUT

export const logout = () => ({
  type: "LOGOUT",
})

// USER_INFO_CHANGE

export const userInfoChange = (user) => ({
  type: "USER_INFO_CHANGE",
  payload: user,
})
