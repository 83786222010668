import axios from "axios"
import SelectInput from "Components/Input/SelectInput"
import config from "config/config"
import { usePremiumFlow } from "Context/flowPremiumContext/FlowPremiumContext"
import { useEffect } from "react"
import { useRef } from "react"
import { useState } from "react"
import Cookies from "universal-cookie"
import DatePickerInput from "../../../../../Components/Input/DatePickerInput"
import TextInput from "../../../../../Components/Input/TextInput"
import EmploymentIncomeVerificationConfirmModal from "../EmploymentIncomeVerificationConfirmModal/EmploymentIncomeVerificationConfirmModal"

let VERIFAST_ORIGIN = "https://app.verifast.com"
if (process.env.NODE_ENV === "development") {
  VERIFAST_ORIGIN = "https://sandbox.verifast.com"
}

const countries = { Canada: "CA", "United States": "US" }
function EmploymentIncomeVerificationModal({ openModal, setOpenModal }) {
  const { hasPremiumFeature, premiumFeatureComplete } = usePremiumFlow()
  const [ openEmploymentIncomeVerificationConfirmModal,
          setOpenEmploymentIncomeVerificationConfirmModal,
        ] = useState(false)
  const [step, setStep] = useState(0)
  const [country, setCountry] = useState("CA")
  const [frameUri, setFrameUri] = useState("")
  const [iframeLoaded, setIframeLoaded] = useState(false)
  useEffect(() => {
    const handler = (e) => {
      // console.log("MESSAGE", e)
      if (e.data.detail === "complete") {
        setTimeout(() => {
          setIframeLoaded(false)
          setOpenEmploymentIncomeVerificationConfirmModal(true)
        }, 1000)
      }
    }
    const topHandler = (e) => {
      // console.log({ top: e })
      let iFrame = document.getElementById("iFrame")
      e.data.origin = e.origin
      iFrame.contentWindow.postMessage(
        e.data,
        VERIFAST_ORIGIN
      )
    }
    window.addEventListener("message", handler)
    window.top.addEventListener("message", topHandler)
    return () => {
      window.removeEventListener("message", handler)
      window.top.removeEventListener("message", topHandler)
    }
  }, [])

  return (
    <div className="EmploymentIncomeVerificationModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        {step === 1 && (
          <div
            className="modal__container"
            style={{ padding: "0", display: "block", margin: "0" }}
          >
            <iframe
              id="iFrame"
              src={frameUri}
              title="Verifast"
              style={{
                height: "auto",
                minHeight: "550px",
                width: "100%",
                border: "none",
                display: iframeLoaded ? "block" : "none",
              }}
              onLoad={() => setIframeLoaded(true)}
            />
            {!iframeLoaded && (
              <div className="center-loader">
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </div>
        )}
        {step === 0 && (
          <div className="modal__container">
            <button
              className="btn btn--back"
              onClick={() => {
                setOpenModal(false)
              }}
            >
              cancel
            </button>
            <h2>please provide your Country</h2>
            <p>
              <strong>
                this information will not be saved or displayed to the
                landlord. It is used to accurately pull your income &
                expenses verification directly from Verifast.
              </strong>
            </p>
            <div className="row w-full">
              {/* <div className="col-md-6"> */}
              <SelectInput
                label="Your Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                {Object.entries(countries).map(([key, value]) => {
                  return (
                    <option key={value} value={value}>
                      {key}
                    </option>
                  )
                })}
              </SelectInput>
              {/* <TextInput type="text" label="your SIN" name="sin" /> */}
              {/* </div> */}
              {/* <div className="col-md-6">
              <DatePickerInput label="your birthday" name="dob" />
            </div> */}
            </div>
            <div className="btn-group">
              <button
                className="btn btn--primary"
                onClick={() => {
                  setStep(1)
                  axios
                    .post(
                      `${config.BASE_URL}api/verifast`,
                      {
                        application_id: localStorage.getItem(
                          "currentApplicationId"
                        ),
                        country: country,
                        bank_verify_enabled: hasPremiumFeature(
                          "financial_verification"
                        ),
                        id_verify_enabled:
                          hasPremiumFeature("id_verification"),
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${new Cookies().get(
                            "auth_token"
                          )}`,
                        },
                      }
                    )
                    .then(({ data }) => {
                      if (data.status) {
                        setFrameUri(data.iframeUrl)
                      } else {
                        throw new Error("failed to get iframe url")
                      }
                    })
                  // setOpenEmploymentIncomeVerificationConfirmModal(
                  //   true
                  // )
                }}
              >
                next
              </button>
            </div>
          </div>
        )}
      </div>
      {openEmploymentIncomeVerificationConfirmModal && (
        <EmploymentIncomeVerificationConfirmModal
          setOpenEmploymentIncomeVerificationConfirmModal={
            setOpenEmploymentIncomeVerificationConfirmModal
          }
        />
      )}
    </div>
  )
}

export default EmploymentIncomeVerificationModal
