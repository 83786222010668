import "./applicantForm.scss"
import TextInput from "../../../../Components/Input/TextInput"
import { useState } from "react"
import { CartState } from "Context/cartContext/CartContext"
import PremiumModal from "../PremiumModal/PremiumModal"
import BasicModal from "../BasicModal/BasicModal"

function ApplicantForm(props) {
  const [openPremiumModal, setOpenPremiumModal] = useState(false)
  const [openBasicModal, setOpenBasicModal] = useState(false)
  const [selected, setSelected] = useState(true)

  const {
    state: { premiumFeatures, cart },
    dispatch,
  } = CartState()

  const updateTenantInformation = (infoType, propId, value) => {
    propId = parseInt(propId)

    let currentTenantState = props.tenantInformation.filter(function (
      tenants
    ) {
      return tenants.id === propId
    })

    if (currentTenantState.length === 0) {
      props.setTenantInformation((tenantInformation) => [
        ...tenantInformation,
        {
          id: propId,
          name: infoType === "name" ? value : null,
          phone: infoType === "phone" ? value : null,
          email: infoType === "email" ? value.toLowerCase() : null,
        },
      ])
    } else {
      props.setTenantInformation(
        props.tenantInformation.map((tenant) =>
          tenant.id === propId
            ? { ...tenant, [infoType]: value }
            : tenant
        )
      )
    }
  }

  const handleClick = (e) => {
    //update cart
  }

  const tenant = props.tenantInformation.find(({ id }) => id === parseInt(props.id))

  const selectedFeatures = Object.assign(
    {},
    ...cart
      .filter((item) => item[0].applicantId === Number(props.id))
      .map((item) => ({ [item[0].id]: true }))
  )


  return (
    <div
      className="ScreenApplicants__tenant-info-container"
      data-applicant-id={props.id}
    >
      <div className="row">
        <div className="col-xl-6">
          <TextInput
            type="text"
            label="Tenant's name"
            name="name"
            onChange={(e) =>
              updateTenantInformation(
                "name",
                props.id,
                e.target.value
              )
            }
            value={tenant?.name || ""}
          />
        </div>
      </div>

      <label>How would you like to contact?</label>
      <div className="row">
        <div className="col-auto">
          <div className="form-choose-group mb-1">
            <button
              className={
                selected
                  ? "choose-input-group label active"
                  : "choose-input-group label "
              }
              onClick={() => setSelected(true)}
            >
              email
            </button>
            <button
              className={
                selected
                  ? "choose-input-group label"
                  : "choose-input-group label active "
              }
              onClick={() => setSelected(false)}
            >
              phone
            </button>
          </div>
        </div>
        <div className="col-xl">
          {selected ? (
            <TextInput
              type="text"
              name="email"
              placeholder="tenant's email address"
              onChange={(e) =>
                updateTenantInformation(
                  "email",
                  props.id,
                  e.target.value.toLowerCase()
                )
              }
              value={tenant?.email || ""}
            />
          ) : (
            <TextInput
              type="text"
              name="name"
              placeholder="tenant's mobile number"
              onChange={(e) =>
                updateTenantInformation(
                  "phone",
                  props.id,
                  e.target.value
                )
              }
              value={tenant?.phone || ""}
            />
          )}
        </div>
      </div>

      <div className="row justify-content-between mt-2">
        <div className="col-lg-auto">
          <div className="title-container mt-0 mb-2">
            <h2>advanced features</h2>
            <button
              className="help-tip"
              onClick={() => {
                setOpenPremiumModal(true)
              }}
            ></button>
          </div>
        </div>
      </div>
      <div className="row justify-content-between">
        {premiumFeatures.map((feature) => (
          <div
            className="col-lg-6 mr-auto advancedFeatures"
            key={feature.id}
          >
            <div
              type="checkbox"
              name={feature.name}
              className="form-checkbox__form-control"
              checked={selectedFeatures[feature.id]}
              // onChange={handleChange}
              onClick={handleClick}
            />
            <label className="form-checkbox__details advancedFtText">
              {feature.id === "reference_check" && ( // Remove me once reference check is implemented
                <div className="comingSoon">
                  <h4 className="comingText">coming soon!</h4>
                </div>
              )}
              {feature.name}
            </label>
            {selectedFeatures[feature.id] ? (
              <button
                className="checkedbox checkBtn"
                disabled={feature.id === "reference_check"} // Remove me once reference check is implemented
                onClick={() => {
                  dispatch({
                    type: "REMOVE_FROM_CART",
                    payload: {
                      ...feature,
                      applicantId: Number(props.id),
                    },
                    id: props.id,
                  })
                  setSelected({ ...selected, [feature.id]: false })
                }}
              ></button>
            ) : (
              <button
                className="greyBtn"
                disabled={feature.id === "reference_check"} // Remove me once reference check is implemented
                onClick={() => {
                  dispatch({
                    type: "ADD_TO_CART",
                    payload: {
                      ...feature,
                      applicantId: Number(props.id),
                    },
                    id: props.id,
                  })

                  setSelected({ ...selected, [feature.id]: true })
                }}
              ></button>
            )}
          </div>
        ))}
      </div>
      <div className="title-container mt-2">
        <h2>basic features</h2>
        <button
          className="help-tip"
          onClick={() => {
            setOpenBasicModal(true)
          }}
        ></button>
      </div>

      <div className="row">
        <div className="col-xl-6">
          <div className="ScreenApplicants__basic-feature">
            <span className="box-check"></span>
            <span>previous housing</span>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="ScreenApplicants__basic-feature">
            <span className="box-check"></span>
            <span>employment & income</span>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="ScreenApplicants__basic-feature">
            <span className="box-check"></span>
            <span>current housing</span>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="ScreenApplicants__basic-feature">
            <span className="box-check"></span>
            <span>household & personal</span>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="ScreenApplicants__basic-feature">
            <span className="box-check"></span>
            <span>guarantor</span>
          </div>
        </div>
      </div>
      {openPremiumModal ? (
        <PremiumModal
          openPremiumModal={openPremiumModal}
          setOpenPremiumModal={setOpenPremiumModal}
        />
      ) : null}
      {openBasicModal ? (
        <BasicModal
          openBasicModal={openBasicModal}
          setOpenBasicModal={setOpenBasicModal}
        />
      ) : null}
    </div>
  )
}

export default ApplicantForm
