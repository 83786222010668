import "./filterModal.scss"
import ChooseInput from "../../../../Components/Input/ChooseInput"
import MultiRangeSlider from "./MultiRangeSlider/MultiRangeSlider";
import { useState } from "react"
import clsx from "clsx"

function FilterModal({
  openModal,
  setOpenModal,
  filterConfig,
  setFilterConfig,
  applyFilters
}) {

  const initHouseHoldRange = {
    abs: { min: filterConfig.householdRange.abs.min, max: filterConfig.householdRange.abs.max },
    curr: { min: filterConfig.householdRange.abs.min, max: filterConfig.householdRange.abs.max }
  }
  const initSalaryRange = {
    abs: { min: filterConfig.salaryRange.abs.min, max: filterConfig.salaryRange.abs.max },
    curr: { min: filterConfig.salaryRange.abs.min, max: filterConfig.salaryRange.abs.max }
  }

  const initCreditRange = {
    abs: { min: filterConfig.creditRange.abs.min, max: filterConfig.creditRange.abs.max },
    curr: { min: filterConfig.creditRange.abs.min, max: filterConfig.creditRange.abs.max }
  }

  const [householdRange, setHouseholdRange] = useState(initHouseHoldRange)
  const [salaryRange, setSalaryRange] = useState(initSalaryRange)
  const [smoke, setSmoke] = useState(filterConfig.allowSmoke)
  const [creditRange, setCreditRange] = useState(initCreditRange)
  const [pets, setPets] = useState(filterConfig.pets)
  const [backgroundCheck, setBackgroundCheck] = useState(filterConfig.backgroundCheck)

  const applyConfig = () => {
    setFilterConfig({
      ...filterConfig,
      filterApplied: true,
      householdRange: householdRange,
      salaryRange: salaryRange,
      creditRange: creditRange,
      allowSmoke: smoke,
      pets: pets,
      backgroundCheck: backgroundCheck
    })

    setOpenModal(false)
    applyFilters()
  }

  const hasDoubleZero = (range) => {
    return range.min === 0 && range.max === 0
  }

  return (
    <div className="FilterModal side-modal">
      <div className="side-modal__overlay"></div>
      <div className="side-modal__wrapper">
        <div className="side-modal__container">
          <button
            className="btn btn--back btn--small mb-2"
            onClick={() => setOpenModal(false)}
          >
            Cancel
          </button>
          <h2>filter by</h2>

          <div className={"form-group"}>
            <div className={clsx(hasDoubleZero(filterConfig.householdRange.abs) && "hasZeroValue")}>
            <label>household number</label>
            <MultiRangeSlider
              min={filterConfig.householdRange.abs.min}
              max={filterConfig.householdRange.abs.max}
              prevValues={filterConfig.householdRange.curr}
              name={"household number"}
              onChange={({ min, max }) => {
                if (min !== householdRange.curr.min || max !== householdRange.curr.max) {
                  setHouseholdRange( ({
                    abs: { min: filterConfig.householdRange.abs.min, max: filterConfig.householdRange.abs.max },
                    curr: { min, max }
                  }))
                }
              }}
            />
          </div>
          </div>

          <div className="form-group">
            <label>smoking</label>
            <div className="form-choose-group">
              <ChooseInput label="yes"
                           name="smoking"
                           value="yes"
                           checked={smoke}
                           onChange={() => {
                             setSmoke(true)
                           }}
              />
              <ChooseInput label="no"
                           name="smoking"
                           value={"no"}
                           checked={!smoke}
                           onChange={() => {
                             setSmoke(false)
                           }}
              />
            </div>
          </div>

          <div className={"form-group"}>
            <div className={clsx(hasDoubleZero(filterConfig.salaryRange.abs) && "hasZeroValue")}>
              <label>income level</label>
            <MultiRangeSlider
              min={filterConfig.salaryRange.abs.min}
              max={filterConfig.salaryRange.abs.max}
              prevValues={filterConfig.salaryRange.curr}
              currency={true}
              onChange={({ min, max }) => {
                if (min !== salaryRange.curr.min || max !== salaryRange.curr.max) {
                  setSalaryRange( ({
                    abs: { min: filterConfig.salaryRange.abs.min, max: filterConfig.salaryRange.abs.max },
                    curr: { min, max }
                  }))
                }
              }}
            />
            </div>
          </div>

          <div className="form-group">
            <label>no pets</label>
            <div className="form-choose-group">
              <ChooseInput label="yes"
                           name="no_pets"
                           value="yes"
                           checked={pets}
                           onChange={() => {
                             setPets(true)
                           }}
              />
              <ChooseInput label="no"
                           name="no_pets"
                           value="no"
                           checked={!pets}
                           onChange={() => {
                             setPets(false)
                           }}
              />
            </div>
          </div>

          <div className="premiumFeaturesContainer">

            <div className="form-group">
              <label>id verified</label>
              <div className="form-choose-group">
                <ChooseInput label="yes"
                             name="id_verified"
                             value="yes"
                             checked={filterConfig.idVerification}
                             onChange={() => {
                               setFilterConfig({ ...filterConfig, idVerification: true })
                             }}

                />
                <ChooseInput label="no"
                             name="id_verified"
                             value="no"
                             checked={!filterConfig.idVerification}
                             onChange={() => {
                               setFilterConfig({ ...filterConfig, idVerification: false })
                             }}
                />
              </div>
            </div>

            <div className="form-group">
              <div className={clsx(hasDoubleZero(filterConfig.creditRange.abs) && "hasZeroValue")}>
                <label>credit score</label>
                <MultiRangeSlider
                  min={filterConfig.creditRange.abs.min}
                  max={filterConfig.creditRange.abs.max}
                  prevValues={filterConfig.creditRange.curr}
                  name={"credit_score"}
                  hasDoubleZero={hasDoubleZero(filterConfig.creditRange.abs)}
                  cosmeticDefaultVal={{min: 300, max: 900}}
                  onChange={({ min, max }) => {
                    if (min !== creditRange.curr.min || max !== creditRange.curr.max) {
                      setCreditRange( ({
                        abs: { min: filterConfig.creditRange.abs.min, max: filterConfig.creditRange.abs.max },
                        curr: { min, max }
                      }))
                    }
                  }}
                />
              </div>
            </div>

            {/* <div className="form-group">
            <label>has personal video</label>
            <div className="form-choose-group">
              <ChooseInput label="yes" name="has_personal_video" value="yes" />
              <ChooseInput label="no" name="has_personal_video" value="no" />
            </div>
          </div>*/
            }

            <div className="form-group">
              <label>bank check</label>
              <div className="form-choose-group">
                <ChooseInput label="yes"
                             name="has_bank_check"
                             value="yes"
                             checked={filterConfig.bankCheck}
                             onChange={() => {
                               setFilterConfig({ ...filterConfig, bankCheck: true })
                             }}
                />
                <ChooseInput label="no"
                             name="has_bank_check"
                             value="no"
                             checked={!filterConfig.bankCheck}
                             onChange={() => {
                               setFilterConfig({ ...filterConfig, bankCheck: false })
                             }}
                />
              </div>
            </div>

            {/*  <div className="form-group">
            <label>verified landlord reference</label>
            <div className="form-choose-group">
              <ChooseInput label="yes" name="has_verified_landlord" value="yes" />
              <ChooseInput label="no" name="has_verified_landlord" value="no" />
            </div>
          </div>
          */}

            <div className="form-group">
              <label>background check</label>
              <div className="form-choose-group">
                <ChooseInput label="yes"
                             name="background_check"
                             value="yes"
                             checked={backgroundCheck}
                             onChange={() => {
                               setBackgroundCheck(true)
                             }}
                />
                <ChooseInput label="no"
                             name="background_check"
                             value="no"
                             checked={!backgroundCheck}
                             onChange={() => {
                               setBackgroundCheck(false)
                             }}
                />
              </div>
            </div>
          </div>


          <button className="btn btn--accent large--accent" onClick={applyConfig}>
            apply
          </button>
        </div>
      </div>
    </div>
  )
}

export default FilterModal
