import React from "react"
import styles from "./LLReference.module.scss"
import { Stars } from "../../Stars"
function LLReference({ display }) {
  return display.map((ref, i) => {
  /*
    if (ref.landlord_name == null) { // temporary fix
      return (
        <React.Fragment key={i}>
          <div className="title-container">
            {/* <div
            className={clsx({
              "circle-check": !!ref.tenant_address,
              "circle-exclamation": ref.tenant_address === null,
            })}
          ></div>}
            <h3>reference {i + 1}</h3>
          </div>
          <div className="row">
            <div className="col">
              <h4 className={styles.centerText}>
                landlord reference pending
              </h4>
            </div>
          </div>
          {i !== display.length - 1 && (
            <hr className={styles.smallHr} />
          )}
        </React.Fragment>
      )
    }
    */
    if (ref.landlord_name == null) { // temporary fix
    return (
      <React.Fragment key={ref.id}>
        <div className="title-container">
          {/* <div
            className={clsx({
              "circle-check": !!ref.tenant_address,
              "circle-exclamation": ref.tenant_address === null,
            })}
          ></div> */}
          <h3>reference {i + 1}</h3>
        </div>
  
        {/* tenant rankings temp disabled
        <h5>Tenant rating:</h5>
        <div className="row">
          <div className="col">
            <Stars
              count={3}
              rating={
                ["no", "sometimes", "yes"].indexOf(ref.rent_on_time) +
                  1
              }
              alt={
                {
                  yes: "rent is on time",
                    sometimes: "rent is sometimes on time",
                      no: "rent is not on time",
                }[ref.rent_on_time] ||
                  `rent is on time: ${ref.rent_on_time || "unknown"}`
              }
              ></Stars>
            <p>Pays rent on time</p>
          </div>
          <div className="col">
            <Stars
              count={3}
              rating={
                ["yes", "little", "no"].indexOf(ref.cause_damage) + 1
              }
              alt={
                {
                  yes: "The tenant caused damage",
                    little: "The tenant caused a little damage",
                      no: "The tenant did not cause damage",
                }[ref.cause_damage] ||
                  `The tenant caused damage: ${
                ref.cause_damage || "unknown"
              }`
              }
              ></Stars>
            <p>No damage to property</p>
          </div>
          <div className="col">
            <Stars
              count={3}
              rating={
                ["no", "maybe", "yes"].indexOf(ref.rent_to_again) + 1
              }
              alt={
                {
                  yes: "Yes, Reference would rent to this tenant again",
                    maybe:
                    "Maybe, Reference might rent to this tenant again",
                      no: "No, Reference would not rent to this tenant again",
                }[ref.rent_to_again] ||
                  `Reference would rent to this tenant again: ${
                ref.rent_to_again || "unknown"
              }`
              }
              ></Stars>
            <p>Would rent to this tenant again</p>
          </div>
        </div>
        */}
        <div className="row">
          <div className="col">
            <h5>name</h5>
            <p>{ref.name}</p>
          </div>
          {/* 
          <div className="col">
            <h5>address of property</h5>
            <p>{ref.tenant_address}</p>
          </div> */}
        </div>
        <div className="row">
          <div className="col">
            <h5>email address</h5>
            <p>
              { ref.email != null ? (
                <a href={"mailto:" + ref.email}>{ref.email}</a>
              ) : ( <span>unknown</span> )}
            </p>
          </div>
          <div className="col">
            <h5>phone number</h5>
            <p>
              { ref.phone_number != null ? (
              <a
                href={
                  "tel:" + ref.phone_number?.replace(/[^0-9]/g, "")
                }
                >
                {ref.phone_number}
              </a>
              ) : ( <span>unknown</span> )}
            </p>
          </div>
        </div>
        {i !== display.length - 1 && (
          <hr className={styles.smallHr} />
        )}
      </React.Fragment>
      )
    }
  })
}

export default LLReference
