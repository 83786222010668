import {
  createContext,
  useCallback,
  useContext,
  useState,
} from "react"
import { useEffectOnce } from "Hooks/useEffectOnce"
import axios from "axios"
import { useCookie } from "Hooks/useCookie"
import config from "../../config/config"
const FlowPremiumContext = createContext()
export const usePremiumFlow = () => useContext(FlowPremiumContext)


export const FlowPremiumContextProvider = ({ children }) => {
  const [auth_token] = useCookie("auth_token", null)
  const [hasData, setHasData] = useState(false)
  const [premiumFeatures, setPremiumFeatures] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffectOnce(() => {
    let applicationId = localStorage.getItem("currentApplicationId")
    if (applicationId !== null) {
      axios
        .get(
          `${config.BASE_URL}api/screening-request/tenants-request-items/${applicationId}`,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        )
        .then((res) => {
          // console.log("res for premium features", res)
          setHasData(true)
          setPremiumFeatures(res.data.data)
          // console.log("res from preimum features,", res.data.data)
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
      setHasData(false)
    }
  })
  const hasPremiumFeature = useCallback(
    (feature) => {
      return (
        hasData &&
        Boolean(
          premiumFeatures.find(
            (premiumFeature) => premiumFeature.item === feature
          )
        )
      )
    },
    [premiumFeatures, hasData]
  )
  const premiumFeatureComplete = useCallback(
    (feature) => {
      return (
        hasData &&
        Boolean(
          premiumFeatures.find(
            (premiumFeature) => premiumFeature.item === feature
          )?.is_completed
        )
      )
    },
    [premiumFeatures, hasData]
  )

  const premiumFeatureProcessed = useCallback(
    (feature) => {
      return (
        hasData &&
        Boolean(
          premiumFeatures.find(
            (premiumFeature) => premiumFeature.item === feature
          )?.is_processed
        )
      )
    },
    [premiumFeatures, hasData]
  )

  return (
    <FlowPremiumContext.Provider
      value={{
        premiumFeatures,
        hasData,
        isLoading,
        hasPremiumFeature,
        premiumFeatureComplete,
        premiumFeatureProcessed
      }}
    >
      {children}
    </FlowPremiumContext.Provider>
  )
}
