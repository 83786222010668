const ChooseInput = ({ value, label, name, onChange, checked }) => (
    <div className="choose-input-group">
        <input
            type="radio"
            value={value}
            name={name}
            id={name + '-' + value}
            className="choose-input choose-input--hide"
            onChange={onChange}
            checked={checked}
        />{label && <label htmlFor={name + '-' + value}>{label}</label>}
    {/*  error message here  */}
    </div>
);

export default ChooseInput;