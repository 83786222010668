import { useNavigate } from "react-router-dom"
import "./premiumLocked.scss"
import pendingLock from "../../../assets/svg/pending.svg"

function PremiumLocked(props) {
  const navigate = useNavigate()
  const pending = props.pending

  return (
    <section className="premium-feature-locked application-preview-container">
      <div className="title-container">
        {( pending === "awaiting"  || pending === "processed" || pending === "paid") ? (
          <>
            <img src={pendingLock} className="premium-feature-pending__icon" />
            <h5 className={"pendingText"}>
              this premium feature is pending completion
            </h5>
          </>
        ) : (
          <>
            <div className="premium-feature-locked__icon"></div>
            <h5 className="premium-feature-locked__title">
              this premium feature is locked
            </h5>
          </>
        )}

      </div>
      <h2>{props.title}</h2>
      <p>{props.description}</p>
      {( pending === "awaiting"  || pending === "processed" || pending === "paid") ? (
        <p className={"checkBackSoon"}>check back soon, we will update this item when the applicant completes it</p>
      ): (
        <button
          className="btn btn--accent"
          onClick={() => {
            navigate(
              `/landlord/screen-applicants?${Object.entries({
                name: props.meta.name,
                contact_type: props.meta.contact_type,
                contact_info: props.meta.contact_info,
                phone: props.meta.phone,
                selected_features:
                  props.meta.selected_features.join(","),
                hide_add_more: true,
              })
                .map(
                  ([key, value]) =>
                    `${key}=${btoa(JSON.stringify(value))}`
                )
                .join("&")}`
            )
          }}
        >
          purchase
        </button>
      )
      }

    </section>
  )
}

export default PremiumLocked
